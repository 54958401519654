import React, { memo, forwardRef, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { BeforeModifier, AfterModifier, DateUtils } from "react-day-picker";
import {
  format as dateFnsFormat,
  parse as dateFnsParse,
  getMonth,
  setMonth
} from "date-fns";
import { useTheme, Theme } from "@tvg/design-system";
import {
  InputCalendarProps,
  InputFieldProps,
  InputCalendarMode,
  OnDayChange
} from "../types";
import {
  Container,
  DayPickerCustomStyles,
  IconWrapper,
  InputRangeWrapper
} from "./styled-components";
import { InputField } from "../inputField";
import { InputLabel } from "../inputLabel";
import { InputMessage } from "../inputMessage";
import { Icon } from "../../Icon";
import { MonthSelector, MonthNavbar } from "./components";
import { MonthSelectorProps, MonthNavbarProps } from "./components/types";

function parseDate(str: string, format: string, locale: Locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date: Date, format: string, locale: Locale) {
  return dateFnsFormat(date, format, { locale });
}

const CustomInputCore = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => <InputField ref={ref} {...props} />
);

const InputIcon = ({
  theme,
  inputRef
}: {
  theme: Theme;
  inputRef: React.RefObject<HTMLInputElement>;
}) => (
  <IconWrapper
    onClick={() => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }}
  >
    <Icon
      name="calendar"
      size="m"
      lineColor={theme.colorTokens.content.default}
      qaLabel="calendar-icon"
    />
  </IconWrapper>
);

const NavbarElement = ({ onPreviousClick, onNextClick }: MonthNavbarProps) => (
  <MonthNavbar onPreviousClick={onPreviousClick} onNextClick={onNextClick} />
);

export const InputCalendar = ({
  label = "",
  messageAbove = "",
  messageBellow = "",
  isOptional = false,
  isChecked = false,
  isInvalid = false,
  isDisabled = false,
  isReadOnly = false,
  warningMessage = "",
  qaLabel,
  value,
  placeholder = "MM/DD/YYYY",
  mode = InputCalendarMode.SINGLE,
  inputOverlayAlignment = "left",
  onDayChange,
  onDayFromChange,
  onDayToChange,
  disabledDays = [],
  dayPickerProps
}: InputCalendarProps) => {
  const theme = useTheme();
  const dayPickerContainerRef = useRef<DayPickerInput>(null);
  const dayPickerInputRef = useRef<HTMLInputElement>(null);
  const inputRangeToFieldRef = useRef<HTMLInputElement>(null);
  const [isMonthSelector, setIsMonthSelector] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  // Input Range Mode state
  const [from, setFrom] = useState<Date | undefined>();
  const [to, setTo] = useState<Date | undefined>();

  const inputProps = {
    end: !isDisabled ? (
      <InputIcon theme={theme} inputRef={dayPickerInputRef} />
    ) : null,
    isDisabled,
    readOnly: isReadOnly,
    ref: dayPickerInputRef,
    qaLabel: `${qaLabel}-input-calendar-input-field`
  };

  const commonProps = {
    captionElement: ({
      date,
      localeUtils
    }: Pick<MonthSelectorProps, "date" | "localeUtils">) => (
      <MonthSelector
        date={date}
        localeUtils={localeUtils}
        isMonthSelector={isMonthSelector}
        setIsMonthSelector={setIsMonthSelector}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
      />
    ),
    navbarElement: () => (
      <NavbarElement
        onPreviousClick={() =>
          setSelectedMonth((prevDate) =>
            setMonth(prevDate, getMonth(prevDate) - 1)
          )
        }
        onNextClick={() =>
          setSelectedMonth((prevDate) =>
            setMonth(prevDate, getMonth(prevDate) + 1)
          )
        }
      />
    )
  };

  const handleOnDayFromChange: OnDayChange = (dayFrom, modifiers, input) => {
    setFrom(dayFrom);
    if (inputRangeToFieldRef && inputRangeToFieldRef.current && dayFrom) {
      const inputRef = inputRangeToFieldRef.current;
      const { value: newValue } = inputRef;
      inputRef.focus();
      inputRef.setSelectionRange(newValue.length, newValue.length);
    }
    if (onDayFromChange) {
      onDayFromChange(dayFrom, modifiers, input);
    }
  };

  const handleOnDayToChange: OnDayChange = (dayTo, modifiers, input) => {
    setTo(dayTo);
    if (onDayToChange) {
      onDayToChange(dayTo, modifiers, input);
    }
  };

  // DayPicker doesn't close until you click on a day if keepfocus is set to false
  // https://github.com/gpbl/react-day-picker/issues/723
  const handleOnBlur = () => {
    setTimeout(() => {
      const elClicked = document.activeElement;
      const container = document.querySelector(
        `[data-qa-label="${qaLabel}-input-calender"]`
      );
      if (
        container &&
        !container.contains(elClicked) &&
        typeof dayPickerContainerRef.current?.hideDayPicker === "function"
      ) {
        dayPickerContainerRef.current.hideDayPicker();
      }
    });
  };

  return (
    <Container data-qa-label={`${qaLabel}-input-calender`}>
      <InputLabel label={label} isOptional={isOptional} isChecked={isChecked} />
      {!!messageAbove && (
        <InputMessage
          qaLabel={`${qaLabel}-input-calendar-message-above`}
          message={messageAbove}
          marginTop="space-3"
        />
      )}
      {mode === InputCalendarMode.SINGLE ? (
        // @ts-ignore
        <DayPickerInput
          ref={dayPickerContainerRef}
          onDayChange={onDayChange}
          inputProps={inputProps}
          component={CustomInputCore}
          value={value as string}
          placeholder={placeholder}
          formatDate={formatDate}
          format="MM/dd/yyyy"
          parseDate={parseDate}
          keepFocus={false}
          dayPickerProps={{
            disabledDays,
            onBlur: handleOnBlur,
            month: selectedMonth,
            ...commonProps,
            ...dayPickerProps
          }}
        />
      ) : (
        <InputRangeWrapper>
          {/* @ts-ignore */}
          <DayPickerInput
            onDayChange={handleOnDayFromChange}
            inputProps={inputProps}
            component={CustomInputCore}
            placeholder="From"
            format="MM/dd/yyyy"
            formatDate={formatDate}
            parseDate={parseDate}
            keepFocus={false}
            dayPickerProps={{
              disabledDays: [
                ...(Array.isArray(disabledDays)
                  ? disabledDays
                  : [disabledDays]),
                { after: to } as AfterModifier
              ],
              selectedDays: [from, { from, to }],
              month: selectedMonth,
              toMonth: to,
              ...commonProps,
              ...dayPickerProps
            }}
          />
          {/* @ts-ignore */}
          <DayPickerInput
            onDayChange={handleOnDayToChange}
            inputProps={{ ...inputProps, ref: inputRangeToFieldRef }}
            component={CustomInputCore}
            placeholder="To"
            format="MM/dd/yyyy"
            formatDate={formatDate}
            parseDate={parseDate}
            keepFocus={false}
            dayPickerProps={{
              disabledDays: [
                ...(Array.isArray(disabledDays)
                  ? disabledDays
                  : [disabledDays]),
                { before: from } as BeforeModifier
              ],
              selectedDays: [from, { from, to }],
              month: from || selectedMonth,
              fromMonth: from,
              ...commonProps,
              ...dayPickerProps
            }}
          />
        </InputRangeWrapper>
      )}
      {isInvalid && warningMessage && (
        <InputMessage
          qaLabel={`${qaLabel}-input-calendar-warning-message`}
          message={warningMessage}
          isWarning={isInvalid}
          mx={0}
          mt={2}
          mb="space-3"
        />
      )}
      {!!messageBellow && (
        <InputMessage
          qaLabel={`${qaLabel}-input-calendar-message-below`}
          message={messageBellow}
        />
      )}
      <Helmet>
        <style>{DayPickerCustomStyles(theme, { inputOverlayAlignment })}</style>
      </Helmet>
    </Container>
  );
};

export { MonthSelector } from "./components/MonthSelector";
export default memo(InputCalendar);
