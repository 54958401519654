import React from "react";
// @ts-ignore
import { useColorTokens, ColorTokens } from "@tvg/design-system";
import { Header, Button } from "@tvg/design-system/web";
import ErrorPageImgMap from "./SvgImages/ErrorPageImgMap";
import PageErrorDescription from "./ErrorPageDescription";
import PageErrorMessages from "./ErrorPageMessages";
import {
  PageErrorWrapper,
  ScrollableContent,
  HeaderContent,
  MapImage,
  ActionsContent
} from "./styled-components";
import { Props, MapImageEnum } from "./types";

export const ErrorPage = ({
  qaLabel = "errorPage",
  isGenericError,
  title,
  imageType = MapImageEnum.PIN,
  description,
  errorsTitle,
  errorsMessages = [],
  primaryButton,
  secondaryButton
}: Props) => {
  const { content } = useColorTokens() as ColorTokens;

  return (
    <PageErrorWrapper data-qa-label={qaLabel}>
      <ScrollableContent>
        <MapImage data-qa-label={`${qaLabel}-map-image`}>
          <ErrorPageImgMap error={imageType} />
        </MapImage>
        <HeaderContent>
          <Header
            tag="h3"
            qaLabel={`${qaLabel}-title`}
            fontFamily="bold"
            color={content.onDark}
          >
            {title}
          </Header>
        </HeaderContent>
        {description && (
          <PageErrorDescription
            qaLabel={`${qaLabel}-description`}
            {...description}
          />
        )}
        {!isGenericError && errorsMessages.length > 0 && (
          <PageErrorMessages
            qaLabel={`${qaLabel}-messages`}
            title={errorsTitle}
            messages={errorsMessages}
          />
        )}
        {primaryButton && (
          <ActionsContent hasDescription={!!description}>
            <Button
              qaLabel={`${qaLabel}-primary-btn`}
              variant="primary"
              onClick={primaryButton.onClick}
              size="l"
              isStretched
            >
              {primaryButton.title}
            </Button>
          </ActionsContent>
        )}
        {secondaryButton?.onClick && secondaryButton?.title && (
          <Button
            qaLabel={`${qaLabel}-secondary-btn`}
            variant="tertiaryDark"
            onClick={secondaryButton.onClick}
            isStretched
          >
            {secondaryButton.title}
          </Button>
        )}
      </ScrollableContent>
    </PageErrorWrapper>
  );
};

export default ErrorPage;
