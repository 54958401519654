import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import ApolloContext from "@tvg/utils/apolloContext";
import ApolloContextClients from "@tvg/utils/types/ApolloContext";
import HomeGrid from "@tvg/home";
import { isFDR } from "@tvg/utils/generalUtils";
import Homepage from "@urp/homepage/src/index";
import { HomeContainer } from "./styled-components";
import { getshowStoryblokHomeToggle } from "../../store/selectors/general";

export const Home = () => {
  const seoContent = useSelector((store) =>
    get(store, "capi.messages.seoContentHome", "")
  );
  const showStoryblokHomepage = useSelector(getshowStoryblokHomeToggle);

  return (
    <HomeContainer>
      {showStoryblokHomepage && isFDR() ? (
        <Homepage />
      ) : (
        <ApolloContext.Consumer>
          {(clients: ApolloContextClients) => (
            <HomeGrid
              shouldUpdate={typeof window !== "undefined"}
              device="desktop"
              seoContent={seoContent}
              graphClients={clients}
            />
          )}
        </ApolloContext.Consumer>
      )}
    </HomeContainer>
  );
};

export default Home;
