import styled from "styled-components";
import { fontProxima } from "@tvg/atomic-ui/_static/Typography";
import { UnmountClosed } from "react-collapse";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
`;

export const SessionContainer = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: ${fontProxima};
  font-weight: normal;
  color: var(--fd-colors-content-on-dark);
  font-size: var(--fd-font-sizes-font-size-020);
  padding: 0 16px;
`;

export const SessionDescription = styled.span`
  font-weight: bold;
`;

export const SessionPart = styled.div``;

export const PlaceHolder = styled.div`
  width: 100%;
  height: 16px;
`;

export const StyledUnmountClosed = styled(UnmountClosed)`
  height: 141px;
  overflow: initial;
`;
