import StoryblokClient, { Richtext } from "storyblok-js-client";
import {
  ContentFaqItem,
  EducationHubSectionType,
  SectionTypes
} from "../types";

export const getSectionByType = (
  sections: EducationHubSectionType[] | undefined,
  type: SectionTypes
) => sections?.find((s) => s.type === type);

export function isDescriptionRichtext(
  object: ContentFaqItem["description"]
): object is Richtext {
  return !!(object as Richtext).content;
}

export const formatFaqList = (
  data: ContentFaqItem[],
  storyblokClient?: StoryblokClient
) =>
  data?.map((item, index) => ({
    id: index,
    title: item.title,
    link: item.link ?? null,
    linkLabel: item.linkLabel ?? "",
    description: isDescriptionRichtext(item.description)
      ? storyblokClient?.richTextResolver.render(item.description) || ""
      : item.description
  }));
