import React from "react";
import Section from "../../Section";
import SectionHeaderLoading from "./SectionHeaderLoading";
import ArticleCardLoading from "./ArticleCardLoading";
import { CardsWrapper } from "../styled-components";

export default function ArticleSectionLoading() {
  return (
    <Section>
      <SectionHeaderLoading />
      <CardsWrapper>
        <ArticleCardLoading />
        <ArticleCardLoading />
        <ArticleCardLoading />
        <ArticleCardLoading />
      </CardsWrapper>
    </Section>
  );
}
