import { Richtext } from "storyblok-js-client";

const betTypeKey = "PICK_BET_NUMBER";

export const replacePlaceholderValue = (
  bet_description: Richtext,
  bettingType?: string[]
) => {
  const value =
    typeof window !== "undefined"
      ? window.localStorage.getItem(betTypeKey) || ""
      : "";

  const richTextStr = JSON.stringify(bet_description);
  const valueToReplace = bettingType?.length && value ? value : "3-10";
  const richTextResult = richTextStr.replace(`[${betTypeKey}]`, valueToReplace);

  return JSON.parse(richTextResult);
};
