import React, { useState } from "react";
import { Button } from "@tvg/design-system/web";
import { openExternalLink } from "@tvg/sh-utils/mobileUtils";
import {
  PageErrorWrapper,
  IconWrapper,
  TextWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  HalfBlock,
  ParagraphBlock,
  ErrorMessageSubtitleBlock,
  ButtonWrapper,
  StateList
} from "./styled-components";
import LocationErrorPageIcon from "../SvgImages/LocationErrorPageIcon";
import { LocationErrorPageProps } from "./types";

export const LocationErrorPage = ({
  qaLabel = "errorPage",
  errorTitles,
  errorMessages,
  allowedStates = []
}: LocationErrorPageProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const goToFAQPage = (url: string) => {
    openExternalLink(url);
  };
  const allowedStatesList = allowedStates.join(", ");
  const chevronIcon = isDropdownOpen ? "chevronUp" : "chevronDown";
  const { modalTitle, modalSubtitle, errorMessageSubtitle } = errorTitles;
  const {
    locationServicesMessage,
    locationServicesMessageLink,
    allowAccessMessage,
    switchBrowserMessage,
    downloadAppMessage,
    availableStatesButtonText
  } = errorMessages;
  return (
    <PageErrorWrapper data-qa-label={qaLabel}>
      <IconWrapper>
        <LocationErrorPageIcon />
      </IconWrapper>
      <TextWrapper>
        <HalfBlock>
          <TitleWrapper>
            <Title>{modalTitle}</Title>
            <Subtitle>{modalSubtitle}</Subtitle>
          </TitleWrapper>
          <ParagraphBlock>
            {locationServicesMessage}
            <button
              type="button"
              onClick={() =>
                goToFAQPage(
                  "https://support.fanduel.com/s/article/Location-Troubleshooting-Tips"
                )
              }
            >
              {locationServicesMessageLink}
            </button>
          </ParagraphBlock>
          <ParagraphBlock>{allowAccessMessage}</ParagraphBlock>
        </HalfBlock>
        <HalfBlock>
          <ErrorMessageSubtitleBlock>
            {errorMessageSubtitle}
          </ErrorMessageSubtitleBlock>
          <ParagraphBlock>{switchBrowserMessage}</ParagraphBlock>
          <ParagraphBlock>{downloadAppMessage}</ParagraphBlock>
          <ButtonWrapper>
            <Button
              qaLabel="dropDown-Button"
              size="s"
              variant="tertiary"
              icon={chevronIcon}
              iconPosition="end"
              onClick={() => toggleDropdown()}
            >
              {availableStatesButtonText}
            </Button>
            {isDropdownOpen && <StateList>{allowedStatesList}</StateList>}
          </ButtonWrapper>
        </HalfBlock>
      </TextWrapper>
    </PageErrorWrapper>
  );
};

export default LocationErrorPage;
