import React, { FC, useState } from "react";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { useColorTokens, Paragraph } from "@tvg/design-system";
import { Counter } from "@tvg/design-system/web";
import {
  EVENTS,
  FILTER
} from "@urp/amplitude/src/modules/talentPicks/constants";
import {
  AllTalents,
  Container,
  FilterSelectorWrapper,
  BadgeWrapper,
  TalentImage
} from "./styled-components";
import { TalentFilter } from "../../types";
import TalentFilterLoading from "./TalentFilterLoading";
import { triggerAmplitudeEvent } from "../../utils/amplitude";

interface TalentFilterSelectorProps {
  loading: boolean;
  onSelect: UnaryFn<string, void>;
  talents: TalentFilter[];
}

const TalentFilterSelector: FC<TalentFilterSelectorProps> = ({
  loading,
  onSelect,
  talents
}) => {
  const { content } = useColorTokens();
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const allTalentPicks = talents.reduce(
    (acc, talent) => acc + talent.picksCount,
    0
  );

  const isTalentActive = (talentValue: string): boolean =>
    activeFilters.includes(talentValue);

  const handlerOnClick = (talentValue: string, label: string) => {
    if (talentValue.length === 0) {
      setActiveFilters([]);
    } else {
      const result = activeFilters.find((filter) => filter === talentValue)
        ? activeFilters.filter((filter) => filter !== talentValue)
        : [...activeFilters, talentValue];
      setActiveFilters([...result]);
    }
    onSelect(talentValue);
    triggerAmplitudeEvent(EVENTS.FILTERS_APPLIED, {
      filterName: FILTER.TALENT,
      filterLabel: label
    });
  };

  return (
    <Container>
      {loading ? (
        <TalentFilterLoading />
      ) : (
        talents
          .filter(({ picksCount, value }) => picksCount > 0 || value === "")
          .map((talent) => {
            const isActive = isTalentActive(talent.value);
            const hasSelected = activeFilters.length !== 0;
            const isAllBtnActive = !talent.image && !hasSelected;
            return (
              <FilterSelectorWrapper
                key={talent.value}
                onClick={() => handlerOnClick(talent.value, talent.label)}
                hasSelected={hasSelected}
                hideButton={allTalentPicks === 0}
                isActive={isActive || isAllBtnActive}
              >
                {talent.image ? (
                  <BadgeWrapper data-qa-label="badge-container">
                    <Counter
                      value={talent.picksCount}
                      position="absolute"
                      top={9}
                      right={4}
                    />
                    <TalentImage src={talent.image} isActive={isActive} />
                  </BadgeWrapper>
                ) : (
                  <BadgeWrapper data-qa-label="badge-container">
                    <Counter
                      value={allTalentPicks}
                      position="absolute"
                      top={9}
                      right={4}
                    />
                    <AllTalents isActive={isAllBtnActive}>
                      <Paragraph
                        qaLabel="allTalents"
                        color={content.onDark}
                        fontFamily="bold"
                        fontSize="s"
                        textOverflow="ellipsis"
                        textAlign="center"
                      >
                        All
                      </Paragraph>
                    </AllTalents>
                  </BadgeWrapper>
                )}
                <Paragraph
                  qaLabel="talentLabel"
                  fontFamily="regular"
                  fontSize="10px"
                  textAlign="center"
                  color={content.onDark}
                  textOverflow="ellipsis"
                  // mt={3}
                >
                  {talent.image ? talent.label : "All FDTV Picks"}
                </Paragraph>
              </FilterSelectorWrapper>
            );
          })
      )}
    </Container>
  );
};

export default TalentFilterSelector;
