import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StoryblokClient from "storyblok-js-client";
import get from "lodash/get";
import tvgConf from "@tvg/conf";
import ComplianceBanner from "@urp/compliance-banner";
import { AnchorTabs, Header, PageHeader } from "@tvg/design-system/web";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import {
  RacingFAQ,
  RichTextSection,
  BetTypesSection,
  ArticlePageLoading,
  Footer,
  SeoHeader
} from "../components";
import useContentData from "../hooks/useContentData";
import {
  Container,
  Title,
  Columns,
  MainContent,
  SideContent,
  MobileHeader
} from "./styled-components";
import { getSectionByType } from "../utils/storyblok";
import { StoryblokPageContent } from "../types";
import { HORSE_RACING_101_URL } from "../constants";

interface ArticleProps {
  slug: string;
}

const storyblokToken = tvgConf().config("storyblokToken");
const storyblokClient = new StoryblokClient({
  accessToken: storyblokToken,
  cache: {
    clear: "auto",
    type: "memory"
  }
});

const Article = ({ slug }: ArticleProps) => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();

  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const [isBetTypesLoading, setBetTypesLoading] = useState(
    pathname === `${HORSE_RACING_101_URL}/betting`
  );

  const { contentData, isLoading, isError } =
    useContentData<StoryblokPageContent>(slug);

  const faqSection = useMemo(
    () => getSectionByType(contentData?.sections, "faq"),
    [contentData]
  );

  const richTextSection = useMemo(
    () => getSectionByType(contentData?.sections, "richtext"),
    [contentData]
  );

  const betTypesSection = useMemo(
    () => getSectionByType(contentData?.sections, "bet_types"),
    [contentData]
  );

  const footerSection = useMemo(() => {
    const footerTitle = contentData?.footer_title;
    const footerLink = contentData?.footer_link;

    return (
      footerTitle &&
      footerLink?.cached_url && (
        <Footer footerTitle={footerTitle} footerLink={footerLink} />
      )
    );
  }, [contentData]);

  const shouldHideFaqAndRichText = useMemo(
    () =>
      hash &&
      betTypesSection?.content.some(
        (item) => get(item, "hash") === hash.replace("#", "")
      ),
    [betTypesSection, hash]
  );

  useEffect(() => {
    if (isError) {
      navigate(HORSE_RACING_101_URL);
    }
  }, [isError]);

  useEffect(() => {
    if (betTypesSection) {
      setBetTypesLoading(false);
    }
  }, [betTypesSection]);

  const tabs = useMemo(
    () =>
      contentData?.sections
        ?.filter((tabItem) => tabItem && tabItem.tab_name)
        .map((tabItem) => ({
          id: tabItem.tab_name.toLowerCase(),
          label: tabItem.tab_name
        })) || [],
    [contentData]
  );

  const isMobileBettingPage =
    pathname === `${HORSE_RACING_101_URL}/betting` && !isDesktop;

  if (isLoading || isBetTypesLoading)
    return (
      <ArticlePageLoading isBetting={isBetTypesLoading} isDesktop={isDesktop} />
    );

  return (
    <Container data-qa-label="education-hub-container">
      {contentData?.seo_meta_data && (
        <SeoHeader seoMetaData={contentData.seo_meta_data} />
      )}
      {isDesktop ? (
        <Title>
          <Header
            tag="h1"
            fontFamily="bold"
            qaLabel="education-hub-title"
            color="--fd-colors-content-strong"
            fontSize="28px"
            lineHeight="35px"
          >
            {get(contentData, "title", "")}
          </Header>
        </Title>
      ) : (
        <MobileHeader>
          <PageHeader
            qaLabel="education-hub-page-header"
            title={get(contentData, "title", "")}
            showBackButton
            onBack={() => navigate(-1)}
            py="14px"
          />
          <ComplianceBanner onBannerVisibility={setIsBannerVisible} />
          {!!tabs.length && !shouldHideFaqAndRichText && (
            <AnchorTabs
              sections={tabs}
              offsetMargin={isBannerVisible ? 131 : 97}
              isSticky={false}
            />
          )}
        </MobileHeader>
      )}
      <Columns
        isArticle
        hasMobileBottomSpace={!!tabs.length && !shouldHideFaqAndRichText}
      >
        <MainContent data-qa-label="education-hub-main-content" isFullHeight>
          {betTypesSection && (
            <BetTypesSection
              id={betTypesSection.tab_name.toLowerCase()}
              data={betTypesSection}
              storyblokClient={storyblokClient}
              footerTitle={contentData?.footer_title}
            />
          )}

          {isMobileBettingPage && !shouldHideFaqAndRichText && faqSection && (
            <RacingFAQ
              data={faqSection}
              storyblokClient={storyblokClient}
              defaultOpenIndices={isDesktop ? [] : [0]}
            />
          )}

          {!shouldHideFaqAndRichText && richTextSection && (
            <RichTextSection
              id={richTextSection.tab_name.toLowerCase()}
              data={richTextSection}
              storyblokClient={storyblokClient}
              hasSectionsMargin={!!betTypesSection}
              footer={footerSection}
            />
          )}
        </MainContent>

        {!isMobileBettingPage && !shouldHideFaqAndRichText && faqSection && (
          <SideContent data-qa-label="education-hub-side-content">
            <RacingFAQ
              data={faqSection}
              storyblokClient={storyblokClient}
              defaultOpenIndices={isDesktop ? [] : [0]}
            />
          </SideContent>
        )}
      </Columns>
    </Container>
  );
};

export { Article, ArticlePageLoading as EducationHubArticlePageLoading };
