import { HomepageStoryblokConfig } from "@urp/homepage/src/types";
import PreferenceService from "@tvg/api/uam";
import { setNewPreferenceValue } from "@tvg/shared-actions/UserActions";
import { Dispatch } from "redux";
import { Template } from "./types";

const capitalizeWord = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const formatTemplate = (config: HomepageStoryblokConfig): Template[] =>
  config.templates.map((template) => ({
    value: capitalizeWord(template),
    label: capitalizeWord(template),
    // @ts-ignore
    description: config[template]?.description || ""
  }));

export const setHPTemplateSelectorVisibility = (
  accountNumber: string,
  value: boolean,
  dispatch: Dispatch,
  isMobile?: boolean
) => {
  const config = isMobile
    ? "showHPTemplateSelectorMobile"
    : "showHPTemplateSelectorDesktop";

  return PreferenceService.setUserPref(accountNumber, config, value)
    .then(() => {
      dispatch(setNewPreferenceValue(config, `${value}`));

      return {
        success: true,
        message: "Template preference updated successfully."
      };
    })
    .catch((error: unknown) => ({
      success: false,
      message: "Failed to update template preference.",
      error
    }));
};

export const updateUserTemplatePref = async (
  accountNumber: string,
  template: string,
  isMobile: boolean
) => {
  const config = isMobile
    ? "homepageTemplateMobile"
    : "homepageTemplateDesktop";
  try {
    await PreferenceService.setUserPref(accountNumber, config, template);
    return {
      success: true,
      message: "Template preference updated successfully."
    };
  } catch (error) {
    return {
      success: false,
      message: "Failed to update template preference.",
      error
    };
  }
};
