import React from "react";
import { useIsValidIcon } from "@tvg/design-system";
import { Paragraph, Header, Icon, IconNames } from "@tvg/design-system/web";
import { Button, Container, Content, Footer } from "./styled-components";

interface ArticleItemProps {
  onClick: () => void;
  title: string;
  description: string;
  buttonText: string;
  qaLabel: string;
  icon?: string;
}

const ArticleItem = ({
  qaLabel,
  onClick,
  title,
  description,
  buttonText,
  icon
}: ArticleItemProps) => {
  const isValidIcon = useIsValidIcon();
  const iconName = isValidIcon(icon ?? "") ? icon : null;

  return (
    <Container>
      <Button onClick={onClick} data-qa-label={qaLabel}>
        <Content>
          {iconName && (
            <Icon
              qaLabel={`${qaLabel}-icon`}
              size="m"
              name={iconName as IconNames}
              mb="space-3"
              lineColor="--fd-colors-content-default"
            />
          )}
          <Header
            color="--fd-colors-content-strong"
            qaLabel={`${qaLabel}-title`}
            tag="h3"
            fontSize="14px"
            fontWeight={700}
            mb="space-1"
          >
            {title}
          </Header>
          <Paragraph qaLabel={`${qaLabel}-text`} fontSize="s">
            {description}
          </Paragraph>
        </Content>
        <Footer>
          <Paragraph
            qaLabel={`${qaLabel}-button-text`}
            fontFamily="medium"
            fontSize="s"
            color="--fd-colors-component-button-button-link-content-base"
          >
            {buttonText}
          </Paragraph>
          <Icon
            qaLabel={`${qaLabel}-icon`}
            size="s"
            name="chevronRight"
            lineColor="--fd-colors-component-button-button-link-content-base"
          />
        </Footer>
      </Button>
    </Container>
  );
};

export default ArticleItem;
