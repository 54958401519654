import { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noop } from "lodash";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import {
  AWPath,
  openAWPage
} from "@tvg/sh-lib-account-wallet/src/utils/AWPages";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { openPreferencesModalAction } from "@tvg/sh-lib-preferences/redux/actions";
import { getIsLogged } from "@urp/store-selectors";
import {
  trackBetaFeatureFeedback,
  events as AlchemerEvents
} from "@urp/alchemer";
import mediator from "@tvg/mediator";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { MenuLinkProps, MenuRoutes, MenuRoutesProps } from "../types";
import {
  openContentMoreGtm as contentNavigationCallBack,
  moreLinksGtm as navigationCallBack,
  onExternalLinkCallback,
  navigateTutorialsGtm
} from "../utils";

interface UseMenuRoutesReturn {
  accountLinks: MenuLinkProps[];
  otherLinks: MenuLinkProps[];
}

const openLogin = (callback: NullaryFn<void>) => {
  mediator.base.dispatch({
    type: "OPEN_LOGIN",
    payload: {
      callback
    }
  });
};

// If accessing the Account Page doesn't require a login,
// there's a need to protect certain links with a prior login form
const handleLinkNeedingPriorLogin = (
  noLoginRequiredForAccountPage: boolean,
  isLogged: boolean,
  onClickHandler: NullaryFn<void>
) => {
  if (!isLogged && noLoginRequiredForAccountPage) {
    openLogin(onClickHandler);
  } else {
    onClickHandler();
  }
};

export function useMenuRoutes({
  menuRoutes,
  derbyGuide,
  globalFDRSupportLink,
  globalFDRPrivacyPolicyLink,
  isTutorialsLinkNew,
  doNotSell,
  feedbackUrl,
  accountNumber,
  termsConditions,
  xSellMigrationDate,
  talentPicks,
  watchFDTV,
  trackInformationList,
  enableNoLoginRequiredForAccountPage,
  onTransactionHistoryClick = noop,
  enablePreferencesCenter,
  enableURPEducationHub
}: MenuRoutesProps): UseMenuRoutesReturn {
  const isLogged = useSelector(getIsLogged);
  const dispatch = useDispatch();
  const {
    account,
    tutorials,
    californiaPolicy,
    accessibility,
    picks,
    live,
    educationHub
  } = menuRoutes as MenuRoutes;
  const showAWTransactionHistory = xSellMigrationDate;
  const noLoginRequiredForAccountPage =
    enableNoLoginRequiredForAccountPage && !isXSell();
  const termsConditionsRef = useRef(termsConditions);
  termsConditionsRef.current = termsConditions;

  const accountLinks = useMemo<MenuLinkProps[]>(
    () => [
      {
        qaLabel: "account-nav-balance",
        to: account,
        onClick: () => openAWPage(AWPath.Account),
        linkText: "Account balance",
        isExternalLink: true
      },
      {
        qaLabel: "account-nav-transactionHistory",
        to: account,
        onClick: accountNumber
          ? () => {
              onTransactionHistoryClick();
              contentNavigationCallBack(
                showAWTransactionHistory
                  ? "Transactions before"
                  : "Transactions History"
              );
            }
          : onOpenComplianceModal,
        linkText: showAWTransactionHistory
          ? `Transactions before ${xSellMigrationDate}`
          : "Transaction History"
      },
      ...(showAWTransactionHistory
        ? [
            {
              qaLabel: "account-nav-transactionHistoryAWFlows",
              to: account,
              onClick: () =>
                openAWPage(AWPath.Transactions, {
                  onOpenAWPage: () =>
                    contentNavigationCallBack("Transaction History")
                }),
              linkText: `Transactions after ${xSellMigrationDate}`
            }
          ]
        : []),
      {
        qaLabel: "account-nav-taxInformation",
        to: account,
        onClick: () => openAWPage(AWPath.TaxInformation),
        linkText: "Tax information",
        isExternalLink: true
      },
      {
        qaLabel: "account-nav-settings",
        to: account,
        onClick: () => openAWPage(AWPath.Settings, { forceWeb: true }),
        linkText: "Account Settings",
        isExternalLink: true
      },
      {
        qaLabel: "account-activity-statement",
        to: account,
        onClick: () => openAWPage(AWPath.TaxCenter, { forceWeb: true }),
        linkText: "Tax Center",
        isExternalLink: true
      },
      {
        qaLabel: "account-my-spend",
        to: account,
        onClick: () => openAWPage(AWPath.MySpend, { forceWeb: true }),
        linkText: "My Spend",
        isExternalLink: true
      },
      ...(enablePreferencesCenter
        ? [
            {
              qaLabel: "account-preferences",
              to: account,
              onClick: () => dispatch(openPreferencesModalAction()),
              linkText: "Preferences",
              isExternalLink: false
            }
          ]
        : [])
    ],
    [account, accountNumber]
  );

  const otherLinks = useMemo<MenuLinkProps[]>(
    () => [
      ...(derbyGuide?.toggle && derbyGuide?.url && derbyGuide?.label
        ? [
            {
              qaLabel: "account-nav-derby-betting",
              to: "/",
              onClick: (e: Event) => {
                onExternalLinkCallback(derbyGuide?.url || "", e);
                navigationCallBack(
                  derbyGuide?.url || "",
                  derbyGuide?.label || ""
                );
              },
              linkText: derbyGuide.label || "",
              isExternalLink: true
            }
          ]
        : []),
      ...(talentPicks.toggle
        ? [
            {
              qaLabel: "account-nav-talent-picks",
              to: picks,
              onClick: () => contentNavigationCallBack("FDTV Talent Picks"),
              linkText: talentPicks.label,
              isExternalLink: false
            }
          ]
        : []),
      ...(watchFDTV.toggle
        ? [
            {
              qaLabel: "account-nav-watch-fdtv",
              to: live,
              onClick: () => contentNavigationCallBack("Watch FDTV"),
              linkText: watchFDTV.label || "",
              isExternalLink: false
            }
          ]
        : []),
      ...(!isXSell()
        ? [
            {
              qaLabel: "account-nav-tracks-information-list",
              to: trackInformationList?.url || "",
              onClick: () =>
                contentNavigationCallBack(trackInformationList?.label || ""),
              linkText:
                trackInformationList?.label ?? "Tracks Information List",
              isExternalLink: false
            }
          ]
        : []),
      {
        qaLabel: "account-nav-FAQ",
        to: account || "",
        onClick: (e: Event) => {
          onExternalLinkCallback(globalFDRSupportLink || "", e);
          navigationCallBack(globalFDRSupportLink || "", "FanDuel FAQ");
        },
        linkText: "FanDuel FAQ",
        isExternalLink: true
      },
      ...(enableURPEducationHub
        ? [
            {
              qaLabel: "account-nav-tutorials",
              to: educationHub || "",
              onClick: () => {
                contentNavigationCallBack("Tutorials");
                navigateTutorialsGtm(tutorials);
                AlchemerEvents.selectTutorialsTab();
              },
              linkText: "Tutorials",
              highlightedText: isTutorialsLinkNew ? "New" : ""
            }
          ]
        : [
            {
              qaLabel: "account-nav-tutorials",
              to: tutorials || "",
              onClick: () => {
                contentNavigationCallBack("Tutorials");
                navigateTutorialsGtm(tutorials);
                AlchemerEvents.selectTutorialsTab();
              },
              linkText: "Tutorials",
              highlightedText: isTutorialsLinkNew ? "New" : ""
            }
          ]),
      {
        qaLabel: "account-nav-tutorials",
        to: tutorials || "",
        onClick: () => {
          contentNavigationCallBack("Tutorials");
          navigateTutorialsGtm(tutorials);
          AlchemerEvents.selectTutorialsTab();
        },
        linkText: "Tutorials",
        highlightedText: isTutorialsLinkNew ? "New" : ""
      },
      {
        qaLabel: "account-nav-privacyPolicy",
        to: account || "",
        onClick: (e: Event) => {
          onExternalLinkCallback(globalFDRPrivacyPolicyLink || "", e);
          navigationCallBack(
            globalFDRPrivacyPolicyLink || "",
            "Privacy Policy"
          );
        },
        linkText: "Privacy Policy",
        isExternalLink: true
      },
      {
        qaLabel: "account-nav-californiaPolicy",
        to: account,
        onClick: (e: Event) => {
          onExternalLinkCallback(
            `${globalFDRPrivacyPolicyLink}#state-specific-privacy-rights`,
            e
          );
          navigationCallBack(
            `${globalFDRPrivacyPolicyLink}#state-specific-privacy-rights`,
            "Privacy Policy"
          );
        },
        linkText: "California Privacy Policy",
        isExternalLink: true
      },
      ...(doNotSell.toggle
        ? [
            {
              qaLabel: "account-do-not-sell",
              to: "/",
              onClick: (e: Event) => {
                onExternalLinkCallback(doNotSell?.url || "", e);
                navigationCallBack(
                  doNotSell?.url || "",
                  "Do not sell Navigation"
                );
              },
              linkText: doNotSell.label,
              isExternalLink: true
            }
          ]
        : []),
      {
        qaLabel: "account-nav-accessibility",
        to: accessibility,
        onClick: () => contentNavigationCallBack("accessibility"),
        linkText: "Accessibility"
      },
      {
        qaLabel: "account-nav-TermsConditions",
        to: account,
        onClick: (e: Event) => {
          const onClickHandler = () => {
            if (termsConditionsRef.current) {
              onExternalLinkCallback(termsConditionsRef.current.url || "", e);
              navigationCallBack(
                termsConditionsRef.current.url || "",
                termsConditionsRef.current.label || ""
              );
            }
          };
          handleLinkNeedingPriorLogin(
            noLoginRequiredForAccountPage,
            isLogged,
            onClickHandler
          );
        },
        linkText: termsConditions?.label || "",
        isExternalLink: true
      },
      {
        qaLabel: "account-nav-feedback",
        to: feedbackUrl,
        onClick: (e: Event) => {
          const onClickHandler = () => {
            if (isXSell()) {
              onExternalLinkCallback(feedbackUrl || "", e);
              navigationCallBack(feedbackUrl || "", "Send Feedback");
            } else {
              e.preventDefault();
              trackBetaFeatureFeedback();
              AlchemerEvents.showSendFeedbackSurvey();
            }
          };
          handleLinkNeedingPriorLogin(
            noLoginRequiredForAccountPage,
            isLogged,
            onClickHandler
          );
        },
        linkText: "Send Feedback",
        isExternalLink: isXSell()
      },
      {
        qaLabel: "account-nav-responsibleGaming",
        to: account,
        onClick: () => {
          const onClickHandler = () => {
            openAWPage(AWPath.ResponsiblePlay);
          };
          handleLinkNeedingPriorLogin(
            noLoginRequiredForAccountPage,
            isLogged,
            onClickHandler
          );
        },
        linkText: "Responsible Gaming",
        isExternalLink: false
      }
    ],
    [
      globalFDRSupportLink,
      globalFDRPrivacyPolicyLink,
      derbyGuide.url,
      derbyGuide.label,
      derbyGuide.toggle,
      isTutorialsLinkNew,
      tutorials,
      californiaPolicy,
      accessibility,
      termsConditions?.url,
      termsConditions?.label,
      feedbackUrl,
      doNotSell.url,
      doNotSell.label,
      doNotSell.toggle,
      talentPicks.label,
      talentPicks.toggle,
      trackInformationList?.label,
      trackInformationList?.toggle
    ]
  );

  return {
    accountLinks,
    otherLinks
  };
}

export default useMenuRoutes;
