import React from "react";
import { noop } from "lodash";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { Button } from "@tvg/design-system/web";
import {
  Container,
  JoinBarMessage,
  JoinBarActions,
  JoinBarTitle,
  JoinBarDescription
} from "./styled-components";

type Props = {
  title: string;
  message: string;
  onLogin: NullaryFn<void>;
  onJoin: NullaryFn<void>;
  qaLabel?: string;
  isVisible: boolean;
};

const JoinBar = ({
  title,
  message,
  onLogin,
  onJoin,
  qaLabel = "join-bar",
  isVisible
}: Props) => (
  <Container data-qa-label={qaLabel} isVisible={isVisible}>
    <JoinBarMessage>
      <JoinBarTitle>{title}</JoinBarTitle>
      <JoinBarDescription>{message}</JoinBarDescription>
    </JoinBarMessage>
    <JoinBarActions>
      <Button
        variant="primary"
        onClick={() => onLogin()}
        qaLabel={`${qaLabel}-login`}
      >
        Log in
      </Button>
      <Button
        variant="betting"
        onClick={() => onJoin()}
        qaLabel={`${qaLabel}-join`}
      >
        Join now
      </Button>
    </JoinBarActions>
  </Container>
);

JoinBar.defaultProps = {
  title: "Want to wager?",
  message: "Log in or join to get started.",
  onLogin: noop,
  onJoin: noop,
  qaLabel: "join-bar"
};

export default JoinBar;
