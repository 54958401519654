import { get } from "lodash";
import { fetchStoryblok } from "@tvg/sh-lib-storyblok/src/fetchStoryblok";
import {
  STORYBLOK_CONTENT_PATH,
  STORYBLOK_SLUGS
} from "@tvg/sh-lib-storyblok/src/constraints/config";
import {
  StoryblokEnvironment,
  StoryblokStory,
  StoryblokVersion
} from "@tvg/sh-lib-storyblok/src/types";
import { isTvg5, isURP } from "@tvg/utils/generalUtils";
import {
  BannersStoryblokContent,
  BannersConfigContext,
  BannerConfig,
  HeaderBanner
} from "../types";

const formatDate = (storyblokDate: string) => {
  if (!storyblokDate) {
    return null;
  }

  const [date, time] = storyblokDate.split(" ");
  return new Date(`${date}T${time}:00Z`);
};

export const getDerbyBanner = (
  storyblokComponent: StoryblokStory<BannersStoryblokContent>,
  eventType: string
): Array<HeaderBanner> => {
  if (isTvg5() && isURP) {
    return get(storyblokComponent, `content.${eventType}_banner`, []).filter(
      (banner: { context: BannersConfigContext }) =>
        banner.context.includes(BannersConfigContext.DESKTOP)
    );
  }
  return get(storyblokComponent, `content.${eventType}_banner`, []).filter(
    (banner: { context: BannersConfigContext }) =>
      banner.context.includes(BannersConfigContext.X_SELL) ||
      banner.context.includes(BannersConfigContext.MOBILE)
  );
};

export const getDerbyBannerConfigByStoryblok = async (
  storyblokVersion = StoryblokVersion.DRAFT,
  overrideToken?: StoryblokEnvironment
): Promise<BannerConfig> => {
  try {
    const storyblokResponse = await fetchStoryblok<BannersStoryblokContent>(
      STORYBLOK_CONTENT_PATH.BANNERS_CONFIG,
      STORYBLOK_SLUGS.BANNERS,
      storyblokVersion,
      overrideToken
    );

    const derbyBannerDefault = getDerbyBanner(storyblokResponse, "default");
    const derbyBannerEventDay = getDerbyBanner(storyblokResponse, "event_day");
    const derbyBannerPreEvent = getDerbyBanner(storyblokResponse, "pre_event");
    const derbyBannerFridayPreEvent = getDerbyBanner(
      storyblokResponse,
      "friday_pre_event"
    );
    const derbyBannerThursdayPreEvent = getDerbyBanner(
      storyblokResponse,
      "thursday_pre_event"
    );

    const eventDate = formatDate(get(storyblokResponse, "content.event_date"));
    const eventName: string = get(storyblokResponse, "content.event_name", "");
    const trackAbbr: string = get(storyblokResponse, "content.track_abbr", "");
    const raceNumber: number = +get(
      storyblokResponse,
      "content.event_race_number",
      ""
    );

    return {
      eventInfo: {
        eventDate,
        trackAbbr,
        raceNumber,
        eventName: {
          label: eventName
        }
      },
      derbyBanner: {
        default: derbyBannerDefault,
        eventDay: derbyBannerEventDay,
        fridayPreEvent: derbyBannerFridayPreEvent,
        thursdayPreEvent: derbyBannerThursdayPreEvent,
        preEvent: derbyBannerPreEvent
      }
    };
  } catch (e) {
    console.warn("Error retrieving homepage config by Storyblok", e);

    return {
      eventInfo: undefined,
      derbyBanner: undefined
    };
  }
};
