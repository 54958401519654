import React from "react";
import DOMPurify from "dompurify";
import { RichTextStyled } from "./styled-components";

interface RichTextProps {
  qaLabel?: string;
  hasPadding?: boolean;
  hasSectionsMargin?: boolean;
  text: string;
  className?: string;
  boldTextMedium?: boolean;
}

export const RichText = ({
  qaLabel = "richText",
  hasPadding = false,
  hasSectionsMargin = false,
  boldTextMedium = false,
  text,
  className
}: RichTextProps) => (
  <RichTextStyled
    id="rich-text"
    data-qa-label={qaLabel}
    boldTextMedium={boldTextMedium}
    hasPadding={hasPadding}
    hasSectionsMargin={hasSectionsMargin}
    dangerouslySetInnerHTML={{
      __html: typeof window !== "undefined" ? DOMPurify.sanitize(text) : ""
    }}
    className={className}
  />
);

export default RichText;
