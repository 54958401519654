import React from "react";
import {
  Container,
  Columns,
  MainContent,
  SideContent,
  MobileHeader
} from "../../contents/styled-components";
import { LatestVideos, VideoSectionWrapper } from "./styled-components";
import {
  PageTitleLoading,
  FAQLoading,
  VideoCardLoading,
  ArticleSectionLoading,
  MobileHeaderLoading,
  TabsLoading,
  SectionHeaderLoading
} from "./Components";

interface HomePageLoadingProps {
  isDesktop: boolean;
}

export default function HomePageLoading({ isDesktop }: HomePageLoadingProps) {
  return (
    <Container data-qa-label="education-hub-loading">
      {isDesktop ? (
        <PageTitleLoading />
      ) : (
        <MobileHeader>
          <MobileHeaderLoading />
          <TabsLoading />
        </MobileHeader>
      )}

      <VideoSectionWrapper>
        <SectionHeaderLoading />
        <LatestVideos>
          <VideoCardLoading />
          <VideoCardLoading />
          <VideoCardLoading />
        </LatestVideos>
      </VideoSectionWrapper>

      <Columns isArticle={false} hasMobileBottomSpace={false}>
        <MainContent>
          <ArticleSectionLoading />
          <ArticleSectionLoading />
          <ArticleSectionLoading />
          <ArticleSectionLoading />
        </MainContent>

        <SideContent>
          <FAQLoading />
        </SideContent>
      </Columns>
    </Container>
  );
}
