import React, { memo } from "react";
import formatCurrency from "@tvg/formatter/currency";
import { Icon } from "@tvg/design-system";
import { Button, useColorTokens } from "@tvg/design-system/web";
import { NullaryFn } from "@tvg/ts-types/Functional";
import {
  AccountBalancesWrapper,
  Row,
  ColumnLabel,
  ColumnValue
} from "./styled-components";
import { Balance } from "../../types";

interface Props {
  balances: Balance[];
  qaLabel?: string;
  onViewBalanceDetails: NullaryFn<void>;
  onOpenPlayableBalanceModal: NullaryFn<void>;
}

export const AccountBalances = memo(
  ({
    balances,
    qaLabel = "account-balances",
    onViewBalanceDetails,
    onOpenPlayableBalanceModal
  }: Props) => {
    const colorTokens = useColorTokens();
    return (
      <AccountBalancesWrapper data-qa-label={qaLabel}>
        <Row>
          <ColumnLabel bolded>Playable Balance</ColumnLabel>
          <ColumnValue onClick={() => onOpenPlayableBalanceModal()} withCursor>
            <Icon
              name="info"
              lineColor={colorTokens.content.onDark}
              size="s"
              backgroundColor="transparent"
            />
          </ColumnValue>
        </Row>
        {balances.map((balance) => {
          const balanceQaLabel = `${qaLabel}-${balance.label
            .toLowerCase()
            .trim()
            .replace(/ /g, "-")}`;

          return (
            <Row data-qa-label={balanceQaLabel} key={balance.label}>
              <ColumnLabel
                isHighlighted={!!balance.isHighlighted}
                data-qa-label={`${balanceQaLabel}-text`}
              >
                {balance.label}
              </ColumnLabel>
              <ColumnValue
                isHighlighted={!!balance.isHighlighted}
                data-qa-label={`${balanceQaLabel}-value`}
              >
                {formatCurrency(balance.value)}
              </ColumnValue>
            </Row>
          );
        })}

        <Button
          qaLabel="account-balances-details-button"
          isStretched
          variant="primary"
          border="solid 1px var(--fd-colors-content-on-dark)"
          marginTop={4}
          onClick={onViewBalanceDetails}
        >
          View Balance Details
        </Button>
      </AccountBalancesWrapper>
    );
  }
);

export default AccountBalances;
