import React, { RefObject, useEffect, useMemo, useRef } from "react";
import { range, get } from "lodash";
import { IconButton } from "@tvg/design-system/src/components/iconButton";
import { isSameYear, isSameMonth, getYear } from "date-fns";
import { Button } from "../../../../Button";
import { Icon } from "../../../../Icon";
import {
  YearLabel,
  OpenButtonWrapper,
  SelectorWrapper,
  SelectorContainer,
  SelectorHeader,
  CloseButtonWrapper,
  SelectorTitle,
  YearWrapper,
  PickerWrapper,
  PickerButtonWrapper
} from "./styled-components";
import { MonthSelectorProps } from "../types";

export const MonthSelector = ({
  date,
  localeUtils,
  isMonthSelector,
  setIsMonthSelector,
  selectedMonth,
  setSelectedMonth,
  initialYearRange = 21
}: MonthSelectorProps) => {
  const currentYear = new Date().getFullYear();
  const selectedYear = date.getFullYear();
  const initialYear = currentYear - initialYearRange;
  const months = localeUtils.getMonths();
  const currentMonth = months[date.getMonth()];
  const monthLabel = `${currentMonth} ${selectedYear}`;
  const wrapperRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (wrapperRef.current) {
      const selectedYearElement = document.getElementById(
        `month-selector-${getYear(selectedMonth)}`
      );
      const selectedYearElementTopOffset =
        selectedYearElement?.getBoundingClientRect().top || 0;

      const headerHeight =
        headerRef.current?.getBoundingClientRect().height || 0;

      const amountToScroll =
        selectedYearElementTopOffset -
        wrapperRef.current.getBoundingClientRect().top -
        headerHeight;

      wrapperRef.current.scrollTop = amountToScroll;
    }
  }, []);

  const renderMonths = useMemo(() => {
    const yearRange = range(currentYear, initialYear);
    const monthRange = range(0, 12);

    return yearRange.map((year) => (
      <YearWrapper key={year} id={`month-selector-${year}`}>
        <YearLabel>{year}</YearLabel>
        <PickerWrapper>
          {monthRange.map((month) => {
            const dateToRender = new Date(year, month);
            const isSelectedMonth =
              isSameMonth(selectedMonth as Date, dateToRender) &&
              isSameYear(selectedMonth as Date, dateToRender);

            return (
              <PickerButtonWrapper
                key={month}
                isSelectedMonth={isSelectedMonth}
                {...(isSelectedMonth
                  ? { "data-qa-label": "month-selector-button-active" }
                  : {})}
              >
                <Button
                  qaLabel="month-selector-button"
                  variant={isSelectedMonth ? "primary" : "tertiary"}
                  onClick={() => {
                    setSelectedMonth(dateToRender);
                    setIsMonthSelector(false);
                  }}
                >
                  {get(months, month, "")}
                </Button>
              </PickerButtonWrapper>
            );
          })}
        </PickerWrapper>
      </YearWrapper>
    ));
  }, [selectedMonth]);

  const renderMonthSelector = () => (
    <SelectorWrapper
      ref={wrapperRef as RefObject<HTMLDivElement>}
      data-qa-label="month-selector"
    >
      <SelectorContainer>
        <SelectorHeader ref={headerRef as RefObject<HTMLDivElement>}>
          <CloseButtonWrapper>
            <IconButton
              qaLabel="month-selector-close-button"
              variant="tertiary"
              iconName="chevronLeft"
              onPress={() => setIsMonthSelector(false)}
            />
          </CloseButtonWrapper>
          <SelectorTitle>Select Month</SelectorTitle>
        </SelectorHeader>
        {renderMonths}
      </SelectorContainer>
    </SelectorWrapper>
  );

  const renderMonthCaption = () => (
    <div className="DayPicker-Caption">
      <OpenButtonWrapper>
        <Button
          qaLabel="month-selector-open-button"
          variant="tertiary"
          onClick={() => setIsMonthSelector(true)}
          iconPosition="end"
          icon={
            <Icon
              name="chevronRight"
              size="s"
              lineColor="component.button.tertiary.content.base"
            />
          }
        >
          {monthLabel}
        </Button>
      </OpenButtonWrapper>
    </div>
  );

  return isMonthSelector ? renderMonthSelector() : renderMonthCaption();
};

export default MonthSelector;
