import React from "react";
import { Helmet } from "react-helmet";
import { SeoMetaData } from "../../types";

interface SeoHeaderProps {
  seoMetaData: SeoMetaData;
}

const SeoHeader = ({ seoMetaData }: SeoHeaderProps) => {
  const { title, description } = seoMetaData;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default SeoHeader;
