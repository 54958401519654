import React from "react";
import StoryblokClient from "storyblok-js-client";
import Section from "../Section";
import RichText from "../RichText";
import { EducationHubSectionType, ContentRichText } from "../../types";

interface RichTextSectionProps {
  data: EducationHubSectionType;
  storyblokClient?: StoryblokClient;
  hasSectionsMargin?: boolean;
  footer?: React.ReactNode;
  id?: string;
}

const RichTextSection = ({
  data,
  storyblokClient,
  hasSectionsMargin = false,
  footer,
  id
}: RichTextSectionProps) => {
  const qaLabel = data.title.replace(/ /g, "-").toLowerCase();

  return (
    <Section id={id} title={data.title} qaLabel={qaLabel} footer={footer}>
      <RichText
        hasPadding
        hasSectionsMargin={hasSectionsMargin}
        boldTextMedium
        text={
          storyblokClient?.richTextResolver.render(
            (data.content[0] as ContentRichText)?.text
          ) ?? ""
        }
      />
    </Section>
  );
};

export default RichTextSection;
