import styled, { css } from "styled-components";

export const Container = styled.li<{ isActive: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 12px 16px;
  border: 1px solid var(--fd-colors-border-subtle);
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: var(--fd-colors-core-blue);
    `}

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  & + & {
    border-top-color: transparent;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
