import { UnaryFn } from "@tvg/ts-types/Functional";
import { Icon, Paragraph } from "@tvg/design-system/web";
import React from "react";
import { Container, TextContainer } from "./styled-components";

interface Props {
  title: string;
  description: string;
  onClick: UnaryFn<string, void>;
  isActive?: boolean;
  qaLabel?: string;
}

function TemplateItem({
  title,
  description,
  onClick,
  isActive = false,
  qaLabel
}: Props) {
  return (
    <Container
      onClick={() => onClick(title)}
      role="button"
      isActive={isActive}
      data-qa-label={qaLabel}
    >
      <TextContainer>
        <Paragraph
          fontFamily="medium"
          fontSize="m"
          color={
            isActive ? "--fd-colors-core-white" : "--fd-colors-content-strong"
          }
          qaLabel="qaLabel"
        >
          {title}
        </Paragraph>
        <Paragraph
          fontSize="s"
          color={
            isActive ? "--fd-colors-core-white" : "--fd-colors-content-default"
          }
          qaLabel="qaLabel"
          marginTop="2"
        >
          {description}
        </Paragraph>
      </TextContainer>
      {isActive && (
        <Icon name="tick" size="s" lineColor="--fd-colors-core-white" />
      )}
    </Container>
  );
}

export default TemplateItem;
