import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import tvgConf from "@tvg/conf";
import { fetchStoryblok } from "@tvg/sh-lib-storyblok/src/fetchStoryblok";
import { STORYBLOK_CONTENT_PATH } from "@tvg/sh-lib-storyblok/src/constraints/config";
import { getStoredSlugContent } from "../selectors";
import { setSelectedSlug, setStoredContentSlugs } from "../redux/actions";
import { StoryblokPageContent, StoryblokWalkthroughContent } from "../types";

const useContentData = <
  T extends StoryblokPageContent | StoryblokWalkthroughContent
>(
  selectedSlug: string
) => {
  const dispatch = useDispatch();

  const storedContent = useSelector((store) =>
    getStoredSlugContent<StoryblokPageContent | StoryblokWalkthroughContent>(
      store,
      selectedSlug
    )
  );

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [contentData, setContentData] = useState<
    StoryblokPageContent | StoryblokWalkthroughContent | undefined
  >(storedContent);

  const fetchContentData = useCallback(async () => {
    if (typeof storedContent === "undefined" && selectedSlug) {
      setLoading(true);
      try {
        const data = await fetchStoryblok<StoryblokPageContent>(
          STORYBLOK_CONTENT_PATH.EDUCATIONAL_HUB,
          selectedSlug,
          tvgConf().config().storyblokVersion
        );

        dispatch(
          setStoredContentSlugs({ key: selectedSlug, data: data.content })
        );
        setContentData(data.content);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    } else if (selectedSlug === "") {
      setContentData(undefined);
    }
  }, [storedContent, selectedSlug]);

  useEffect(() => {
    fetchContentData();
    if (selectedSlug) {
      dispatch(setSelectedSlug(selectedSlug));
    }
  }, [dispatch, selectedSlug]);

  return {
    contentData: (storedContent ?? contentData) as T | undefined,
    isLoading,
    isError
  };
};

export default useContentData;
