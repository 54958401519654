import React, { useState } from "react";
import Icon from "@fdr/static-ui/Icons";
import { isXSell, openExternalLink } from "@tvg/sh-utils/mobileUtils";
import { fanduel } from "@fdr/static-ui/Icons/icons";
import { useNavigate } from "react-router-dom";
import { Button } from "@tvg/design-system";
import {
  Wrapper,
  IconWrapper,
  TextWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  ResidentialAddressErrorMessageWrapper,
  ErrorMessage,
  StateExpand,
  StateList,
  PhysicalLocationErrorMessageContinued,
  ButtonsWrapper,
  ErrorButton
} from "./styled-components";
import { InvalidResidentialAddressErrorProps } from "./types";

export const InvalidResidentialAddressError = ({
  allowedStates = [],
  isNative,
  invalidResidentialAddressMessages,
  onClose
}: InvalidResidentialAddressErrorProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleExternalLink = (url: string) => {
    onClose();
    openExternalLink(url);
  };

  const goHomeClick = () => {
    onClose();
    navigate("/home");
  };
  const {
    title,
    subtitle,
    residentialAddressErrorMessage,
    seeEligibleStates,
    physicalLocationErrorMessage,
    backToSportsbook,
    learnMore,
    goHome,
    neverMind
  } = invalidResidentialAddressMessages;

  const chevronIcon = isDropdownOpen ? "chevronUp" : "chevronDown";
  const primaryButtonText = isXSell() ? backToSportsbook : learnMore;
  const secondaryButtonText = isNative ? goHome : neverMind;
  const allowedStatesList = allowedStates.join(", ");

  return (
    <Wrapper
      data-qa-label="invalid-residential-address-error"
      isNative={isNative}
    >
      <IconWrapper>
        <Icon
          icon={fanduel}
          color="var(--fd-colors-brand-primary-default)"
          size="62"
        />
      </IconWrapper>

      <TextWrapper>
        <TitleWrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TitleWrapper>

        <ResidentialAddressErrorMessageWrapper>
          <ErrorMessage
            dangerouslySetInnerHTML={{
              __html: residentialAddressErrorMessage
            }}
          />
          <StateExpand>
            <Button
              qaLabel="dropDown-Button"
              size="s"
              variant="tertiary"
              tertiaryColor="blue_accent.500"
              icon={chevronIcon}
              iconPosition="end"
              onPress={() => toggleDropdown()}
            >
              {seeEligibleStates}
            </Button>
            {isDropdownOpen && <StateList>{allowedStatesList}</StateList>}
          </StateExpand>
        </ResidentialAddressErrorMessageWrapper>

        <ErrorMessage
          dangerouslySetInnerHTML={{
            __html: physicalLocationErrorMessage[0]
          }}
        />
        <PhysicalLocationErrorMessageContinued>
          {physicalLocationErrorMessage[1]}
        </PhysicalLocationErrorMessageContinued>
      </TextWrapper>

      <ButtonsWrapper>
        <ErrorButton>
          <button
            type="button"
            onClick={() =>
              isXSell()
                ? handleExternalLink("https://sportsbook.fanduel.com")
                : handleExternalLink("https://www.fanduel.com")
            }
          >
            {primaryButtonText}
          </button>
        </ErrorButton>
        {!isXSell() && (
          <ErrorButton data-qa-label="home-Button" isGoHome>
            <button type="button" onClick={() => goHomeClick()}>
              {secondaryButtonText}
            </button>
          </ErrorButton>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default InvalidResidentialAddressError;
