import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import TVGConf from "@tvg/conf";
import { breakpoints, useMediaQuery, useTheme } from "@tvg/design-system";
import ComplianceBanner from "@urp/compliance-banner";
import LocationRevalidator from "@tvg/revalidate-location";
import { getResidenceState } from "@urp/store-selectors";
import useActiveBets from "@tvg/sh-lib-my-bets/hooks/useActiveBets";
import { REVALIDATE_LOCATION_MESSAGES } from "./components/Header/constants";
import StandardHeader from "./components/Header";
import HamburgerMenu from "./components/HamburgerMenu";
import { GlobalStyle, Header, AlertContainer } from "./styled-components";
import { setIsHamburgerMenuOpen as setIsHamburgerMenuOpenAction } from "./store/actions";
import { getIsHamburgerMenuOpen } from "./store/selectors";
import { handleHamburgerMenuGtm } from "./gtm";

const HDR = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktopMinXXl = useMediaQuery(breakpoints.desktop.min.xxl);
  const isDesktopMaxXl = useMediaQuery(breakpoints.desktop.max.xxl);
  const wasMenuOpen = useSelector(getIsHamburgerMenuOpen);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const userHomeState = useSelector(getResidenceState);
  const { totalActiveBets: activeBets } = useActiveBets();

  const logoBrand = useMemo(
    () =>
      TVGConf().brand === "fdr" && userHomeState === "IA"
        ? "fdr-iowa"
        : TVGConf().brand,
    [userHomeState]
  );

  const onOpenHamburgerMenu = useCallback(() => {
    dispatch(setIsHamburgerMenuOpenAction(true));
    setIsHamburgerMenuOpen(true);
    handleHamburgerMenuGtm(true);
  }, []);

  const onCloseHamburgerMenu = useCallback(() => {
    dispatch(setIsHamburgerMenuOpenAction(false));
    setIsHamburgerMenuOpen(false);
    handleHamburgerMenuGtm(false);
  }, []);

  // close menu if user resizes browser
  useEffect(() => {
    if ((isDesktopMinXXl && isHamburgerMenuOpen) || !wasMenuOpen) {
      setIsHamburgerMenuOpen(false);
    }
  }, [isDesktopMinXXl, isHamburgerMenuOpen, wasMenuOpen]);

  // open menu if user resizes browser again and was open before
  useEffect(() => {
    if (isDesktopMaxXl && wasMenuOpen) {
      setIsHamburgerMenuOpen(true);
    }
  }, [isDesktopMaxXl, wasMenuOpen]);

  return (
    <>
      <GlobalStyle />
      <Header theme={theme} data-qa-label="headerDesktop">
        <StandardHeader
          onClickHamburger={onOpenHamburgerMenu}
          activeBets={activeBets}
          logoBrand={logoBrand}
        />
        <HamburgerMenu
          isOpen={isHamburgerMenuOpen}
          logoBrand={logoBrand}
          onClose={onCloseHamburgerMenu}
        />
        <LocationRevalidator
          device="desktop"
          messages={REVALIDATE_LOCATION_MESSAGES}
        />
      </Header>
      <ComplianceBanner Wrapper={AlertContainer} />
    </>
  );
};

export default HDR;
