import React, { useRef, useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { openExternalLink } from "@tvg/sh-utils/mobileUtils";
import { Accordion, AccordionItem } from "@tvg/design-system/web";
import { DescriptionLabel } from "./styled-components";
import { DescriptionProps } from "./types";

const Description = ({
  description,
  qaLabel,
  onAnimationEnd
}: DescriptionProps) => {
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const labelRef = useRef<HTMLDivElement | null>(null);
  const [isAccordionDisabled, setAccordionDisabled] = useState(false);

  const parseWithTarget = (desc: string) => {
    DOMPurify.addHook("afterSanitizeAttributes", (node) => {
      if ("target" in node) {
        node.setAttribute("id", node.getAttribute("href") || "");
        setTimeout(() => {
          const element = document.getElementById(
            node.getAttribute("href") || ""
          );
          if (element)
            element.addEventListener("click", (e) => {
              e.preventDefault();
              e.stopPropagation();
              openExternalLink(element.getAttribute("href") || "");
            });
        }, 50);
      }
    });

    return DOMPurify.sanitize(desc);
  };

  useEffect(() => {
    const measureHeight = () => {
      if (labelRef.current) {
        // scrollHeight includes content not visible due to overflow or truncation
        const height = labelRef.current?.scrollHeight;
        setAccordionDisabled(height <= 18);
      }
    };

    // Measure height after the DOM updates
    const timeout = setTimeout(measureHeight, 0);

    return () => clearTimeout(timeout);
  }, [description.description]);

  return (
    <>
      {isMobile ? (
        <Accordion>
          <AccordionItem
            hasBorder={false}
            title={description.title}
            onAnimationEnd={onAnimationEnd}
            isDisabled={isAccordionDisabled}
            showPreview
          >
            <DescriptionLabel
              ref={labelRef}
              data-qa-label={`${qaLabel}-description`}
              dangerouslySetInnerHTML={{
                __html: parseWithTarget(description.description)
              }}
            />
          </AccordionItem>
        </Accordion>
      ) : (
        <DescriptionLabel
          data-qa-label={`${qaLabel}-description`}
          dangerouslySetInnerHTML={{
            __html: parseWithTarget(description.description)
          }}
        />
      )}
    </>
  );
};

export default Description;
