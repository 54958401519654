import React from "react";
import TopPools from "@urp/top-pools";
import SeoFooter from "@tvg/atomic-ui/_static/SeoFooter";
import PromosWidgetComponent from "@urp/promos-widget";
import PreviousWinnersComponent from "@urp/previous-winners";
import { TipsAndPicks } from "@urp/tips-picks";
import { TalentPickList } from "@urp/talent-picks";
import QuickLinks from "@urp/quick-links";
import { TopRaces } from "@urp/popular-races";
import TopTracks from "@urp/top-tracks";
import { Device } from "@tvg/ts-types/Device";
import { Racing101Section } from "@urp/education-hub";
import { ComponentMapping } from "./types";

const componentsMapping: ComponentMapping = {
  QuickLinks: {
    component: () => <QuickLinks />
  },
  TopRaces: {
    component: (props) => <TopRaces title={props.title} />
  },
  TipsAndPicks: {
    component: (props) => (
      <TipsAndPicks qaLabel="tips-picks" title={props.title} />
    )
  },
  TopPools: {
    component: (props) => (
      <TopPools shouldUpdate device={Device.DESKTOP} title={props.title} />
    )
  },
  TopTracks: {
    component: (props) => <TopTracks title={props.title} />
  },
  PromosWidget: {
    component: (props) => <PromosWidgetComponent title={props.title} />
  },
  PreviousWinners: {
    component: (props) => (
      <PreviousWinnersComponent device={Device.DESKTOP} title={props.title} />
    )
  },
  TalentPicks: {
    component: (props) => <TalentPickList onHomePage title={props.title} />
  },
  FeaturedSection: {
    component: (props) => (
      <Racing101Section title={props.title} device={Device.DESKTOP} />
    )
  },
  SEOFooter: {
    component: (props) => (
      <SeoFooter
        message={props.text}
        title={props.title}
        device={Device.DESKTOP}
      />
    )
  }
};

export default componentsMapping;
