import React from "react";
import loadable from "@loadable/component";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useNavigate } from "@tvg/custom-hooks";
import { isUserInAWFlows } from "@tvg/sh-lib-account-actions/src/selectors/user-ts";
import useTalentPicksWithFilters from "@urp/talent-picks/src/hooks/useTalentPicksWithFilters";
import {
  useAmplitude,
  useAnchorInterceptor,
  useHashInterceptor,
  useMediatorClassicSharedEvents,
  usePageViewEventDispatch,
  useRefreshApp,
  useRouteInterceptor,
  useSeoData,
  useSessionInterceptor,
  useReloadApp
} from "./hooks";
import GlobalModals from "./GlobalModals";
import RedirectPage from "./pages/RedirectPage";
import RouteMapper from "./routes/RouteMapper";
import { getAmplitudeToggle } from "./store/selectors/general";

// @ts-ignore - while tvg/login-controller is not refactored
const LoginController = loadable(() => import("@tvg/login-controller"));

// @ts-ignore - while tvg/login-controller is not refactored
const LoginControllerAW = loadable(() => import("@tvg/login-controller-aw"));

const App = () => {
  const history = useNavigate();

  const useAmplitudeToggle = useSelector(getAmplitudeToggle);
  const enableAWFlows = useSelector(isUserInAWFlows);
  if (useAmplitudeToggle) {
    useAmplitude();
  }

  useRouteInterceptor();
  useHashInterceptor();
  useMediatorClassicSharedEvents();
  useAnchorInterceptor();
  useRefreshApp();
  useSessionInterceptor();
  useReloadApp();
  useTalentPicksWithFilters(true, false);

  const { dispatchEvent } = usePageViewEventDispatch({
    dispatchOnLocationChange: false
  });
  const { seoTitle, seoDescription } = useSeoData(dispatchEvent);

  return (
    <>
      {seoTitle && seoDescription && (
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={seoDescription} />
        </Helmet>
      )}
      {enableAWFlows ? (
        <LoginControllerAW history={history} />
      ) : (
        <LoginController history={history} />
      )}
      <Routes>
        {RouteMapper()}
        <Route path="*" element={<RedirectPage />} />
      </Routes>
      <GlobalModals enableAWFlows={enableAWFlows} />
    </>
  );
};

export default App;
