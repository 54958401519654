import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mediator from "@tvg/mediator";
import { breakpoints, Modal, useMediaQuery } from "@tvg/design-system";
import { Carousel, Button } from "@tvg/design-system/web";
import { events as AlchemerEvents } from "@urp/alchemer";
import { useNavigate } from "react-router";
import useFetchPlaylist from "../../hooks/useFetchPlaylist";
import VideoCard from "../VideoCard";
import Loading from "./loading";
import VideoPlayerDetails from "./VideoPlayerDetails";
import { CardWrapper } from "./styled-components";
import {
  getDefaultPlaylistSettings,
  getFullscreenModalState
} from "../../selectors";
import { openVideoModal, closeVideoModal } from "../../redux/actions";
import { Playlist, VideoNativeRotation } from "./types";
import { toggleNativeRotation } from "../../utils/jwplayer";

interface PlaylistVideosProps {
  title: string;
  playlistUrl: string | undefined;
  qaLabel?: string;
  seeAllUrl?: string;
  displayNavButtons?: boolean;
}

const PlaylistVideos = ({
  title,
  playlistUrl,
  qaLabel = "playlist-videos",
  seeAllUrl,
  displayNavButtons = true
}: PlaylistVideosProps) => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { playerScript } = useSelector(getDefaultPlaylistSettings);
  const { data, isLoading } = useFetchPlaylist(playlistUrl);

  const isVideoModalOpen = useSelector(getFullscreenModalState);
  const [selectedVideo, setSelectedVideo] = useState<Playlist | null>(null);

  const handleOpenVideoModal = (selected: Playlist) => {
    AlchemerEvents.selectEducationVideo();
    setSelectedVideo(selected);
    dispatch(openVideoModal());
  };

  const onCloseVideoModal = () => {
    dispatch(closeVideoModal());
  };

  const seeAllButton = (
    <Button
      variant="tertiary"
      onClick={() => navigate(seeAllUrl || "")}
      qaLabel="see-all-horse-racing-101-playlist-button"
      size="m"
    >
      See all
    </Button>
  );

  const carouselItems = useMemo(() => {
    if (isLoading) {
      return Array.from({ length: 3 }, (_, index) => (
        <Loading key={index.toString()} />
      ));
    }
    return data?.playlist.map((videoItem) => (
      <CardWrapper key={videoItem.video.playerId}>
        <VideoCard
          item={videoItem}
          qaLabel={`${qaLabel}-video-card-${videoItem.video.playerId}`}
          onClick={handleOpenVideoModal}
        />
      </CardWrapper>
    ));
  }, [data, isLoading]);

  const gtmHandlers = (
    type: string,
    payload: {
      errorType?: string;
      gaEventAction?: string;
    } = {},
    callback?: () => void
  ) => {
    if (selectedVideo?.description) {
      mediator.base.dispatch({
        type,
        payload: {
          videoProvider: "jw_player",
          videoTitle: selectedVideo?.description.title,
          ...payload
        }
      });

      if (callback) callback();
    }
  };

  return (
    <>
      <Carousel
        title={title}
        qaLabel={qaLabel}
        items={carouselItems ?? []}
        slideSize={292}
        headerChildren={seeAllUrl ? seeAllButton : null}
        slidesToScroll="auto"
        dragFree={!isDesktop}
        displayNavButtons={displayNavButtons}
        displayHeader={!!(title || seeAllUrl)}
      />

      <Modal
        qaLabel="video-details-modal"
        isOpen={isVideoModalOpen && !!selectedVideo}
        title={selectedVideo?.description.title ?? ""}
        type={isDesktop ? "lightbox" : "fullscreen"}
        onClose={onCloseVideoModal}
        maxWidth={833}
        p={0}
      >
        <VideoPlayerDetails
          isModalOpen={isVideoModalOpen && !!selectedVideo}
          description={selectedVideo?.description}
          src={selectedVideo?.video.src ?? ""}
          tracks={selectedVideo?.video.tracks ?? []}
          library={selectedVideo?.video.playerScript || playerScript}
          isAutoPlay
          onPause={() => gtmHandlers("VP_VIDEO_PAUSED")}
          allowFullscreen
          onPlay={(viewable: boolean) =>
            gtmHandlers(
              "VP_VIDEO_PLAY_CLICKED",
              {},
              () => viewable && gtmHandlers("VP_VIDEO_PLAYED_SUCCESSFULL")
            )
          }
          onVideoViewed={() => gtmHandlers("VP_VIDEO_VIEWED")}
          onVideoPlayedCompletely={() =>
            gtmHandlers("VP_VIDEO_PLAYED_COMPLETELY")
          }
          onVideoPlayAttemptFailed={(error: string) =>
            gtmHandlers("VP_VIDEO_PLAY_ERROR", {
              errorType: error,
              gaEventAction: error
            })
          }
          onSetupError={(error: string) =>
            gtmHandlers("VP_VIDEO_LOAD_FAILED", {
              errorType: error,
              gaEventAction: error
            })
          }
          onVideoMuteAndUnmute={(muted: boolean) =>
            gtmHandlers("VP_VIDEO_MUTED_UNMUTED", {
              gaEventAction: muted
                ? "Education Video Muted"
                : "Education Video Unmuted"
            })
          }
          onVideoFullScreen={(fullscreen: boolean) =>
            gtmHandlers("VP_VIDEO_SIZE_CHANGED", {
              gaEventAction: fullscreen
                ? "Video View Maximised"
                : "Video View Minimized"
            })
          }
          deviceLockRotationToggle={(isLock) => {
            toggleNativeRotation(
              isLock ? VideoNativeRotation.PORTRAIT : VideoNativeRotation.ALL
            );
          }}
        />
      </Modal>
    </>
  );
};

export default PlaylistVideos;
