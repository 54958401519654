import React, { memo } from "react";

import type { ButtonProps } from "./types";
import { Icon } from "../../../Icon";
import {
  ButtonContainer,
  StyledButton,
  TextContainer
} from "./styled-components";
import { Paragraph } from "../../../Typography";

export const Button = memo(
  ({
    onPress,
    icon,
    qaLabel,
    text,
    isSelected = false,
    isFullWidth = false,
    isFirstChild = false,
    isLastChild = false,
    hasShadow = false,
    isHoveringAll = false,
    ...props
  }: ButtonProps) => (
    <ButtonContainer
      isFullWidth={isFullWidth}
      isActive={isSelected}
      isHovering={isHoveringAll}
      onClick={onPress}
      {...props}
    >
      <StyledButton
        hasBorderRadius={isFirstChild && isLastChild}
        data-qa-label={qaLabel}
        isFullWidth={isFullWidth}
        hasShadow={hasShadow}
        data-active={isSelected}
        data-force-hover={isHoveringAll}
      >
        <TextContainer>
          {icon && (
            <Icon
              name={icon}
              size="s"
              lineColor="--fd-colors-link-default-base"
              mr="space-3"
            />
          )}

          <Paragraph
            color="--fd-colors-link-default-base"
            fontSize="14px"
            fontWeight={500}
            lineHeight="125%"
            fontFamily="regular"
          >
            {text}
          </Paragraph>
        </TextContainer>
      </StyledButton>
    </ButtonContainer>
  )
);

export default Button;
