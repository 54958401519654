import React from "react";
import { IconButton, Button } from "@tvg/design-system/web";
import { IconButtonContainer } from "./styled-components";

interface MoreButtonProps {
  showMore: boolean;
  isNavCollapsed: boolean;
  onClick: () => void;
}

const MoreButton = ({ showMore, isNavCollapsed, onClick }: MoreButtonProps) => (
  <>
    {isNavCollapsed ? (
      <IconButtonContainer>
        <IconButton
          mode="dark"
          onClick={onClick}
          qaLabel="show-more-icon-button"
          iconName={showMore ? "chevronUp" : "chevronDown"}
        />
      </IconButtonContainer>
    ) : (
      <Button
        variant="tertiaryDark"
        onClick={onClick}
        qaLabel="show-more-button"
        icon={showMore ? "chevronUp" : "chevronDown"}
        iconPosition="end"
        mt="space-2"
        ml="50px"
      >
        {showMore ? "Show less" : "Show more"}
      </Button>
    )}
  </>
);

export default MoreButton;
