import { isFdrProduct } from "@tvg/sh-utils/mobileUtils";
import { componentMap, layoutMap } from "./componentMap";

interface LayoutProps {
  isHeaderVisible: boolean;
  isLHNVisible: boolean;
  showTVG4Content: boolean;
  hasMobileView: boolean;
}

interface RouteProps {
  path: string;
  key: string;
  name: string;
  namespaces: string[];
  props?: {
    brandsException?: string[];
    profileTypeException?: string[];
    requiresLogin?: boolean;
    toggle?: string;
  };
}

interface LayoutRoutes {
  key: string;
  props?: LayoutProps;
  routes: Array<RouteProps>;
}

export const layoutRoutes: Array<LayoutRoutes> = [
  {
    key: "mainLayout",
    props: {
      isHeaderVisible: true,
      isLHNVisible: true,
      showTVG4Content: false,
      hasMobileView: isFdrProduct()
    },
    // Routes that are already responsive
    routes: [
      {
        path: "/racetracks/:trackAbbr/:trackName",
        key: "programPage",
        name: "ProgramPage",
        namespaces: ["Homepage", "SeoData", "ProgramPage", "BetTicket", "Race"]
      },
      {
        path: "/preferences",
        key: "preferences",
        name: "Preferences",
        namespaces: [],
        props: {
          requiresLogin: true
        }
      },
      {
        path: "/live",
        key: "live",
        name: "Live",
        namespaces: [],
        props: {
          toggle: "enableURPWatchFDTV"
        }
      },
      {
        path: "/live/:channel/:trackAbbr/:trackName",
        key: "livePage",
        name: "Live",
        namespaces: ["SeoData", "ProgramPage", "BetTicket", "Race", "Global"],
        props: {
          toggle: "enableURPWatchFDTV"
        }
      },
      {
        path: "/horse-racing-101",
        key: "educationHub",
        name: "Horse Racing 101",
        namespaces: ["LandingPages"],
        props: {
          toggle: "enableURPEducationHub"
        }
      },
      {
        path: "/horse-racing-101/:slug",
        key: "educationHub",
        name: "Horse Racing 101",
        namespaces: ["LandingPages"],
        props: {
          toggle: "enableURPEducationHub"
        }
      }
    ]
  },
  {
    key: "mainLayout",
    props: {
      isHeaderVisible: true,
      isLHNVisible: true,
      showTVG4Content: false,
      hasMobileView: false
    },
    // Routes that don't need tvg4
    routes: [
      {
        path: "/",
        key: "home",
        name: "Home",
        namespaces: ["Homepage"]
      },
      {
        path: "/handicapping/talent-picks",
        key: "handicapping",
        name: "Handicapping",
        namespaces: []
      },
      {
        path: "/promos",
        key: "promos",
        name: "Promos",
        namespaces: ["Promos", "Homepage"]
      },
      {
        path: "/promos/:slug",
        key: "promos",
        name: "Promos",
        namespaces: ["Promos", "Homepage"]
      },
      {
        path: "/promos/:slug/:id",
        key: "promos",
        name: "Promos",
        namespaces: ["Promos", "Homepage"]
      },
      {
        path: "/promos/:slug/:category/:id",
        key: "promos",
        name: "Promos",
        namespaces: ["Promos", "Homepage"]
      },
      {
        path: "/wager-rewards",
        key: "wagerRewards",
        name: "WagerRewards",
        props: {
          brandsException: ["pabets", "fdr"],
          profileTypeException: ["PORT-PA"]
        },
        namespaces: ["WagerRewards"]
      },
      {
        path: "/referral",
        key: "referral",
        name: "Referral",
        props: {
          brandsException: ["fdr"]
        },
        namespaces: []
      },

      {
        path: "/my-bets",
        key: "myBets",
        name: "MyBets",
        namespaces: [],
        props: {
          requiresLogin: true
        }
      },
      {
        path: "/info/privacypolicy/state-specific-privacy-rights",
        key: "specificPrivacyRights",
        name: "specificPrivacyRights",
        namespaces: ["messages"],
        props: {
          brandsException: ["fdr"]
        }
      },
      {
        path: "/info/privacypolicy",
        key: "privacyPolicy",
        name: "privacyPolicy",
        namespaces: ["messages"],
        props: {
          brandsException: ["fdr"]
        }
      },
      {
        path: "/info/accessibility",
        key: "accessibility",
        name: "accessibility",
        namespaces: ["InformationalPages"]
      }
    ]
  },
  {
    key: "mainLayout",
    props: {
      isHeaderVisible: true,
      isLHNVisible: true,
      showTVG4Content: false,
      hasMobileView: true
    },
    routes: [
      {
        path: "/tracks",
        key: "tracks",
        name: "Tracks",
        namespaces: []
      },
      {
        path: "/tracks/results",
        key: "tracks",
        name: "Tracks Results",
        namespaces: [],
        props: {
          toggle: "useURPRacingResults"
        }
      },
      {
        path: "/tracks/all-info",
        key: "tracks",
        name: "Tracks",
        namespaces: []
      },
      {
        path: "/tracks/all-info/*",
        key: "tracksDetails",
        name: "Tracks Details",
        namespaces: ["SeoData"]
      },
      {
        path: "/racetracks",
        key: "racetracks",
        name: "Racetracks",
        namespaces: []
      }
    ]
  },
  {
    key: "mainLayout",
    props: {
      isHeaderVisible: true,
      isLHNVisible: true,
      showTVG4Content: true,
      hasMobileView: false
    },
    // TVG4 routes
    routes: [
      {
        path: "/live",
        key: "live",
        name: "Live",
        namespaces: [],
        props: {
          toggle: "enableOldWatchFDTV"
        }
      },
      {
        path: "/races",
        key: "races",
        name: "Races",
        namespaces: []
      },
      {
        path: "/handicapping/handicapping-store",
        key: "empty",
        name: "Handicapping",
        namespaces: [],
        props: {
          brandsException: ["fdr"],
          requiresLogin: true
        }
      },
      {
        path: "/handicapping/free-pp",
        key: "empty",
        name: "Handicapping",
        props: {
          brandsException: ["fdr"]
        },
        namespaces: []
      },
      {
        path: "/handicapping/race-replays",
        key: "empty",
        name: "Handicapping",
        namespaces: []
      },
      {
        path: "/my-funds",
        key: "myFunds",
        name: "Funds",
        namespaces: ["Funds", "Deposits"]
      },
      {
        path: "/my-funds/withdraw",
        key: "myFundsWithdraw",
        name: "FundsWithdraw",
        namespaces: ["Funds", "Deposits"]
      },
      {
        path: "/my-funds/deposit",
        key: "myFundsDeposit",
        name: "FundsDeposit",
        namespaces: []
      },
      {
        path: "/my-account-summary",
        key: "accountSummary",
        name: "accountSummary",
        namespaces: [],
        props: {
          requiresLogin: true,
          brandsException: ["fdr"]
        }
      },
      {
        path: "/my-profile",
        key: "myProfile",
        name: "myProfile",
        namespaces: [],
        props: {
          requiresLogin: true,
          brandsException: ["fdr"]
        }
      },
      {
        path: "/my-favorite-tracks",
        key: "myFavoriteTrack",
        name: "myFavoriteTrack",
        namespaces: [],
        props: {
          requiresLogin: true,
          brandsException: ["fdr"]
        }
      },
      {
        path: "/my-stable",
        key: "myStable",
        name: "myStable",
        namespaces: [],
        props: {
          requiresLogin: true,
          brandsException: ["fdr"]
        }
      },
      {
        path: "/w2-g-reports",
        key: "w2gReports",
        name: "w2gReports",
        namespaces: [],
        props: {
          requiresLogin: true,
          brandsException: ["fdr"]
        }
      },
      {
        path: "/year-end-reports",
        key: "yearEndReports",
        name: "yearEndReports",
        namespaces: [],
        props: {
          requiresLogin: true,
          brandsException: ["fdr"]
        }
      },
      {
        path: "/info/termsandconditions",
        key: "termsAndConditions",
        name: "termsAndConditions",
        namespaces: []
      },
      {
        path: "/info/wagerresponsibly",
        key: "wagerResponsibly",
        name: "wagerResponsibly",
        namespaces: [],
        props: {
          brandsException: ["fdr"]
        }
      },
      {
        path: "/racetracks",
        key: "racetracks",
        name: "Racetracks",
        namespaces: ["Homepage", "SeoData", "ProgramPage"]
      },
      {
        path: "/races",
        key: "races",
        name: "Races",
        namespaces: []
      }
    ]
  },
  {
    key: "mainLayout",
    props: {
      isHeaderVisible: true,
      isLHNVisible: false,
      showTVG4Content: false,
      hasMobileView: false
    },
    // Routes that don't need LHN
    routes: [
      {
        path: "/wallet",
        key: "wallet",
        name: "Wallet",
        namespaces: ["Funds", "Deposits"],
        props: {
          requiresLogin: true,
          brandsException: ["fdr"]
        }
      },
      {
        path: "/responsible-gaming",
        key: "responsibleGaming",
        name: "ResponsibleGaming",
        namespaces: ["ResponsibleGaming"],
        props: {
          brandsException: ["fdr"]
        }
      }
    ]
  },
  {
    key: "mainLayout",
    props: {
      isHeaderVisible: false,
      isLHNVisible: false,
      showTVG4Content: false,
      hasMobileView: false
    },
    // Routes that have a full page layout
    routes: [
      {
        path: "/registration",
        key: "registration",
        name: "Registration",
        namespaces: ["Registration"],
        props: {
          brandsException: ["fdr"]
        }
      },
      {
        path: "/credentials-reset",
        key: "credentialsReset",
        name: "CredentialsReset",
        namespaces: ["passwordRecovery"]
      },
      {
        path: "/unsubscribe",
        key: "unsubscribe",
        name: "Unsubscribe",
        namespaces: ["Homepage"]
      }
    ]
  },
  {
    key: "wagerpadLayout",
    routes: [
      {
        path: "/wagerpad/:trackAbbr/:raceNumber",
        key: "wagerPad",
        name: "wagerPad",
        namespaces: ["ProgramPage", "SeoData", "BetTicket", "Global"]
      }
    ]
  },
  {
    key: "racesLayout",
    props: {
      isHeaderVisible: true,
      isLHNVisible: true,
      showTVG4Content: true,
      hasMobileView: false
    },
    routes: [
      {
        path: "/races",
        key: "races",
        name: "Races",
        namespaces: []
      },
      {
        path: "/racetracks",
        key: "racetracks",
        name: "Racetracks",
        namespaces: ["Homepage", "SeoData", "ProgramPage"]
      }
    ]
  }
];

export const PageRoutes = () =>
  layoutRoutes.map((layout: LayoutRoutes) => ({
    ...layout,
    component: layoutMap[layout.key],
    routes: layout.routes.map((route: RouteProps) => ({
      ...route,
      component: componentMap[route.key]
    }))
  }));

export default PageRoutes;
