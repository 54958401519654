import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@tvg/design-system/web";
import { NavContainer, NavDots, PageDot } from "./styled-components";

interface NavigationButtonsProps {
  page: number;
  totalPages: number;
  doneProps: {
    label: string;
    link: string;
  } | null;
  onChangePage: (page: number) => void;
  onCloseModal: () => void;
}

const NavigationButtons = ({
  page,
  totalPages,
  doneProps,
  onChangePage,
  onCloseModal
}: NavigationButtonsProps) => {
  const navigate = useNavigate();

  const handleDoneAction = useCallback(() => {
    if (doneProps?.link) {
      const { link } = doneProps;
      const isExternal = link.startsWith("https://");
      if (isExternal && typeof window !== "undefined") {
        if (link.includes(window.location.hostname)) {
          const { pathname, search, hash: hashUrl } = new URL(link);
          navigate({ pathname, search, hash: hashUrl });
        } else {
          window.open(link, "_blank", "noopener, noreferrer");
        }
      } else {
        navigate(link);
      }
    } else {
      onCloseModal();
    }
  }, [doneProps, onCloseModal]);

  return (
    <NavContainer>
      <Button
        onClick={() => onChangePage(page - 1)}
        variant="secondary"
        isDisabled={page === 1}
        qaLabel="btn-back"
      >
        Back
      </Button>
      <NavDots data-qa-label="nav-dots">
        {Array.from({ length: totalPages }, (_, index) => (
          <PageDot
            key={index.toString()}
            onClick={() => onChangePage(index + 1)}
            isActive={index === page - 1}
          />
        ))}
      </NavDots>
      {page === totalPages ? (
        <Button onClick={handleDoneAction} variant="primary" qaLabel="btn-done">
          {doneProps?.label ?? "Done"}
        </Button>
      ) : (
        <Button
          onClick={() => onChangePage(page + 1)}
          variant="primary"
          qaLabel="btn-next"
        >
          Next
        </Button>
      )}
    </NavContainer>
  );
};

export default NavigationButtons;
