import { SetStoreSlugAction, SetSelectedSlugAction } from "./actions";
import { StoryblokPageContent } from "../types";

export interface State {
  storedSlugsData: Record<string, Array<StoryblokPageContent>>;
}

export const initialState = {
  selectedSlug: "",
  storedSlugsData: {}
};

type Actions = SetStoreSlugAction | SetSelectedSlugAction;

export default function educationalHubReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = initialState,
  action: Actions
) {
  switch (action.type) {
    case "SET_STORED_CONTENT_SLUGS": {
      const stateSlugsCopy = { ...state.storedSlugsData };
      Object.assign(stateSlugsCopy, {
        [action.payload.key]: action.payload.data
      });

      return {
        ...state,
        storedSlugsData: stateSlugsCopy
      };
    }

    case "SET_SELECTED_SLUG": {
      return {
        ...state,
        selectedSlug: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
