import React, { FC, memo } from "react";
import { Container } from "../../styled-components";
import type { MtpCellProps } from "../../types";
import { Paragraph } from "../../../Typography";

export const Default: FC<
  Pick<MtpCellProps, "withBackground" | "align" | "qaLabel">
> = memo(({ withBackground, align, qaLabel = "mtp-cell", ...rest }) => (
  <Container
    withBackground={withBackground}
    align={align}
    bgColor="--fd-colors-background-layer"
    lineColor="--fd-colors-border-default"
    data-qa-label={qaLabel}
    {...rest}
  >
    <Paragraph
      fontFamily="bold"
      textAlign="center"
      qaLabel={`${qaLabel}-available-bet-types`}
      fontSize="16px"
      lineHeight="20px"
      color="--fd-colors-content-default"
      margin={0}
      width="72px"
    >
      Available bet types
    </Paragraph>
  </Container>
));
