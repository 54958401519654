import React, { useEffect, Fragment } from "react";
import { AlertBanner } from "@tvg/design-system/web";
import useBannerProps from "./useBannerProps";

interface ComplianceBannerProps {
  Wrapper?: React.ComponentType;
  onBannerVisibility?: (isVisible: boolean) => void;
}

const ComplianceBanner = ({
  Wrapper,
  onBannerVisibility
}: ComplianceBannerProps) => {
  const bannerProps = useBannerProps();

  const WrapperComponent = Wrapper ?? Fragment;

  useEffect(() => {
    if (typeof onBannerVisibility === "function") {
      onBannerVisibility(!!bannerProps);
    }
  }, [bannerProps]);

  if (!bannerProps) return null;

  return (
    <WrapperComponent>
      <AlertBanner
        qaLabel="alertBanner"
        icon={{
          name: "exclamation",
          size: "s",
          lineColor: "content.onDark"
        }}
        title={bannerProps.text}
        link={{
          onClick: bannerProps.link.onClick,
          text: bannerProps.link.text ?? ""
        }}
      />
    </WrapperComponent>
  );
};

export default ComplianceBanner;
