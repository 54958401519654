import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { breakpoints, Modal, useMediaQuery } from "@tvg/design-system";
import { Paragraph } from "@tvg/design-system/web";
import useContentData from "../../hooks/useContentData";
import { setSelectedSlug } from "../../redux/actions";
import { StoryblokWalkthroughContent } from "../../types";
import { PageWrapper, Image } from "./styled-components";
import NavigationButtons from "./buttons";
import Loading from "./loading";

const WalkthroughModal = () => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hash, key: locationKey } = useLocation();

  const [slug, setSlug] = useState("");
  const [page, setPage] = useState(1);

  const { contentData, isLoading } =
    useContentData<StoryblokWalkthroughContent>(slug);

  useEffect(() => {
    if (hash.startsWith("#walkthroughs")) {
      setSlug(hash.replace("#", "").replace("-", "/"));
    } else {
      setSlug("");
    }
  }, [hash]);

  const onCloseModal = useCallback(() => {
    if (locationKey !== "default") {
      navigate(-1);
    } else {
      navigate({ hash: "" });
    }
    dispatch(setSelectedSlug("homepage"));
  }, [dispatch, locationKey]);

  return (
    <Modal
      isOpen={!!slug}
      title={contentData?.title ?? "Loading..."}
      padding={isLoading ? 0 : "space-4"}
      type={isDesktop ? "lightbox" : "fullscreen"}
      onClose={onCloseModal}
      onAfterClose={() => setPage(1)}
      hideBack
    >
      {isLoading || !contentData ? (
        <Loading />
      ) : (
        <PageWrapper data-qa-label="page-wrapper">
          <Modal.PageGroup page={page}>
            {contentData.steps.map(({ _uid, title, description, image }) => (
              <Modal.Page key={_uid}>
                <Image data-qa-label="image" src={image.filename} />
                <Paragraph
                  qaLabel="title"
                  fontFamily="bold"
                  fontSize="l"
                  textAlign="center"
                  color="--fd-colors-content-strong"
                  mt="space-4"
                  py="space-4"
                >
                  {title}
                </Paragraph>
                <Paragraph
                  qaLabel="description"
                  fontSize="m"
                  textAlign="center"
                  color="--fd-colors-content-default"
                >
                  {description}
                </Paragraph>
              </Modal.Page>
            ))}
          </Modal.PageGroup>
          <NavigationButtons
            page={page}
            totalPages={contentData.steps.length}
            doneProps={{
              label: contentData.done_button_title,
              link: contentData.done_button_link.cached_url
            }}
            onChangePage={setPage}
            onCloseModal={onCloseModal}
          />
        </PageWrapper>
      )}
    </Modal>
  );
};

export default WalkthroughModal;
