import React, { useCallback } from "react";
import { Accordion, AccordionItem, Button } from "@tvg/design-system/web";
import StoryblokClient from "storyblok-js-client";
import { useNavigate } from "react-router-dom";
import { Container, TextContainer } from "./styled-components";
import Section from "../Section";
import RichText from "../RichText";
import { EducationHubSectionType, ContentFaqItem } from "../../types";
import { formatFaqList } from "../../utils/storyblok";
import { HORSE_RACING_101_URL } from "../../constants";

interface ArticlesProps {
  data?: EducationHubSectionType;
  storyblokClient?: StoryblokClient;
  defaultOpenIndices?: Array<number>;
}

const RacingFAQ = ({
  data,
  storyblokClient,
  defaultOpenIndices = []
}: ArticlesProps) => {
  const navigate = useNavigate();

  const onClickItem = useCallback(({ slug }) => {
    if (slug) {
      const cleanSlug = slug.replace("educational-hub/modals/", "");
      navigate(`${HORSE_RACING_101_URL}/${cleanSlug}`);
    }
  }, []);

  const qaLabel = data?.title.replace(/ /g, "-").toLowerCase();
  const faqList = formatFaqList(
    data?.content as ContentFaqItem[],
    storyblokClient
  );

  return (
    <Container>
      <Section
        qaLabel={`section-${qaLabel}`}
        title={data?.title ?? ""}
        id={data?.tab_name.toLowerCase() || ""}
      >
        <Accordion
          qaLabel={`${qaLabel}-accordion`}
          defaultOpenIndices={defaultOpenIndices}
        >
          {faqList?.map((item, i, { length }) => (
            <AccordionItem
              key={i.toString()}
              qaLabel={`accordion-item-${item.id}`}
              title={item.title}
              hasBorder={i + 1 !== length}
            >
              <Container>
                <TextContainer>
                  <RichText
                    text={item.description}
                    qaLabel={`faqItem-${item.id}-content`}
                  />
                </TextContainer>
                {item.link && item.linkLabel && (
                  <Button
                    mt="space-4"
                    variant="tertiary"
                    qaLabel={`faqItem-${item.id}-button`}
                    onClick={() =>
                      onClickItem({ slug: item?.link?.cached_url ?? "" })
                    }
                    iconPosition="end"
                    icon="arrowRight"
                  >
                    {item.linkLabel}
                  </Button>
                )}
              </Container>
            </AccordionItem>
          ))}
        </Accordion>
      </Section>
    </Container>
  );
};

export default RacingFAQ;
