import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Link)`
  appearance: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  gap: 3px;
  flex: 1;
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: 3px;
  margin-left: 15px;
  z-index: 2;
  pointer-events: none;
`;
