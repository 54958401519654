import { BinaryFn } from "@tvg/ts-types/Functional";
import { BannerContext } from "@urp/derby-banner/src/types";

export enum BannersConfigType {
  DEFAULT = "default",
  EVENT_DAY = "eventDay",
  PRE_EVENT = "preEvent",
  FRIDAY_PRE_EVENT = "fridayPreEvent",
  THURSDAY_PRE_EVENT = "thursdayPreEvent"
}

export enum BannersConfigContext {
  DESKTOP = "desk",
  MOBILE = "mobile",
  X_SELL = "xSell",
  HOME_PAGE = "homePage",
  PROGRAM_PAGE = "programPage"
}
export enum DerbyEventStatus {
  PRE = "preEvent",
  DERBY_DAY = "eventDay",
  POST = "default"
}

export interface TextMarks {
  type: "textStyle";
  attrs: {
    color: string;
  };
}

export interface ContentText {
  type: "text";
  text: string;
  marks?: Array<TextMarks>;
}

export interface ContentParagraph {
  type: "paragraph";
  content?: Array<ContentText>;
}

export interface ContentHeading {
  type: "heading";
  attrs: {
    level: number;
  };
  content: Array<ContentText>;
}

export type RichText = ContentParagraph | ContentHeading;
export interface CtaButton {
  button_type: string;
  label: string;
  link_url: {
    url: string;
  };
}
export interface BannerProps {
  raceStartDate?: Date | null;
  eventStatus: DerbyEventStatus;
  bannerCfg: HeaderBanner;
  orientation?: "portrait" | "landscape";
  onCtaClick: BinaryFn<string, string, void>;
  qaLabel?: string;
}

export interface HeaderBanner {
  context: Array<BannersConfigContext | BannerContext>;
  background_image: {
    filename: string;
    alt: string;
  };
  cta_buttons_bg_color?: string;
  cta_buttons: Array<CtaButton>;
  dynamic_text_bg_color?: string;
  dynamic_text?: {
    type: "doc";
    content: Array<RichText>;
  };
  background_image_link?: {
    url?: string;
  };
}

export type HeaderBannerConfig = Record<BannersConfigType, Array<HeaderBanner>>;

export interface BannersStoryblokContent {
  content: {
    event_day_homepage_banner?: Array<HeaderBanner>;
    pre_event_homepage_banner?: Array<HeaderBanner>;
    friday_pre_event_homepage_banner?: Array<HeaderBanner>;
    thursday_pre_event_homepage_banner?: Array<HeaderBanner>;
    event_date: string;
    event_name: string;
    track_abbr: string;
  };
}
export interface BannersConfigEventInfo {
  label: string;
  size?: number;
}

export interface BannerConfigEventInfo {
  eventDate: Date | null;
  eventName: BannersConfigEventInfo;
  raceNumber: number;
  trackAbbr: string;
}

export interface BannerConfig {
  eventInfo?: BannerConfigEventInfo;
  derbyBanner?: HeaderBannerConfig;
}
