import styled from "styled-components";
import { fontProxima } from "@tvg/atomic-ui/_static/Typography";

export const SessionInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--fd-colors-content-on-dark);
  font-size: var(--fd-font-sizes-font-size-020);
  font-family: ${fontProxima};
  font-weight: normal;
`;

export const SessionDescription = styled.span`
  font-weight: bold;
`;
