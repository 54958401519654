import React, { useContext } from "react";
import { SortContext } from "../../context";
import { Container, ContentContainer, DropdownDiv } from "./styled-components";
import type { AnimatedDropdownProps } from "./types";

export const Dropdown: React.FC<AnimatedDropdownProps> = ({
  children,
  qaLabel
}) => {
  const { isDropdownOpen, layer } = useContext(SortContext);

  return isDropdownOpen ? (
    <Container data-qa-label={qaLabel}>
      <ContentContainer layer={layer}>
        <DropdownDiv>{children}</DropdownDiv>
      </ContentContainer>
    </Container>
  ) : null;
};
