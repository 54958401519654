import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isUserInAWFlows } from "@tvg/sh-lib-account-actions/src/selectors/user-ts";
import { getBalance, getIsLogged } from "@urp/store-selectors";
import mediator from "@tvg/mediator";
import { Button, ButtonVariant } from "@tvg/design-system/web";
import {
  AWPath,
  openAWPage
} from "@tvg/sh-lib-account-wallet/src/utils/AWPages";
import { getBalanceIsShown } from "@tvg/sh-lib-preferences/redux/selectors";
import { ToggleStateEnum } from "@tvg/sh-lib-preferences/utils/types";
import { toggleQuickDepositModal } from "@tvg/sh-lib-paws/redux/slices/quickDepositModalSlice";
import { computeBalanceDisplay } from "@tvg/utils/balance";
import { isFDR } from "@tvg/utils/generalUtils";
import { QuickDepositWrapper } from "../../styled-components";

export const computeButtonVariant = (balance: number | null): ButtonVariant => {
  if ((!balance && balance !== 0) || balance > 10) {
    return "secondaryDark";
  }
  return "betting";
};

export const QuickDepositButton = () => {
  const dispatch = useDispatch();
  const userBalance = useSelector(getBalance);
  const isUserBalanceShown = useSelector(getBalanceIsShown);
  const isAWFlowEnabled = useSelector(isUserInAWFlows);
  const isLogged = useSelector(getIsLogged);

  const openQuickDeposit = useCallback(() => {
    if (isAWFlowEnabled) {
      openAWPage(AWPath.Deposit, { forceWeb: true });
    } else {
      mediator.base.dispatch({
        type: "NAVIGATION:MENU_CLICK",
        payload: {
          action: "Quick Deposit Opened",
          module: "Global Header"
        }
      });
      dispatch(toggleQuickDepositModal(true));
    }
  }, []);

  return useMemo(() => {
    if (!isLogged) return <></>;

    const displayText = computeBalanceDisplay({
      balance: userBalance,
      isBalanceHidden: isUserBalanceShown === ToggleStateEnum.OFF,
      hiddenValueDisplay: "Deposit"
    });
    const buttonVariant = computeButtonVariant(userBalance);

    const ButtonWrapper = isFDR() ? QuickDepositWrapper : React.Fragment;

    return (
      <ButtonWrapper>
        <Button
          variant={buttonVariant}
          qaLabel="quick-deposit-button"
          onClick={openQuickDeposit}
          icon="deposit"
          mx={isFDR() ? "" : "space-5"}
          isStretched={isFDR()}
        >
          {displayText.replace("$", "")}
        </Button>
      </ButtonWrapper>
    );
  }, [isLogged, userBalance, isUserBalanceShown]);
};

export default QuickDepositButton;
