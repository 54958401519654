import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import seo from "@tvg/api/seo";
import { HORSE_RACING_101_URL } from "@urp/education-hub/src/constants";
import { setPageTitle } from "../store/actions/page";
import { getSeoDefaultMessages } from "../store/selectors/general";

const IGNORE_PATHS = [HORSE_RACING_101_URL];

const useSeoData = (callback: () => void) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { tabTitle, tabDescription } = useSelector(getSeoDefaultMessages);

  const [seoTitle, setSeoTitle] = useState(tabTitle);
  const [seoDescription, setSeoDescription] = useState(tabDescription);

  const fetchSeoData = useCallback(
    async (pathname: string) => {
      try {
        const { data } = await seo.getSeoData(pathname);

        const { title, description } = data;

        if (title) {
          setSeoTitle(title);
        }
        if (description) {
          setSeoDescription(description);
        }
      } catch {
        setSeoTitle(tabTitle);
        setSeoDescription(tabDescription);
      } finally {
        if (typeof callback === "function") {
          setTimeout(callback);
        }
      }
    },
    [callback, tabTitle, tabDescription]
  );

  useEffect(() => {
    dispatch(setPageTitle(seoTitle));
  }, [seoTitle]);

  useEffect(() => {
    const { pathname } = location;

    if (!IGNORE_PATHS.some((ignored) => pathname.startsWith(ignored))) {
      fetchSeoData(location.pathname);
    } else {
      setSeoTitle(tabTitle);
      setSeoDescription(tabDescription);

      if (typeof callback === "function") {
        setTimeout(callback);
      }
    }
  }, [location.pathname]);

  return { seoTitle, seoDescription };
};

export default useSeoData;
