import { WatchQueryFetchPolicy } from "@apollo/client";
import { BannersConfigType } from "../types";

interface IQueryVariables {
  trackAbbr?: string;
  eventStatus?: BannersConfigType;
  raceNumber?: number;
}

const POLL_INTERVAL = 1 * 60 * 1000;

const buildQueryVarsByParentPage = (props: IQueryVariables) => {
  const queryVariables: IQueryVariables = {
    trackAbbr: props.trackAbbr,
    raceNumber: props.raceNumber
  };

  return queryVariables;
};

const apolloOptions = (props: IQueryVariables) => ({
  pollInterval: POLL_INTERVAL,
  fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
  ssr: false,
  variables: buildQueryVarsByParentPage(props),
  skip:
    !props.trackAbbr ||
    !props.raceNumber ||
    props.eventStatus !== BannersConfigType.EVENT_DAY
});

export default apolloOptions;
