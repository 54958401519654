import React, { useEffect, useState, useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { debounce } from "lodash";
import {
  Container,
  EmblaContainer,
  EmblaSlider,
  EmblaViewport,
  Tab
} from "./styled-components";
import { AnchorTabsProps } from "./types";

export const AnchorTabs: React.FC<AnchorTabsProps> = ({
  qaLabel = "anchor-tabs",
  sections,
  isSticky = true,
  top = 0,
  offsetMargin = 48,
  isStretched = true
}) => {
  const [emblaTabsRef, emblaTabsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true
  });

  const [activeTab, setActiveTab] = useState<string | null>(
    sections[0]?.id || null
  );

  const scrollLockRef = useRef(false);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (scrollLockRef.current) return;

      const headerOffset = offsetMargin;
      const viewportHeight = window.innerHeight;
      const currentSection = sections.reduce<{
        id: string | null;
        distance: number;
      }>(
        (closest, section) => {
          const target = document.getElementById(section.id);
          if (target) {
            const rect = target.getBoundingClientRect();
            const distance = Math.abs(rect.top - headerOffset);
            const isVisible =
              rect.top - headerOffset <= 0 && rect.bottom - headerOffset > 0;

            // Prioritize visible sections or closest match
            if (isVisible || (rect.top < viewportHeight && rect.bottom > 0)) {
              if (closest.id === null || distance < closest.distance) {
                return { id: section.id, distance };
              }
            }
          }
          return closest;
        },
        { id: null, distance: Infinity }
      );

      if (currentSection.id && currentSection.id !== activeTab) {
        setActiveTab(currentSection.id);

        if (emblaTabsApi) {
          const sectionIndex = sections.findIndex(
            (section) => section.id === currentSection.id
          );
          if (sectionIndex !== -1) {
            emblaTabsApi.scrollTo(sectionIndex);
          }
        }
      }
    }, 50);

    window.addEventListener("scroll", handleScroll);

    return () => {
      handleScroll.cancel();
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections, activeTab, offsetMargin, emblaTabsApi]);

  const handleTabClick = (newTab: string, position: number) => {
    const targetSection = document.getElementById(newTab);

    if (targetSection) {
      scrollLockRef.current = true;

      const sectionTop =
        targetSection.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: sectionTop - offsetMargin,
        behavior: "smooth"
      });

      setTimeout(() => {
        scrollLockRef.current = false;
      }, 800);
    }

    if (activeTab !== newTab) {
      setActiveTab(newTab);
    }

    if (emblaTabsApi) {
      emblaTabsApi.scrollTo(position);
    }
  };

  return (
    <Container data-qa-label={qaLabel} isSticky={isSticky} top={top}>
      <EmblaSlider isStretched={isStretched}>
        <EmblaViewport ref={emblaTabsRef}>
          <EmblaContainer>
            {sections.map((section, index) => (
              <Tab
                data-qa-label={`${section.id}-tab-${activeTab === section.id ? "active" : "inactive"}`}
                key={section.id}
                isSelected={activeTab === section.id}
                onClick={() => handleTabClick(section.id, index)}
              >
                {section.label}
              </Tab>
            ))}
          </EmblaContainer>
        </EmblaViewport>
      </EmblaSlider>
    </Container>
  );
};

export type { AnchorTabsProps };
