import React, { useCallback } from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import mediator from "@tvg/mediator";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import { Counter, Button } from "@tvg/design-system/web";
import TVGConf from "@tvg/conf";
import { isFDR } from "@tvg/utils/generalUtils";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import { MyBetsButtonContainer, BadgeContainer } from "./styled-components";

export interface MyBetsProps {
  activeBets: number | undefined;
}
const MyBetsButton: React.FC<MyBetsProps> = ({ activeBets }) => {
  const navigate = useNavigate();
  const isDesktopMinSm = useMediaQuery(breakpoints.desktop.min.sm);
  const isTablet = TVGConf().device === "tablet";
  const isAccountCompliant = useSelector(isAccountCompliantSelector);

  const callMyBets = useCallback(() => {
    if (isFDR() && !isAccountCompliant) {
      onOpenComplianceModal();
    } else {
      navigate("/my-bets");
      mediator.base.dispatch({
        type: "NAVIGATION:MENU_CLICK",
        payload: {
          action: "My Bets Opened",
          module: "Global Header"
        }
      });
    }
  }, []);

  return (
    <MyBetsButtonContainer>
      {activeBets! > 0 && (
        <BadgeContainer>
          <Counter
            value={activeBets!}
            qaLabel="mybets-badge"
            variant="secondary"
          />
        </BadgeContainer>
      )}
      <Button
        variant="secondaryDark"
        iconPosition="start"
        icon="bets"
        onClick={callMyBets}
        qaLabel="mybets-button"
      >
        {isDesktopMinSm || isTablet ? "Bets" : <></>}
      </Button>
    </MyBetsButtonContainer>
  );
};

export default MyBetsButton;
