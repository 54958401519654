import React, { useCallback, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import {
  IconButton,
  secondaryDarkOverride,
  Scroll
} from "@tvg/design-system/web";
import { useSpring, animated } from "@react-spring/web";
import { getIsHamburgerMenuOpen } from "@tvg/hdr/src/store/selectors";
import { setIsHamburgerMenuOpen as setIsHamburgerMenuOpenAction } from "@tvg/hdr/src/store/actions";
import { setBetSlipIsExpanded } from "@tvg/pp/src/store/actions";
import useBannerProps from "@urp/compliance-banner/src/useBannerProps";
import Races from "./components/Races";
import { LHNProps } from "./types";
import { Nav, GlobalStyle, ArrowButtonContainer } from "./styled-components";

const AnimatedWrapper = animated(Scroll);
const AnimatedArrow = animated(ArrowButtonContainer);

export const LHN = ({
  isInsideHamburgerMenu = true,
  changeCollapsedMediaQuery = true,
  isHeaderVisible = true,
  isPositionFixed = true,
  showCollapseButton = false,
  onClickRaceEntry,
  setIsLargeContent
}: LHNProps) => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const wasHamburgerMenuOpen = useSelector(getIsHamburgerMenuOpen);
  const isDesktopMinXl = useMediaQuery(breakpoints.desktop.min.xl);
  const isDesktopMinXxl = useMediaQuery(breakpoints.desktop.min.xxl);
  const isIntermediumMediaQuery = isDesktopMinXl && !isDesktopMinXxl;
  const lhnRef = useRef<HTMLDivElement>(null);

  const wrapperTransition = useSpring({
    config: { duration: 200 },
    width: isCollapsed ? "64px" : "292px",
    marginLeft: "6px",
    paddingRight: isCollapsed ? "0px" : "8px"
  });

  const arrowTransition = useSpring({
    config: { duration: 200 },
    rotateY: isCollapsed ? "180deg" : "0"
  });

  const onCollapse = useCallback(() => {
    setIsCollapsed((prevState) => {
      if (!wasHamburgerMenuOpen) dispatch(setBetSlipIsExpanded(!prevState));
      return !prevState;
    });
  }, []);

  const handleHamburgerMenu = useCallback(() => {
    dispatch(setIsHamburgerMenuOpenAction(!wasHamburgerMenuOpen));
  }, [wasHamburgerMenuOpen]);

  useEffect(() => {
    setIsCollapsed(isInsideHamburgerMenu ? false : changeCollapsedMediaQuery);
  }, [changeCollapsedMediaQuery]);

  useEffect(() => {
    dispatch(setBetSlipIsExpanded(isCollapsed || wasHamburgerMenuOpen));
    setIsLargeContent?.(isCollapsed);
  }, [isCollapsed]);

  const bannerPropsValues = useBannerProps();

  return (
    <>
      <GlobalStyle />
      <Nav
        mediaQuery={breakpoints.desktop.max.xl}
        isInsideHamburguerMenu={isInsideHamburgerMenu}
        isHeaderVisible={isHeaderVisible}
        isPositionFixed={isPositionFixed}
        data-qa-label="lhn-scroll-container"
        hasFixedBanner={!!bannerPropsValues}
        ref={lhnRef}
      >
        <AnimatedWrapper
          height="100%"
          isDark
          overflowY="auto"
          overflowX="hidden"
          style={wrapperTransition}
        >
          <Races
            isNavCollapsed={isCollapsed}
            onClickRaceEntry={onClickRaceEntry}
            isInsideHamburgerMenu={showCollapseButton}
          />
        </AnimatedWrapper>
        {showCollapseButton && !isInsideHamburgerMenu && (
          <AnimatedArrow isCollapsed={isCollapsed} style={arrowTransition}>
            <IconButton
              overrideColors={secondaryDarkOverride}
              iconName="arrowCollapseLeft"
              qaLabel="collapse-button"
              onClick={
                isIntermediumMediaQuery ? handleHamburgerMenu : onCollapse
              }
            />
          </AnimatedArrow>
        )}
      </Nav>
      {showCollapseButton && isInsideHamburgerMenu && (
        <AnimatedArrow
          isHaburguerMenu={isInsideHamburgerMenu}
          isCollapsed={isCollapsed}
          style={arrowTransition}
        >
          <IconButton
            overrideColors={secondaryDarkOverride}
            iconName="arrowCollapseLeft"
            qaLabel="collapse-button"
            onClick={isIntermediumMediaQuery ? handleHamburgerMenu : onCollapse}
          />
        </AnimatedArrow>
      )}
    </>
  );
};

export default LHN;
