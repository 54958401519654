import React, { useMemo } from "react";
import { Paragraph, Header } from "@tvg/design-system/web";
import RichText from "../RichText";
import {
  Container,
  HeaderContainer,
  HeaderContent,
  PayoutItems,
  TagContainer,
  TitleContainer
} from "./styled-components";
import { replacePlaceholderValue } from "../../utils/betTypes";
import { BetTypeInfoProps, Difficulty, TagVariant } from "./types";
import PayoutTag from "./PayoutTag";

const tagVariants: Record<Difficulty, TagVariant> = {
  easy: "positive",
  average: "warning",
  hard: "danger",
  very_hard: "danger"
};

export const getTagVariant = (difficulty: string): TagVariant => {
  const normalizedDifficulty = difficulty
    .replace(" ", "_")
    .toLowerCase() as Difficulty;

  const difficultyArray = Object.keys(tagVariants) as Difficulty[];

  if (difficultyArray.includes(normalizedDifficulty)) {
    return tagVariants[normalizedDifficulty as Difficulty];
  }

  return tagVariants.easy;
};

const BetTypeInfo = ({
  title,
  description,
  payout,
  difficulty,
  difficultyLabel,
  storyblokClient
}: BetTypeInfoProps) => {
  const tagVariant = getTagVariant(difficulty);

  const qaLabel = useMemo(
    () => title.replace(/ /g, "-").toLowerCase(),
    [title]
  );

  const renderPayoutTags = useMemo(
    () =>
      Array(5)
        .fill(null)
        .map((_item, index) => (
          <PayoutTag key={index.toString()} isYellow={index + 1 <= payout} />
        )),
    [payout]
  );

  return (
    <Container data-qa-label={`bet-type-${qaLabel}-container`}>
      <HeaderContainer data-qa-label={`bet-type-info-${qaLabel}-container`}>
        <TitleContainer>
          <Header tag="h3" qaLabel={`bet-type-${qaLabel}-title`}>
            {title}
          </Header>
        </TitleContainer>

        <HeaderContent>
          <TagContainer variant={tagVariant} data-qa-label="difficulty-tag">
            <Paragraph
              fontFamily="condensedRegular"
              fontSize="xs"
              color="inherit"
              textTransform="uppercase"
              qaLabel="difficulty-tag-label"
            >
              {difficultyLabel}
            </Paragraph>
          </TagContainer>
          <Paragraph
            fontFamily="condensedRegular"
            color="--fd-colors-content-subtle"
            textTransform="uppercase"
            mt="space-3"
            qaLabel="label-difficulty-level"
          >
            difficulty
          </Paragraph>
        </HeaderContent>

        <HeaderContent>
          <PayoutItems data-qa-label="payout-container">
            {renderPayoutTags}
          </PayoutItems>
          <Paragraph
            fontFamily="condensedRegular"
            color="--fd-colors-content-subtle"
            textTransform="uppercase"
            mt="space-3"
            qaLabel="label-potential-payout"
          >
            potential payout
          </Paragraph>
        </HeaderContent>
      </HeaderContainer>

      <RichText
        text={
          storyblokClient?.richTextResolver.render(
            replacePlaceholderValue(description)
          ) ?? ""
        }
      />
    </Container>
  );
};

export default BetTypeInfo;
