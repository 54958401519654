import React from "react";
import ReactDOM from "react-dom";
import { Container, ContentContainer } from "./styled-components";
import type { AnimatedDropdownProps } from "./types";
import { DropdownButton } from "../DropdownButton";

export const AnimatedDropdown: React.FC<AnimatedDropdownProps> = ({
  isDropdownVisible,
  onSelectValue,
  values,
  selectedValue,
  qaLabel,
  isTop,
  top,
  left,
  width
}) =>
  ReactDOM.createPortal(
    <>
      {values.length > 0 && isDropdownVisible && (
        <Container
          isTop={isTop}
          top={top}
          left={left}
          width={width}
          data-qa-label={qaLabel}
        >
          <ContentContainer>
            {values.map((item, index) => (
              <DropdownButton
                key={index.toString()}
                item={item}
                onSelectValue={onSelectValue}
                selectedValue={selectedValue}
                qaLabel={qaLabel}
              />
            ))}
          </ContentContainer>
        </Container>
      )}
    </>,
    document.body
  );
