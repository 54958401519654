import styled, { css } from "styled-components";
import { InvalidResidentialAddressErrorProps } from "./types";

export const Wrapper = styled.section<
  Pick<InvalidResidentialAddressErrorProps, "isNative">
>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  width: 100%;
  ${({ isNative }) =>
    isNative
      ? css`
          background: var(--fd-colors-background-base);
          height: 100%;
          padding: 12px;
        `
      : css`
          height: auto;
          margin-bottom: 34px;
        `}
`;

export const IconWrapper = styled.div`
  margin: 0 auto;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 4px;
  gap: 12px;
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 22px;
  width: 100%;
`;

export const Title = styled.p`
  font-size: 18px;
  color: var(--fd-colors-content-strong);
  font-weight: 700;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 550;
`;

export const ResidentialAddressErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  ol {
    list-style: decimal;
    list-style-position: outside;
    font-size: 14px;
    font-weight: 400;
    margin-left: 16px;
  }

  li {
    padding-left: 7px;
  }

  li::marker {
    color: var(--fd-colors-content-strong);
    font-weight: 700;
    font-size: 16px;
  }
`;

export const StateExpand = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 0 14px;
  width: 100%;

  button {
    color: var(--fd-colors-brand-primary-default);
    font-weight: 550;
    background: transparent;
  }
`;
export const StateList = styled.p`
  font-size: 14px;
  margin-left: 8px;
`;
export const PhysicalLocationErrorMessageContinued = styled.p`
  padding: 0 22px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const ErrorButton = styled.div<
  Pick<InvalidResidentialAddressErrorProps, "isGoHome">
>`
  button {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
  }
  ${({ isGoHome }) =>
    isGoHome
      ? css`
          button {
            background-color: var(--fd-colors-content-on-dark);
            color: var(--fd-colors-brand-primary-default);
            border: 1px solid var(--fd-colors-brand-primary-default);
          }
        `
      : css`
          button {
            background-color: var(--fd-colors-brand-primary-default);
            color: var(--fd-colors-content-on-dark);
          }
        `}
`;
