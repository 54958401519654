import { get } from "lodash";
import { Store } from "redux";
import { HomepageStoryblokConfig } from "../types";
import { homeStoryblokConfigMock } from "../__mocks__/homeStoryblokConfigMock";

export const getHomepageTemplates = (store: Store): HomepageStoryblokConfig =>
  get(store, "homepageTemplates", homeStoryblokConfigMock);

export const getTemplateUserPreference = (store: Store): string =>
  get(store, "homepageTemplates.templateUserPreference", "default");
