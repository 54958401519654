import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { WagerType } from "@tvg/ts-types/Wager";
import { computeScrollWidth } from "../../utils/computeScrollWidth";

const useBetSelectorScroll = (filteredList: Array<WagerType[]>) => {
  const betTypesContainerRef = useRef<HTMLDivElement>(null);
  const [scrollAmount, setScrollAmount] = useState(0);

  const clientWidth = betTypesContainerRef.current?.clientWidth;
  const scrollWidth = betTypesContainerRef.current?.scrollWidth;

  const updateScrollPosition = useCallback(() => {
    if (betTypesContainerRef.current) {
      setScrollAmount(betTypesContainerRef.current.scrollLeft);
    }
  }, [filteredList, betTypesContainerRef]);

  // Event listener on scroll helps keep track of scroll position,
  useEffect(() => {
    if (betTypesContainerRef.current) {
      betTypesContainerRef.current.addEventListener(
        "scroll",
        updateScrollPosition
      );
    }

    return () => {
      if (betTypesContainerRef.current) {
        betTypesContainerRef.current.removeEventListener(
          "scroll",
          updateScrollPosition
        );
      }
    };
  }, [filteredList, betTypesContainerRef]);

  const isOverFlowing = useMemo(() => {
    if (betTypesContainerRef.current && scrollWidth && clientWidth) {
      return scrollWidth > clientWidth;
    }
    return false;
  }, [filteredList, betTypesContainerRef, scrollWidth, clientWidth]);

  const getWidthToScroll = () => computeScrollWidth(scrollWidth, clientWidth);

  const handleScrollTo = useCallback(
    (value: number) => {
      if (betTypesContainerRef.current) {
        betTypesContainerRef.current.scrollLeft += value;
      }
    },
    [filteredList]
  );

  return {
    updateScrollPosition,
    scrollAmount,
    isOverFlowing,
    handleScrollTo,
    betTypesContainerRef,
    getWidthToScroll
  };
};

export default useBetSelectorScroll;
