import React from "react";

const LocationErrorPageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="63"
    height="62"
    viewBox="0 0 63 62"
    fill="none"
  >
    <g filter="url(#filter0_d_10896_13783)">
      <path
        d="M5.19459 55.2188H57.8024C58.1773 55.2188 58.4189 54.8214 58.2463 54.4886L31.9438 3.76225C31.7573 3.40247 31.2426 3.40246 31.0561 3.76223L4.75072 54.4886C4.57812 54.8214 4.81967 55.2188 5.19459 55.2188Z"
        fill="#FFF1E6"
      />
      <path
        d="M31.5 23.25V38.75M31.5 42.625V48.4375M31.0561 3.76223L4.75072 54.4886C4.57812 54.8214 4.81967 55.2188 5.19459 55.2188H57.8024C58.1773 55.2188 58.4189 54.8214 58.2463 54.4886L31.9438 3.76225C31.7573 3.40247 31.2426 3.40246 31.0561 3.76223Z"
        stroke="#A44800"
        strokeWidth="3"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_10896_13783"
        x="-2.5"
        y="-2"
        width="68"
        height="68"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0666667 0 0 0 0 0.168627 0 0 0 0 0.266667 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10896_13783"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_10896_13783"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default LocationErrorPageIcon;
