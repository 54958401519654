import React, { PropsWithChildren } from "react";
import { Header } from "@tvg/design-system/web";
import { Container, Footer, Title } from "./styled-components";

interface SectionProps {
  title?: string;
  qaLabel?: string;
  hideDivider?: boolean;
  footer?: React.ReactNode;
  id?: string;
  isOnHomepage?: boolean;
}

const Section = ({
  title,
  qaLabel = "section",
  hideDivider,
  footer,
  children,
  id,
  isOnHomepage = false
}: PropsWithChildren<SectionProps>) => (
  <Container
    id={id}
    data-qa-label={qaLabel}
    hasFooter={!!footer}
    isOnHomepage={isOnHomepage}
  >
    {title && (
      <Title divider={!hideDivider}>
        <Header
          tag="h2"
          fontFamily="bold"
          qaLabel={`${qaLabel}-header`}
          color="--fd-colors-content-strong"
        >
          {title}
        </Header>
      </Title>
    )}
    {children}
    {footer && <Footer data-qa-label={`${qaLabel}-footer`}>{footer}</Footer>}
  </Container>
);

export default Section;
