import { get } from "lodash";
import RG_OPTIONS from "@tvg/types/ResponsibleGaming";
import { TransactionHistoryModalActions } from "@urp/transaction-history/src/redux/types";
import { PlayableBalanceModalActions } from "@urp/playable-balance/src/redux/types";
import { EducationHubModalActions } from "@urp/education-hub/src/redux/types";
import { InvalidResidentialAddressModalActions } from "@fdr/shared-actions/UserActions";
import formatDateInDaysOrYears from "../../utils/format";
import { RgModalActions } from "../actions/modal";
import { ModalState } from "../types";

export const initialState = {
  isModalOpen: false,
  accountWallet: {
    open: false,
    title: "",
    path: "",
    isAWLoginModalOpen: false
  },
  responsibleGaming: {
    isOpen: false,
    isFinished: false,
    state: null,
    status: "confirm",
    type: RG_OPTIONS.DEPOSIT_LIMIT,
    isProcessing: false,
    timeoutPeriod: {
      period: "",
      endDate: ""
    },
    fundingMethod: "",
    dailyLimit: {
      new: "$0",
      current: null,
      appliedDate: null
    }
  },
  isTransactionHistoryModalOpen: false,
  isPlayableBalanceModalOpen: false,
  isFullscreenVideoOpen: false
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const modalReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ModalState = initialState,
  action:
    | EducationHubModalActions
    | RgModalActions
    | TransactionHistoryModalActions
    | InvalidResidentialAddressModalActions
    | PlayableBalanceModalActions = {} as
    | RgModalActions
    | TransactionHistoryModalActions
    | InvalidResidentialAddressModalActions
    | PlayableBalanceModalActions
) => {
  switch (action.type) {
    case "RG_MODAL_DEPOSIT_LIMIT_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.DEPOSIT_LIMIT,
          dailyLimit: {
            new: get(
              action,
              "payload.newLimit",
              initialState.responsibleGaming.dailyLimit.new
            ),
            current: get(
              action,
              "payload.currentLimit",
              initialState.responsibleGaming.dailyLimit.current
            ),
            appliedDate: get(
              action,
              "payload.appliedDate",
              initialState.responsibleGaming.dailyLimit.appliedDate
            )
          }
        }
      };
    case "RG_MODAL_FUNDING_CONTROL_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.FUNDING_CONTROLS,
          fundingMethod: get(action, "payload.fundingMethod")
        }
      };

    case "OPEN_AW_MODAL": {
      return {
        ...state,
        accountWallet: {
          ...state.accountWallet,
          open: true,
          title: action.payload?.title,
          path: action.payload?.path
        },
        isModalOpen: true
      };
    }
    case "CLOSE_AW_MODAL": {
      return {
        ...state,
        accountWallet: {
          ...state.accountWallet,
          open: false
        },
        isModalOpen: false
      };
    }
    case "OPEN_INVALID_RESIDENTIAL_ADDRESS_MODAL": {
      return {
        ...state,
        isInvalidResidentialAddressModalOpen: true,
        isModalOpen: true
      };
    }
    case "CLOSE_INVALID_RESIDENTIAL_ADDRESS_MODAL": {
      return {
        ...state,
        isInvalidResidentialAddressModalOpen: false,
        isModalOpen: false
      };
    }
    case "OPEN_TRANSACTION_HISTORY_MODAL": {
      return {
        ...state,
        isTransactionHistoryModalOpen: true,
        isModalOpen: true
      };
    }
    case "CLOSE_TRANSACTION_HISTORY_MODAL": {
      return {
        ...state,
        isTransactionHistoryModalOpen: false,
        isModalOpen: false
      };
    }
    case "OPEN_PLAYABLE_BALANCE_MODAL": {
      return {
        ...state,
        isPlayableBalanceModalOpen: true,
        isModalOpen: true
      };
    }
    case "CLOSE_PLAYABLE_BALANCE_MODAL": {
      return {
        ...state,
        isPlayableBalanceModalOpen: false,
        isModalOpen: false
      };
    }
    case "RG_MODAL_TIMEOUT_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.TIMEOUT,
          timeoutPeriod: {
            period: get(action, "payload.period"),
            endDate: formatDateInDaysOrYears(
              get(action, "payload.period"),
              get(action, "payload.endDate"),
              true
            )
          }
        }
      };
    case "RG_MODAL_SELF_EXCLUSION_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.SELF_EXCLUSION,
          timeoutPeriod: {
            period: get(action, "payload.period"),
            endDate: formatDateInDaysOrYears(
              get(action, "payload.period"),
              get(action, "payload.endDate"),
              false
            )
          }
        }
      };
    case "RG_MODAL_SUSPENSION_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.SUSPEND,
          timeoutPeriod: {
            period: get(action, "payload.period"),
            endDate: get(action, "payload.endDate")
          }
        }
      };
    case "RG_MODAL_SUBMIT":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "submitted"
        }
      };
    case "RG_MODAL_CANCEL":
      return {
        ...state,
        responsibleGaming: initialState.responsibleGaming
      };
    case "RG_MODAL_FINISHED":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "finished",
          isFinished: true,
          status: "success"
        }
      };
    case "RG_MODAL_ERROR":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "error",
          status: "error"
        }
      };
    case "RG_MODAL_CLOSE":
      return {
        ...state,
        responsibleGaming: initialState.responsibleGaming
      };
    case "RG_MODAL_PROCESSING":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          isProcessing: get(action, "payload.isProcessing")
        }
      };
    case "SET_AW_LOGIN_MODAL_OPEN":
      return {
        ...state,
        accountWallet: {
          ...state.accountWallet,
          isAWLoginModalOpen: true
        }
      };
    case "SET_AW_LOGIN_MODAL_CLOSE":
      return {
        ...state,
        accountWallet: {
          ...state.accountWallet,
          isAWLoginModalOpen: false
        }
      };
    case "OPEN_FULLSCREEN_VIDEO_MODAL": {
      return {
        ...state,
        isFullscreenVideoOpen: true,
        isModalOpen: true
      };
    }
    case "CLOSE_FULLSCREEN_VIDEO_MODAL": {
      return {
        ...state,
        isFullscreenVideoOpen: false,
        isModalOpen: false
      };
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;
