import React from "react";

import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { Icon, useColorTokens } from "@tvg/design-system";
import { Mtp } from "@tvg/design-system/web";
import { HEADER, RUNNER, FOOTER } from "../../utils/constants";
import Header from "../Header";
import RunnerInfo from "../RunnerInfo";
import { CardContainerLink, CardFooter } from "./styled-components";
import { Props } from "./types";

export const RaceCard = ({
  qaLabel = "raceCard",
  race,
  to,
  runnersWithSilks = [],
  onClick
}: Props) => {
  const colorTokens = useColorTokens();

  return (
    <CardContainerLink to={to} data-qa-label={qaLabel}>
      <Header race={race} qaLabel={qaLabel} onClick={() => onClick(HEADER)} />
      {race.bettingInterests?.map((bi, idx) => (
        <RunnerInfo
          key={`${bi.biNumber}-${idx.toString()}`}
          qaLabel={`${qaLabel}-runnerInfo-${bi.biNumber}`}
          runnerBI={bi}
          isDriver={race.type.code === RaceTypeCodeEnum.HARNESS}
          hasBottomDivider={idx < (race.bettingInterests || []).length - 1}
          allRunnersHasSilksError={!runnersWithSilks.includes(true)}
          onClick={() => onClick(RUNNER)}
        />
      ))}
      <CardFooter
        onClick={() => onClick(FOOTER)}
        data-qa-label={`${qaLabel}-footer`}
      >
        <Mtp
          qaLabel={`${qaLabel}-mtp`}
          mtp={race.mtp}
          postTime={race.postTime}
          status={race.status.code}
          size="xs"
        />
        <Icon
          qaLabel={`${qaLabel}-IconBtn`}
          name="chevronRight"
          size="m"
          lineColor={colorTokens.content.subtle}
        />
      </CardFooter>
    </CardContainerLink>
  );
};
