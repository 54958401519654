import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import parseCapiMessage from "@tvg/utils/capiUtils";
import mediator from "@tvg/mediator";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import { isUserInAWFlows } from "@tvg/sh-lib-account-actions/src/selectors/user-ts";
import { isFDR, isURP } from "@tvg/utils/generalUtils";
import { Paragraph, IconNames, useIsValidIcon, Icon } from "@tvg/design-system";
import { getURPEducationHubMessages } from "@urp/education-hub/src/selectors";
import {
  UnorderedList,
  TitleContainer,
  ListItem,
  MenuEntryContainer
} from "./styled-components";
import { menuItemsFdr, menuItemsTvg } from "../menuItemsDefault";
import { MenuItems, SubMenus } from "../types";
import { enableURPEducationHub } from "../../store/selectors";
import { parseSubMenus } from "../utils";

export interface MenuItemsProps {
  onClickMenuItem: () => void;
}

const MenuItemsComp = ({ onClickMenuItem }: MenuItemsProps) => {
  const isValidIcon = useIsValidIcon();
  const navigate = useNavigate();
  const isUserInAWFlow = useSelector(isUserInAWFlows);
  const isURPEducationHubEnable = useSelector(enableURPEducationHub);
  const urpEducationHubMessages = useSelector(getURPEducationHubMessages);
  const isFdr = isFDR();
  const menuItems = useSelector((store) =>
    parseCapiMessage(
      store,
      "capi.messages.DesktopHeaderMenuItems",
      isFdr ? menuItemsFdr : menuItemsTvg
    )
  );
  const handleNavigation = useCallback(
    (targetBlank: boolean, url: string) =>
      targetBlank ? window.open(url, "_blank") : navigate(url),
    []
  );

  const onClickItem = useCallback(
    (newWindow: boolean, route: string, menuName: string, linkText: string) => {
      mediator.base.dispatch({
        type: "NAVIGATION:MENU_CLICK",
        payload: {
          action: "Global Navigation Link Clicked",
          module: "Hamburger",
          link: linkText,
          destinationUrl: route,
          menu: menuName
        }
      });

      handleNavigation(newWindow, route);
      onClickMenuItem();
    },
    []
  );

  return useMemo(
    () =>
      menuItems &&
      menuItems.map((menuItem: MenuItems, menuItemIndex: number) => {
        const filteredSubMenus =
          (isURP || isUserInAWFlow) && menuItem.name === "Handicapping"
            ? parseSubMenus(menuItem.subMenus, {
                isURPEducationHubEnable,
                urpEducationHubMessages,
                isDropdownMenu: false
              })
            : menuItem.subMenus;

        return (
          <UnorderedList key={`menu-${menuItemIndex.toString()}`}>
            <TitleContainer>
              <Paragraph
                fontFamily="condensedRegular"
                fontSize="s"
                qaLabel="hamburger-menu-nav-title"
                color="blue.200"
              >
                {menuItem.name.toUpperCase()}
              </Paragraph>
            </TitleContainer>
            {filteredSubMenus.map((item: SubMenus, index: number) => {
              const newWindow =
                item.options && item.options.target === "newWindow";
              const iconName = isValidIcon(item.icon) ? item.icon : "info";

              return (
                <ListItem
                  data-qa-label="hamburger-menu-nav-item"
                  key={`menu-item-${index.toString()}`}
                >
                  <MenuEntryContainer
                    data-qa-label={item.subQaLabel}
                    onClick={() =>
                      onClickItem(
                        newWindow as boolean,
                        item.route,
                        menuItem.name,
                        item.name
                      )
                    }
                  >
                    <Icon
                      qaLabel={`icon-${item.subQaLabel}`}
                      name={iconName as IconNames}
                      size="s"
                      lineColor="blue_accent.000"
                      backgroundColor="none"
                      mr="8px"
                    />
                    {item.name}
                  </MenuEntryContainer>
                </ListItem>
              );
            })}
          </UnorderedList>
        );
      }),
    [menuItems]
  );
};

export default MenuItemsComp;
