import React from "react";
import { get } from "lodash";

import DerbyBanner from "@urp/derby-banner";
import { DerbyEventStatus } from "@urp/derby-banner/src/types";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import { BannersConfigContext } from "./types";
import { useDerbyBannerConfig } from "./hooks/useDerbyBannerConfig";

export const Banner = ({ context }: { context: BannersConfigContext }) => {
  const { loading, derbyRace, bannerCfg, eventStatus, onBannerCtaClick } =
    useDerbyBannerConfig({
      context
    });

  const postTimeAvailableRace = get(derbyRace, "postTime");
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const isDeskAndHomepageView =
    isDesktop && context === BannersConfigContext.HOME_PAGE;

  if (loading || !bannerCfg) {
    return null;
  }

  return (
    <DerbyBanner
      raceStartDate={
        postTimeAvailableRace ? new Date(postTimeAvailableRace) : null
      }
      eventStatus={eventStatus as unknown as DerbyEventStatus}
      // @ts-ignore
      bannerCfg={bannerCfg}
      orientation={isDeskAndHomepageView ? "landscape" : "portrait"}
      onCtaClick={onBannerCtaClick}
    />
  );
};

export default Banner;
