import React, { Fragment, useMemo } from "react";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import { Paragraph } from "@tvg/design-system/web";
import StoryblokClient from "storyblok-js-client";
import { useLocation } from "react-router-dom";
import Section from "../Section";
import {
  EducationHubSectionType,
  ContentBetTypes,
  StoryblokLink
} from "../../types";
import { Header } from "./styled-components";
import BetTypeInfo from "../BetTypeInfo";
import Footer from "../Footer";

interface BetTypesSectionProps {
  data: EducationHubSectionType;
  storyblokClient?: StoryblokClient;
  footerTitle?: string;
  id?: string;
}

const BetTypesSection = ({
  data,
  storyblokClient,
  footerTitle,
  id
}: BetTypesSectionProps) => {
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);

  const qaLabel = useMemo(
    () => data?.tab_name.replace(/ /g, "-").toLowerCase(),
    [data?.tab_name]
  );

  const betCategories = useMemo(
    () => data.content as ContentBetTypes[],
    [data.content]
  );

  const { hash } = useLocation();

  const betCategoriesByHash = useMemo(() => {
    if (hash) {
      const hashFormatted = hash.replace("#", "");
      const betCategoriesByFiltered = betCategories.filter(
        (category) => category.hash === hashFormatted
      );

      return betCategoriesByFiltered.length > 0
        ? betCategoriesByFiltered
        : betCategories;
    }
    return betCategories;
  }, [betCategories, hash]);

  const hasHashAndFilteredCategories = useMemo(
    () => hash && betCategoriesByHash.length > 0,
    [hash, betCategoriesByHash]
  );

  const sectionTitle = useMemo(() => {
    if (!isMobile) {
      return hasHashAndFilteredCategories
        ? betCategoriesByHash[0].title
        : data.tab_name;
    }
    return undefined;
  }, [
    isMobile,
    hasHashAndFilteredCategories,
    betCategoriesByHash,
    data.tab_name
  ]);

  return (
    <Section
      id={id}
      title={sectionTitle}
      qaLabel={qaLabel}
      footer={
        hasHashAndFilteredCategories && (
          <Footer
            footerTitle={footerTitle ?? ""}
            footerLink={
              { cached_url: "educational-hub/modals/betting" } as StoryblokLink
            }
          />
        )
      }
    >
      {betCategoriesByHash?.map((betTypeCategory: ContentBetTypes) => (
        <Fragment key={`betCategory-${betTypeCategory.title.replace(" ", "")}`}>
          <Header>
            <Paragraph
              fontFamily="condensedRegular"
              color="--fd-colors-content-subtle"
              textTransform="uppercase"
              qaLabel={`betCategoryTitle-${betTypeCategory.title.replace(
                / /g,
                ""
              )}`}
            >
              {betTypeCategory.title}
            </Paragraph>
          </Header>
          {betTypeCategory.bet_type.map((betType, index) => (
            <BetTypeInfo
              key={index.toString()}
              title={betType.bet_title}
              description={betType.bet_description}
              payout={+betType.bet_payout}
              difficulty={betType.bet_difficulty[0].difficulty}
              difficultyLabel={betType.bet_difficulty[0].label}
              storyblokClient={storyblokClient}
            />
          ))}
        </Fragment>
      ))}
    </Section>
  );
};

export default BetTypesSection;
