import React from "react";
import {
  useMediaQuery,
  breakpoints,
  Button,
  useTheme
} from "@tvg/design-system";
import { Icon } from "../Icon";
import { LoadingSpinner } from "../LoadingSpinner";
import { SearchBarProps } from "./types";
import {
  DEFAULT_WARNING_MESSAGES,
  DEFAULT_TOP_POSITION_OFFSET
} from "./constants";
import { useSearchBar } from "./useSearchBar";
import WarningPortal from "./components/WarningPortal";
import WarningMessage from "./components/WarningMessage";
import ListItems from "./components/ListItems";
import {
  Wrapper,
  TextInputContainer,
  TextInput,
  ListItemsContainer,
  SearchBarContainer,
  CloseButton
} from "./styled-components";

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  items,
  searchTerm,
  setSearchTerm,
  minSearchTermLength = 3,
  isLoading = false,
  debounceTime = 300,
  warningMessages = {
    minLength: DEFAULT_WARNING_MESSAGES.minLength,
    notFound: DEFAULT_WARNING_MESSAGES.notFound
  },
  warningTopPositionOffset = DEFAULT_TOP_POSITION_OFFSET,
  qaLabel,
  handleOnClick,
  filteredItemsAction
}) => {
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const {
    filteredItems,
    focused,
    hasWarning,
    isOpen,
    wrapperRef,
    listRef,
    warningPosition,
    handleOnChange,
    handleOnFocus,
    handleOnBlur,
    handleItemClick,
    handleOnClear,
    renderWarningMessage,
    activeIndex,
    handleKeyDown
  } = useSearchBar({
    items,
    searchTerm,
    setSearchTerm,
    minSearchTermLength,
    isLoading,
    debounceTime,
    warningMessages,
    handleOnClick,
    filteredItemsAction,
    warningTopPositionOffset
  });
  const theme = useTheme();

  return (
    <>
      {hasWarning && !isLoading && (
        <WarningPortal position={warningPosition}>
          <WarningMessage
            message={renderWarningMessage()}
            qaLabel={`${qaLabel}-warning`}
          />
        </WarningPortal>
      )}
      <Wrapper ref={wrapperRef}>
        <SearchBarContainer>
          <TextInputContainer
            data-qa-label={qaLabel}
            isFocused={focused}
            hasWarning={hasWarning}
          >
            <Icon
              name="search"
              size="s"
              mr="space-4"
              lineColor={hasWarning ? "content.warning" : "content.link"}
            />
            <TextInput
              type="text"
              data-qa-label="text-input"
              placeholder={placeholder}
              value={searchTerm}
              onChange={handleOnChange}
              onFocus={handleOnFocus}
              onBlur={!isMobile ? handleOnBlur : undefined}
              onKeyDown={handleKeyDown}
              role="combobox"
              aria-expanded={isOpen}
              aria-autocomplete="list"
              aria-controls="search-listbox"
              aria-activedescendant={
                activeIndex >= 0 ? `item-${activeIndex}` : undefined
              }
            />
            {isMobile && focused && !isLoading && (
              <CloseButton
                onClick={handleOnClear}
                data-qa-label={`${qaLabel}-btn-close`}
              >
                <Icon
                  name="close"
                  size="s"
                  lineColor={
                    hasWarning
                      ? theme.colorTokens.content.warning
                      : theme.colorTokens.lhnHeader.selectedcell.border
                  }
                />
              </CloseButton>
            )}
            {isLoading && (
              <LoadingSpinner
                size="s"
                ml="space-4"
                color="--fd-colors-core-blue-l-1"
              />
            )}
          </TextInputContainer>
          {isMobile && focused && (
            <Button
              variant="tertiary"
              ml="space-3"
              qaLabel={`${qaLabel}-btn-cancel`}
              onPress={handleOnClear}
            >
              Cancel
            </Button>
          )}
        </SearchBarContainer>
        <ListItemsContainer id="search-listbox" role="listbox">
          {isOpen && !hasWarning && !isMobile && (
            <ListItems
              items={filteredItems}
              searchTerm={searchTerm}
              handleOnClick={handleItemClick}
              activeIndex={activeIndex}
              listRef={listRef}
            />
          )}
        </ListItemsContainer>
      </Wrapper>
    </>
  );
};
