import { get, noop } from "lodash";
import { isBefore } from "date-fns";
import decodeJWT from "jwt-decode";
import {
  getAuthTokenCookie,
  getSportsbookRegionCookie,
  getUserSessionData
} from "@tvg/sh-utils/sessionUtils";
import {
  getGeoClientInstance,
  getGeoClientLicense,
  injectLicenseInGeoClient,
  setupGeoClientEventHandler
} from "./solus";
import {
  GeoClientFields,
  GeoClientTokenStatus,
  GeolocationCallbacks,
  GeoLocationConfig,
  GeolocationCustomErrorCodes,
  GeolocationCustomErrorMessages,
  GeolocationReason,
  GeolocationSession,
  GeoStorageKey
} from "./solus/types/solus";
import { cleanCacheGeoData, getCacheGeoData } from "./solus/utils";
import { checkLocationPermission } from "./utils/checkLocationPermission";

export const relocateUserInjection =
  (config: GeoLocationConfig, geoHandlers: GeolocationCallbacks) =>
  async (previousTokenStatus = GeoClientTokenStatus.SUCCESSFUL_TOKEN) => {
    const { onFailed = noop } = geoHandlers;

    try {
      const userSession = getAuthTokenCookie();
      const geoState = getSportsbookRegionCookie();
      const { fdUserId, sessionId } = getUserSessionData(userSession);
      const session = {
        userId: fdUserId,
        sessionId,
        userAuthToken: userSession
      };
      locateUser(
        geoState,
        GeolocationReason.PERIODIC,
        session,
        config,
        geoHandlers,
        previousTokenStatus
      );
    } catch (e) {
      onFailed({
        message: GeolocationCustomErrorMessages.INVALID_LICENSE,
        code: GeolocationCustomErrorCodes.INVALID_LICENSE
      });
      console.error("[Solus]: Fail to try refresh geocomply session", e);
    }
  };

export const locateUser = async (
  geoState: string,
  reason: GeolocationReason,
  session: GeolocationSession,
  config: GeoLocationConfig,
  geoHandlers: GeolocationCallbacks,
  previousToken: GeoClientTokenStatus = GeoClientTokenStatus.NO_TOKEN
) => {
  const geoClient = getGeoClientInstance();
  const { onProcess = noop, onFailed = noop } = geoHandlers;
  const userSession = getAuthTokenCookie();

  if (
    (await checkLocationPermission()) &&
    geoClient &&
    !geoClient.inProgress &&
    userSession
  ) {
    const { userId, sessionId } = session;
    const geoPacket = getCacheGeoData(GeoStorageKey.GEO_PACKET);
    const geoLicense = getCacheGeoData(GeoStorageKey.LICENSE);
    let isValidCachedGeoPacket = false;
    const relocateUserCallback = relocateUserInjection(config, geoHandlers);

    geoClient.setUserId(userId);
    geoClient.setUserSessionId(sessionId);
    geoClient.customFields.set(GeoClientFields.SESSION_ID, sessionId);
    geoClient.customFields.set(
      GeoClientFields.PREVIOUS_TOKEN_STATUS,
      previousToken
    );
    geoClient.setReason(reason);

    setupGeoClientEventHandler(
      geoClient,
      config,
      geoHandlers,
      relocateUserCallback
    );

    if (geoPacket && geoLicense) {
      const decodedGeoPacket = decodeJWT(geoPacket);
      const decodedGeoLicense = decodeJWT(geoLicense, { header: true });

      // GeoComply Cached Data
      // GeoPacket
      const geoPacketSessionId = get(decodedGeoPacket, "session_id");
      const geoPacketUserId = get(decodedGeoPacket, "user_id");
      const geoPacketExpires = get(decodedGeoPacket, "expires");
      const geoPacketRegionCode = get(decodedGeoPacket, "region_code");

      // Geo License
      const geoLicenseExpiration = get(decodedGeoLicense, "expires", 0) * 1000; // To transform the expires time to Date format

      isValidCachedGeoPacket =
        geoLicense &&
        isBefore(new Date(), new Date(geoLicenseExpiration)) &&
        geoPacketRegionCode === geoState &&
        geoPacketSessionId.toString() === sessionId.toString() &&
        geoPacketUserId.toString() === userId.toString() &&
        isBefore(new Date(), new Date(geoPacketExpires));
    }

    try {
      let license = geoLicense;
      if (license && reason === GeolocationReason.PERIODIC) {
        onProcess();
        license = await getGeoClientLicense(config, license);
      } else if (!isValidCachedGeoPacket) {
        onProcess();
        license = await getGeoClientLicense(config);
      }
      injectLicenseInGeoClient(license, isValidCachedGeoPacket);
    } catch (err) {
      console.error("GeoComply Error: ", err);
      onFailed({
        message: GeolocationCustomErrorMessages.INVALID_LICENSE,
        code: GeolocationCustomErrorCodes.INVALID_LICENSE
      });
    }
  } else {
    cleanCacheGeoData();
    onFailed({
      message: GeolocationCustomErrorMessages.UNAUTHORIZED_LOCATION_PERMISSION,
      code: GeolocationCustomErrorCodes.UNAUTHORIZED_LOCATION_PERMISSION
    });
  }
};
