import type { RaceInfo } from "@tvg/ts-types/Race";
import type { BannerConfig, BannersConfigType } from "../types";

export type SetDerbyBanner = {
  type: "SET_DERBY_BANNER_ACTION";
  payload: BannerConfig;
};

export interface SetBannersEventStatusAction {
  type: "SET_BANNERS_EVENT_STATUS";
  payload: BannersConfigType;
}

export interface SetBannersDerbyRaceAction {
  type: "SET_BANNERS_DERBY_RACE";
  payload: RaceInfo;
}

export const setDerbyBanner = (value: BannerConfig): SetDerbyBanner => ({
  type: "SET_DERBY_BANNER_ACTION",
  payload: value
});

export const setBannersEventStatus = (
  value: BannersConfigType
): SetBannersEventStatusAction => ({
  type: "SET_BANNERS_EVENT_STATUS",
  payload: value
});

export const setHomepageDerbyRace = (
  value: RaceInfo
): SetBannersDerbyRaceAction => ({
  type: "SET_BANNERS_DERBY_RACE",
  payload: value
});
