import React, { FC, memo } from "react";
import { RaceInfo } from "../raceInfo";
import { Container } from "../../styled-components";
import type { MtpCellProps } from "../../types";
import { Paragraph } from "../../../Typography";

export const RaceOff: FC<
  Pick<
    MtpCellProps,
    "withBackground" | "align" | "qaLabel" | "trackCode" | "raceNumber"
  >
> = memo(
  ({
    align,
    withBackground,
    qaLabel = "mtp-cell",
    trackCode,
    raceNumber,
    ...rest
  }) => {
    const raceLabel = `${trackCode} - R${raceNumber}`;

    return (
      <Container
        withBackground={withBackground}
        align={align}
        bgColor="--fd-colors-system-positive-background-subtle"
        lineColor="--fd-colors-border-default"
        data-qa-label={qaLabel}
        {...rest}
      >
        <Paragraph
          fontFamily="bold"
          textAlign={align}
          qaLabel={`${qaLabel}-race-status`}
          fontSize="17px"
          lineHeight="22px"
          color="--fd-colors-system-component-mtp-content-race-off"
          margin={0}
          letterSpacing="-0.2px"
        >
          Race Off
        </Paragraph>
        <RaceInfo align={align} label={raceLabel} qaLabel={qaLabel} />
      </Container>
    );
  }
);
