import { Dispatch } from "redux";
import mediator from "@tvg/mediator";
import {
  getCompleteLoginOptions,
  accountWalletSubscriptions
} from "@tvg/sh-lib-account-wallet/src/utils/subscriptions";
import {
  askLocation,
  isXSell,
  subscribeLaunchInRegion,
  subscribeWebViewForeground
} from "@tvg/sh-utils/mobileUtils";
import {
  getAuthTokenCookie,
  createSportsbookRegionCookie,
  checkValidWageringState,
  getLoginAuthToken,
  getUserSessionData,
  getSportsbookRegionCookie
} from "@tvg/sh-utils/sessionUtils";
import { setGeoLocationState } from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import { completeLogin } from "@tvg/sh-lib-account-wallet/src/session/login";
import { IAWFlows } from "@tvg/sh-lib-account-wallet/src/sdkWebTypes";
import { Props } from "@tvg/sh-lib-account-wallet/src/types";
import { NullaryFn } from "@tvg/ts-types/Functional";
// @ts-ignore
import { initialState } from "@tvg/sh-lib-account-actions/src/reducers/userDataReducer";

const setSessionDataOnStorage = (authToken: string) => {
  const { sessionId, fdUserId } = getUserSessionData(authToken);
  localStorage.setItem("xauthtoken", authToken);
  localStorage.setItem("xlogintoken", getLoginAuthToken());
  localStorage.setItem("sessionid", sessionId);
  localStorage.setItem("fdUserId", fdUserId);
};

export const handleAWEvents = (
  awFlowsSDK: IAWFlows,
  dispatch: Dispatch,
  cleanLoginCallback: NullaryFn<void>,
  getActualProps: NullaryFn<Props>
) => {
  const props = getActualProps();
  const stateAbbr = getSportsbookRegionCookie();

  accountWalletSubscriptions(getActualProps, cleanLoginCallback, awFlowsSDK);

  if (!props.user.isLogged && getAuthTokenCookie()) {
    const authToken = getAuthTokenCookie();
    completeLogin(authToken, dispatch, stateAbbr);
    setSessionDataOnStorage(authToken);
  } else if (!props.user.isLogged) {
    setTimeout(() =>
      mediator.base.dispatch({
        type: "TVG_LOGIN:USER_SESSION_UPDATE",
        payload: {
          ...initialState,
          hasRequested: true
        }
      })
    );
  }

  subscribeLaunchInRegion((authToken, sportsbookRegion) => {
    const { allowedStates, user } = getActualProps();
    const isUserStateAllowedBets = checkValidWageringState(
      sportsbookRegion,
      allowedStates
    );

    if (isUserStateAllowedBets) {
      createSportsbookRegionCookie(sportsbookRegion);
      dispatch(setGeoLocationState(sportsbookRegion));

      if (authToken) {
        const enforceAcceptTerms = true;
        completeLogin(
          authToken,
          dispatch,
          sportsbookRegion,
          getCompleteLoginOptions(user.userName, enforceAcceptTerms)
        ).then(() => isXSell() && askLocation());
      }
    }
  });

  subscribeWebViewForeground();
};
