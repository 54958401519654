import React, { RefObject, useRef, useState } from "react";
import { Paragraph } from "@tvg/design-system/web";
import {
  IconButton,
  Button,
  Modal,
  useOnClickOutside
} from "@tvg/design-system/src";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomepageTemplateUserPrefDesktop,
  getHomepageTemplateUserPrefMobile
} from "@tvg/sh-lib-preferences/redux/selectors";
import { getAccountNumber } from "@urp/store-selectors";
import {
  setUserPrefsHomepageTemplateMobile,
  setUserPrefsHomepageTemplateDesktop
} from "@tvg/sh-lib-account-actions/src/actions/login";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { updateUserTemplatePref } from "@tvg/pref/src/components/HomepageConfig/utils";
import { getHomepageTemplates } from "../../store/selectors";
import {
  Container,
  TemplateList,
  ButtonContainer,
  CloseContainer
} from "./styled-components";
import TemplateItem from "../TemplateOption";
import { HomepageTemplate } from "../../types";

interface Props {
  isMobile?: boolean;
  onSettingsClick: NullaryFn<void>;
  onDismiss: NullaryFn<void>;
}

function HPTemplateSelector({
  isMobile = false,
  onSettingsClick,
  onDismiss
}: Props) {
  const templatesConfig = useSelector(getHomepageTemplates);
  const dispatch = useDispatch();
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);
  const ref = useRef<HTMLDivElement>();
  useOnClickOutside(ref as RefObject<HTMLElement>, () =>
    setIsTemplatesModalOpen(false)
  );
  const userPrefTemplate = useSelector(
    isMobile
      ? getHomepageTemplateUserPrefMobile
      : getHomepageTemplateUserPrefDesktop
  );

  const selectedTemplate = userPrefTemplate.toLowerCase() || "default";
  const accountNumber = useSelector(getAccountNumber);

  const handleOnChange = async (template: string) => {
    try {
      if (template === userPrefTemplate) return;

      const result = await updateUserTemplatePref(
        accountNumber,
        template,
        isMobile
      );

      if (result.success) {
        if (isMobile) {
          dispatch(setUserPrefsHomepageTemplateMobile(template));
        } else {
          dispatch(setUserPrefsHomepageTemplateDesktop(template));
        }
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error(
        "An unexpected error occurred during updating of template preference:",
        error
      );
    }
  };

  const handleDismiss = () => {
    setIsDismissed(true);
    onDismiss();
  };

  return (
    <>
      <Container isDismissed={isDismissed} data-qa-label="hp-template-selector">
        <Paragraph
          fontSize="s"
          color="--fd-colors-core-white"
          qaLabel="qaLabel"
          marginRight="8px"
        >
          {isMobile
            ? "Select your homepage profile"
            : "Select the profile you want to see on your homepage"}
        </Paragraph>
        <ButtonContainer ref={ref as RefObject<HTMLDivElement>}>
          <Button
            variant="secondary_dark"
            onPress={() => setIsTemplatesModalOpen((prevState) => !prevState)}
            qaLabel="hp-template-selector-button"
            iconPosition="end"
            icon={
              isTemplatesModalOpen && !isMobile ? "chevronUp" : "chevronDown"
            }
            size={isMobile ? "s" : "m"}
          >
            {capitalize(selectedTemplate)}
          </Button>
          {isTemplatesModalOpen && !isMobile && (
            <TemplateList data-qa-label="hp-template-selector-list">
              {templatesConfig.templates.map((template) => (
                <TemplateItem
                  qaLabel={`hp-template-selector-option-${template}`}
                  title={capitalize(template)}
                  description={
                    ((templatesConfig[template] as HomepageTemplate)
                      ?.description as string) ?? ""
                  }
                  onClick={() => handleOnChange(template)}
                  isActive={selectedTemplate === template}
                />
              ))}
            </TemplateList>
          )}
        </ButtonContainer>
        <IconButton
          iconName="settings"
          qaLabel="hp-template-selector-settings"
          variant="tertiary"
          tertiaryColor="white.900"
          size="s"
          onPress={onSettingsClick}
        />
        <CloseContainer>
          <IconButton
            iconName="close"
            qaLabel="hp-template-selector-close"
            variant="tertiary"
            tertiaryColor="white.900"
            size="s"
            onPress={handleDismiss}
          />
        </CloseContainer>
      </Container>

      {isMobile && (
        <Modal
          isOpen={isTemplatesModalOpen}
          title="Homepage Configuration"
          type="flexible"
          qaLabel="hp-template-selector-modal"
          onClose={() => setIsTemplatesModalOpen(false)}
          onOverlayClick={() => setIsTemplatesModalOpen(false)}
        >
          <TemplateList>
            {templatesConfig.templates.map((template) => (
              <TemplateItem
                qaLabel={`hp-template-selector-option-${template}`}
                title={capitalize(template)}
                description={
                  ((templatesConfig[template] as HomepageTemplate)
                    ?.description as string) ?? ""
                }
                onClick={() => handleOnChange(template)}
                isActive={selectedTemplate === template}
              />
            ))}
          </TemplateList>
        </Modal>
      )}
    </>
  );
}

export default HPTemplateSelector;
