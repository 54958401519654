import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import tvgConf from "@tvg/conf";
import { fetchStoryblok } from "@tvg/sh-lib-storyblok/src/fetchStoryblok";
import { STORYBLOK_CONTENT_PATH } from "@tvg/sh-lib-storyblok/src/constraints/config";
import { getStoredSlugContent } from "../selectors";
import { setSelectedSlug, setStoredContentSlugs } from "../redux/actions";
import { StoryblokHomeContent } from "../types";

const HOMEPAGE_SLUG = "homepage";

const useHomeData = () => {
  const dispatch = useDispatch();

  const storedContent = useSelector((store) =>
    getStoredSlugContent<StoryblokHomeContent>(store, "homepage")
  );

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [homeData, setHomeData] = useState<StoryblokHomeContent | undefined>(
    storedContent
  );

  const fetchHomeContent = useCallback(async () => {
    if (typeof storedContent === "undefined") {
      try {
        setLoading(true);
        const data = await fetchStoryblok<StoryblokHomeContent>(
          STORYBLOK_CONTENT_PATH.EDUCATIONAL_HUB,
          HOMEPAGE_SLUG,
          tvgConf().config().storyblokVersion
        );
        dispatch(
          setStoredContentSlugs({ key: HOMEPAGE_SLUG, data: data.content })
        );
        setHomeData(data.content);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [storedContent]);

  useEffect(() => {
    fetchHomeContent();
    dispatch(setSelectedSlug(HOMEPAGE_SLUG));
  }, [dispatch]);

  return { homeData, isLoading, isError };
};

export default useHomeData;
