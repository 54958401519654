import type { Item } from "@tvg/design-system/web/components/SearchBar/types";

type FilteredHorseNames = {
  type: "FILTERED_HORSE_NAMES";
  payload: Item[];
};

export type Actions = FilteredHorseNames;

export const setFilteredHorseNames = (
  horseNames: Item[]
): FilteredHorseNames => ({
  type: "FILTERED_HORSE_NAMES",
  payload: horseNames
});
