import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { get, uniqWith, isEqual } from "lodash";
import { useQuery } from "@apollo/client";
import { TalentPickData } from "@tvg/ts-types/TalentPicks";
import { Talent } from "@tvg/ts-types/Talent";
import useWagerProfile from "@tvg/custom-hooks/hooks/useWagerProfile";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import { RaceProgram } from "@tvg/ts-types/Race";

import useTalentPicks from "./useTalentPicks";
import {
  filterTalentPicks,
  onFilterHandler,
  getTalentPicksGroups,
  onTalentsFilterHandler,
  filterTalentPicksByTalents
} from "../helpers";
import {
  EXPERT_FILTER_DEFAULT,
  TRACK_FILTER_DEFAULT,
  TALENT_FILTER_DEFAULT
} from "../constants";
import { TalentFilter } from "../types";
import { GET_GRAPH_TALENT_PICKS_RACES } from "../graphql/queries/talentPicksRacesQuery";
import { racesApolloClientOptions } from "../graphql/options.graph";
import { getBlacklistedWagerTypes } from "../selectors";

const useTalentPicksWithFilters = (
  onHomePage: boolean = false,
  useCache = true
) => {
  const [trackFilter, setTrackFilter] = useState(TRACK_FILTER_DEFAULT);
  const [expertFilter, setExpertFilter] = useState(EXPERT_FILTER_DEFAULT);
  const [talentPickRaces, setTalentPickRaces] = useState<RaceProgram[]>([]);
  const { blacklistedBets } = useSelector(getBlacklistedWagerTypes);
  const wagerProfile = useWagerProfile();

  const [talentsFilter, setTalentsFilter] = useState<TalentFilter[]>([]);
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const { loading, talentPicks } = useTalentPicks({
    wagerProfile,
    races: talentPickRaces,
    useCache
  });

  const { data: races, loading: raceLoading } = useQuery(
    GET_GRAPH_TALENT_PICKS_RACES,
    racesApolloClientOptions({
      talentPicks: talentPicks.talentPicks,
      wagerProfile
    })
  );

  useEffect(() => {
    if (!raceLoading && races?.talentPicksRaces) {
      setTalentPickRaces(
        races.talentPicksRaces.reduce(
          (racesAcc: Array<RaceProgram>, talentPickRace: RaceProgram) => {
            const talentPickTrackRaces: Array<RaceProgram> = get(
              talentPickRace,
              "races",
              []
            );
            racesAcc.push(...talentPickTrackRaces);
            return racesAcc;
          },
          []
        )
      );
    }
  }, [raceLoading, races]);

  const {
    talentPicksGroupsFiltered: talentPicksGroups,
    trackOptionsFiltered: trackOptions,
    expertOptionsFiltered: expertOptions
  } = useMemo(() => {
    const talentPicksFiltered = isMobile
      ? filterTalentPicksByTalents(
          talentPicks.talentPicks,
          talentsFilter,
          blacklistedBets
        )
      : filterTalentPicks(
          talentPicks.talentPicks,
          trackFilter.value,
          expertFilter.value
        );
    const talentPicksGroupsFiltered = getTalentPicksGroups(
      talentPicksFiltered,
      onHomePage
    );

    const newTracks = uniqWith(
      talentPicks.talentPicks.map((pick: TalentPickData) => ({
        value: pick.track,
        label: pick.trackName
      })),
      isEqual
    );
    const trackOptionsFiltered = [TRACK_FILTER_DEFAULT, ...newTracks];

    const sortedTalents =
      talentPicks.talentsList.sort((first, second) =>
        first.name < second.name ? -1 : 0
      ) ?? [];

    const newExperts = sortedTalents
      .map((talent: Talent) => {
        const filteredPicks = talentPicks.talentPicks.filter(
          (talentPick: TalentPickData) =>
            isMobile
              ? talentPick.talent.talentId === talent.talentId &&
                !blacklistedBets?.includes(talentPick.wagerType.abbreviation)
              : talentPick.talent.talentId === talent.talentId
        );

        return {
          value: talent.talentId,
          label: talent.name,
          image: talent.pictureUrl,
          picksCount: filteredPicks.length
        };
      })
      .filter((currentTalent) => !isMobile || currentTalent.picksCount > 0);

    const expertOptionsFiltered = [TALENT_FILTER_DEFAULT, ...newExperts];

    return {
      talentPicksGroupsFiltered,
      trackOptionsFiltered,
      expertOptionsFiltered
    };
  }, [
    blacklistedBets,
    loading,
    talentPicks,
    trackFilter,
    expertFilter,
    talentsFilter
  ]);

  return {
    loading: loading || raceLoading,
    ...talentPicksGroups,
    trackOptions,
    expertOptions,
    trackFilter,
    expertFilter,
    onTrackFilterHandler: onFilterHandler(trackOptions, setTrackFilter),
    onExpertFilterHandler: onFilterHandler(expertOptions, setExpertFilter),
    onExpertsFilterHandler: onTalentsFilterHandler(
      expertOptions,
      talentsFilter,
      setTalentsFilter
    )
  };
};

export default useTalentPicksWithFilters;
