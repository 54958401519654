import React, { forwardRef, useCallback, useState } from "react";
import { SortContext } from "./context";
import { Dropdown } from "./components/Dropdown";
import { Option } from "./components/Option";
import {
  SortButton,
  Container,
  LabelText,
  SelectionText
} from "./styled-components";
import { Icon } from "../Icon";
import type { SortProps } from "./types";

export const Sort = forwardRef<HTMLButtonElement, SortProps>(
  (
    {
      children,
      qaLabel = "sort",
      buttonLabel,
      selectedLabel,
      selectedValue,
      onChange,
      closeOnChange = false,
      iconName,
      size = "m",
      isDisabled = false,
      isContentCustom = false,
      layer = 999
    },
    ref
  ) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const onClick = useCallback(() => {
      setIsDropdownOpen((prevState) => !prevState);
    }, []);

    const onMouseLeave = useCallback(() => {
      setIsDropdownOpen(false);
    }, []);

    const onChangeHandler = (val: string) => {
      onChange(val);
      if (isDropdownOpen && closeOnChange) {
        setIsDropdownOpen(false);
      }
    };

    return (
      <Container onMouseLeave={onMouseLeave}>
        <SortButton
          ref={ref}
          type="button"
          onClick={onClick}
          data-qa-label={qaLabel}
          disabled={isDisabled}
          size={size}
        >
          <>
            {buttonLabel && (
              <LabelText>
                {selectedLabel ? `${buttonLabel}:` : buttonLabel}
              </LabelText>
            )}
            {selectedLabel && <SelectionText>{selectedLabel}</SelectionText>}
            <Icon
              name={iconName ?? (isDropdownOpen ? "chevronUp" : "chevronDown")}
              size="s"
              lineColor={
                isDisabled
                  ? "--fd-colors-content-disabled"
                  : "--fd-colors-component-button-tertiary-content-hover"
              }
            />
          </>
        </SortButton>
        <SortContext.Provider
          value={{
            isDropdownOpen,
            selectedValue,
            onChange: onChangeHandler,
            layer
          }}
        >
          {isContentCustom ? (
            children
          ) : (
            <div data-qa-label={`${qaLabel}-dropdown`}>{children}</div>
          )}
        </SortContext.Provider>
      </Container>
    );
  }
);

export type { SortProps };
export { SortContext, Dropdown, Option };
export default Sort;
