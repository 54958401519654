import React, { memo, useState } from "react";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Carousel } from "@tvg/design-system/web";
import tvgConf from "@tvg/conf";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { ItemContainer } from "./styled-components";
import { sendSelectedEvent } from "../../utils";
import type { Playlist } from "../../types";
import VideoThumbnail from "../VideoThumbnail";
import VideoPlayerModal from "../VideoPlayerModal";
import { BASE } from "../../constants";
import { Props } from "./types";
import LoadingMask from "../LoadingMask";

const isDeviceDesktop = tvgConf().device === "desktop";

const VideoPlaylist = ({ title, qaLabel, playlist }: Props) => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm) || isDeviceDesktop;
  const [selectedItem, setSelectedItem] = useState<Playlist | null>(null);

  const onClose = () => {
    setSelectedItem(null);
  };

  const handleVideoClick = (item: Playlist) => {
    sendSelectedEvent(item.description.title, item.description.category);
    setSelectedItem(item);
  };

  if (!playlist.length) return <LoadingMask />;

  const renderItems = () =>
    playlist.map((item, index) => (
      <ItemContainer key={`${item.description.title}-${index.toString()}`}>
        <VideoThumbnail
          key={`${item.description.title}-${index.toString()}`}
          item={item}
          onClick={() => handleVideoClick(item)}
          qaLabel={buildQaLabel([qaLabel, BASE])}
        />
      </ItemContainer>
    ));

  return (
    <>
      <Carousel
        title={title}
        items={renderItems()}
        slidesToScroll="auto"
        dragFree={!isDesktop}
        slideSize={292}
        containerSpace={{ pb: "space-4" }}
        displayNavButtons={false}
        displayHeader={false}
      />
      <VideoPlayerModal
        item={selectedItem}
        onClose={onClose}
        qaLabel={buildQaLabel([qaLabel, BASE])}
      />
    </>
  );
};

export default memo(VideoPlaylist);
