import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { Action, Store, Dispatch } from "redux";
import { format, parseJSON } from "date-fns";
import tvgConf from "@tvg/conf";
import parseCapiMessage from "@tvg/utils/capiUtils";
import {
  AWPath,
  openAWPage
} from "@tvg/sh-lib-account-wallet/src/utils/AWPages";
import AccountMenuTemplate from "@fdr/atomic-ui/_templates/AccountMenu";
import PreferenceService from "@tvg/api/uam";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { AccountXSellProps } from "./types";
import { hasNewLink, isAndroid, supportOpenGtm } from "./utils";
import { useMenuRoutes, usePreferences } from "./hooks";

export const AccountMenu = ({
  firstName = "",
  lastName = "",
  accountNumber,
  userName,
  sessionStartAt,
  isBalanceShown,
  balance,
  bonus,
  sharedDeposits,
  sharedWinnings,
  setBalanceShown,
  menuRoutes,
  globalFDRSupportLink,
  globalFDRPrivacyPolicyLink,
  derbyGuide,
  isTutorialsLinkNew,
  doNotSell,
  feedbackUrl,
  isPreferencesShown,
  device,
  isBetConfirmationEnabled,
  dispatch,
  isTouchIdEnabled,
  onLogout,
  termsAndConditionsURL,
  rgMessagePerState,
  stateSelectoAbbr,
  xSellMigrationDate,
  talentPicks,
  watchFDTV,
  trackInformationList,
  enableNoLoginRequiredForAccountPage,
  isLogged,
  onTransactionHistoryClick,
  enablePreferencesCenter,
  enableURPEducationHub
}: AccountXSellProps) => {
  const { accountLinks, otherLinks } = useMenuRoutes({
    menuRoutes,
    globalFDRSupportLink,
    derbyGuide,
    globalFDRPrivacyPolicyLink,
    isTutorialsLinkNew,
    doNotSell,
    feedbackUrl,
    termsConditions: {
      url: termsAndConditionsURL || "",
      label: "Terms and Conditions"
    },
    xSellMigrationDate,
    accountNumber,
    talentPicks,
    watchFDTV,
    trackInformationList,
    onTransactionHistoryClick,
    enableNoLoginRequiredForAccountPage,
    enablePreferencesCenter,
    enableURPEducationHub
  });

  const onToggleShownBalance = () => {
    if (!accountNumber) {
      onOpenComplianceModal();
      return;
    }

    const newShownBalance = !isBalanceShown;
    PreferenceService.setUserPref(
      accountNumber,
      "balance_is_shown",
      Number(newShownBalance).toString()
    );

    setBalanceShown(newShownBalance);
  };

  const { toggleBetConfirmationPreference, toggleTouchId } = usePreferences({
    dispatch,
    accountNumber
  });

  const fullName = `${firstName} ${lastName}`;
  const { support } = menuRoutes || { support: "" };
  const deposits = {
    depositHandler: () => openAWPage(AWPath.Deposit),
    withdrawHandler: () => openAWPage(AWPath.Withdrawals)
  };

  const noLoginRequiredForAccountPage =
    enableNoLoginRequiredForAccountPage && !isXSell();

  return (
    <AccountMenuTemplate
      isBalanceShown={isBalanceShown}
      onToggleShownBalance={onToggleShownBalance}
      fullName={fullName}
      userName={userName || accountNumber}
      balancesSummary={[
        {
          label: "Playable balance",
          value: balance
        },
        {
          label: "Bonus",
          value: bonus
        }
      ]}
      balances={[
        {
          label: "Shared deposits",
          value: sharedDeposits
        },
        {
          label: "Racing bonuses",
          value: bonus
        },
        {
          label: "Shared winnings",
          value: sharedWinnings
        },
        {
          label: "Playable balance",
          value: balance,
          isHighlighted: true
        }
      ]}
      accountLinks={accountLinks}
      otherLinks={otherLinks}
      device={device}
      isPreferencesShown={isPreferencesShown && !enablePreferencesCenter}
      accountNumber={accountNumber}
      isBetConfirmationEnabled={isBetConfirmationEnabled === "1"}
      onBetConfirmationToggle={() => {
        if (accountNumber) {
          toggleBetConfirmationPreference(isBetConfirmationEnabled);
        } else {
          onOpenComplianceModal();
        }
      }}
      isAndroid={isAndroid(tvgConf().product)}
      isTouchIdEnabled={isTouchIdEnabled}
      onTouchIdToggle={() => toggleTouchId(!isTouchIdEnabled)}
      sessionStartAt={sessionStartAt}
      deposits={deposits}
      onLogout={onLogout}
      onSupport={supportOpenGtm}
      supportUrl={support}
      rgMessagePerState={
        stateSelectoAbbr &&
        rgMessagePerState &&
        rgMessagePerState[`${stateSelectoAbbr}`]
      }
      noLoginRequiredForAccountPage={noLoginRequiredForAccountPage}
      isLogged={isLogged}
    />
  );
};

const mapStateToProps = (
  store: Store
): Omit<
  AccountXSellProps,
  | "setBalanceShown"
  | "menuRoutes"
  | "feedbackUrl"
  | "isExternalDeposit"
  | "device"
  | "dispatch"
  | "onLogout"
  | "termsConditions"
  | "onTransactionHistoryClick"
> => ({
  firstName: get(store, "userData.user.firstName", ""),
  lastName: get(store, "userData.user.lastName", ""),
  accountNumber: get(store, "userData.user.accountNumber"),
  userName: get(store, "userData.user.userName"),
  balance: get(store, "userData.balance", 0),
  bonus: get(store, "userData.bonus", 0),
  sharedDeposits: get(store, "userData.sharedDeposits", 0),
  sharedWinnings: get(store, "userData.sharedWinnings", 0),
  sessionStartAt: get(store, "userData.sessionStartAt"),
  isBalanceShown:
    get(store, "userData.preferences.balance_is_shown", "0") !== "0",
  derbyGuide: {
    toggle: get(store, "capi.featureToggles.enableDerbyGuide", false),
    label: get(store, "capi.messages.derbyGuideLabel", ""),
    url: get(store, "capi.messages.derbyGuideLink", "")
  },
  trackInformationList: {
    label:
      parseCapiMessage(store, "capi.messages.tabsTracksPage")
        .tracksInformationListLink?.label ?? "Tracks Information List",
    url:
      parseCapiMessage(store, "capi.messages.tabsTracksPage")
        .tracksInformationListLink?.url ?? "/tracks/all-info"
  },
  globalFDRSupportLink: get(store, "capi.messages.globalFDRSupportLink", ""),
  globalFDRPrivacyPolicyLink: get(
    store,
    "capi.messages.globalFDRPrivacyPolicyLink",
    ""
  ),
  isTutorialsLinkNew:
    get(store, "mobileAccount.tutorialsList.length", 0) &&
    hasNewLink(get(store, "mobileAccount.tutorialsList", [])),
  doNotSell: {
    toggle: get(store, "capi.featureToggles.doNotSell", false),
    label: get(store, "capi.messages.doNotSellLabel", ""),
    url: get(store, "capi.messages.doNotSellLink", "")
  },
  isPreferencesShown: get(
    store,
    "capi.featureToggles.userBetPreferences",
    false
  ),
  isBetConfirmationEnabled: get(
    store,
    "userData.preferences.show_bet_confirm",
    "0"
  ),
  isTouchIdEnabled: get(store, "ios.touchId.touchIdEnabled", false),
  termsAndConditionsURL: get(
    store,
    "userData.jurisdiction.terms_and_conditions.url",
    ""
  ),
  rgMessagePerState: parseCapiMessage(
    store,
    "capi.messages.rgMessagePerState",
    {}
  ),
  stateSelectoAbbr: get(store, "app.selectedState", ""),
  xSellMigrationDate: format(
    parseJSON(
      get(store, "capi.messages.xSellMigrationDate", "2022-12-22T02:59:59.999Z")
    ),
    "LLL dd', 'u"
  ),
  talentPicks: {
    toggle: get(store, "capi.featureToggles.enableTalentPicksPage", false),
    label: get(store, "capi.messages.talentPicksLabel", "FDTV Picks"),
    url: ""
  },
  watchFDTV: {
    toggle: get(store, "capi.featureToggles.enableURPWatchFDTV", false),
    label: get(store, "capi.messages.watchFDTVLabel", "Watch FDTV"),
    url: ""
  },
  enableNoLoginRequiredForAccountPage: get(
    store,
    "capi.featureToggles.enableNoLoginRequiredForAccountPage",
    false
  ),
  isLogged: get(store, "userData.logged", false),
  enablePreferencesCenter: get(
    store,
    "capi.featureToggles.enablePreferencesCenter",
    false
  ),
  enableURPEducationHub: get(
    store,
    "capi.featureToggles.enableURPEducationHub",
    false
  )
});

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setBalanceShown: (isBalanceShown: boolean) => {
    dispatch({
      type: "USER_PREFS_BALANCE_SHOWN",
      payload: isBalanceShown ? "1" : "0"
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
