import React from "react";
import {
  Container,
  Columns,
  MainContent,
  SideContent,
  MobileHeader
} from "../../contents/styled-components";
import {
  PageTitleLoading,
  FAQLoading,
  RichTextLoading,
  BetTypesLoading,
  MobileHeaderLoading,
  TabsLoading
} from "./Components";

interface ArticlePageLoadingProps {
  isBetting?: boolean;
  isDesktop?: boolean;
}

export default function ArticlePageLoading({
  isBetting,
  isDesktop = true
}: ArticlePageLoadingProps) {
  return (
    <Container data-qa-label="education-hub-loading">
      {isDesktop ? (
        <PageTitleLoading />
      ) : (
        <MobileHeader>
          <MobileHeaderLoading />
          {isBetting && <TabsLoading />}
        </MobileHeader>
      )}

      <Columns isArticle hasMobileBottomSpace={false}>
        <MainContent>
          {isBetting ? <BetTypesLoading /> : <RichTextLoading />}
        </MainContent>

        <SideContent>
          <FAQLoading />
        </SideContent>
      </Columns>
    </Container>
  );
}
