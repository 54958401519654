import React, { Fragment } from "react";
import { LinkProps } from "react-router-dom";
import { get } from "lodash";
import { BannerProps } from "@urp/derby-banner/src/types";
import { Button, type ButtonVariant } from "@tvg/design-system/web";
import {
  Container,
  TopContainer,
  BannerImage,
  ButtonsContainerMobile,
  ButtonsContainerDesktop,
  BannerButton,
  TextContainer,
  DynamicContent,
  BannerImageContainer,
  StyledLink
} from "./styled-components";
import { CtaButton } from "./types";
import { RichTextParser } from "./RichTextParser";
import { useCountdown } from "./useCountdown";

export const DerbyBanner = ({
  raceStartDate,
  eventStatus,
  bannerCfg,
  onCtaClick,
  orientation = "portrait",
  qaLabel = "headerBanner"
}: BannerProps) => {
  const { endDateCountdown } = useCountdown({
    eventStatus,
    endDate: raceStartDate,
    forceFullDate: false
  });
  const hasDynamicText = !!get(bannerCfg, "dynamic_text.content.0.content");
  const hasCtaButtons = (bannerCfg?.cta_buttons?.length ?? 0) > 0;

  const renderCTA = (cta: CtaButton) => (
    <BannerButton key={cta.button_type}>
      <Button
        isStretched
        size="m"
        variant={cta.button_type as ButtonVariant}
        icon={cta.button_type === "promo" ? "optedIn" : undefined}
        onClick={() =>
          typeof onCtaClick === "function" &&
          onCtaClick(cta.label, cta.link_url?.url)
        }
        qaLabel={`${cta.button_type}-btn`}
      >
        {cta.label}
      </Button>
    </BannerButton>
  );

  const bannerImageURL = bannerCfg?.background_image_link?.url ?? null;
  const BannerImageLink = bannerImageURL ? StyledLink : Fragment;
  const bannerImageProps = (bannerImageURL
    ? { to: bannerImageURL, "data-qa-label": `${qaLabel}-link` }
    : {}) as unknown as LinkProps;

  if (!bannerCfg) {
    return null;
  }

  return orientation === "landscape" ? (
    <Container data-qa-label={qaLabel}>
      <TopContainer bg={bannerCfg.cta_buttons_bg_color}>
        <BannerImageContainer
          data-qa-label={`${qaLabel}-container`}
          $orientation="landscape"
          hasCTAs={hasCtaButtons}
        >
          <BannerImageLink {...bannerImageProps}>
            <BannerImage
              data-qa-label={`${qaLabel}-img`}
              src={bannerCfg.background_image?.filename}
              alt={bannerCfg.background_image?.alt}
            />
          </BannerImageLink>
        </BannerImageContainer>
        {hasCtaButtons && (
          <ButtonsContainerDesktop data-qa-label={`${qaLabel}-ctas`}>
            {bannerCfg.cta_buttons.map((cta) => renderCTA(cta))}
          </ButtonsContainerDesktop>
        )}
      </TopContainer>
      {hasDynamicText && (
        <DynamicContent>
          <TextContainer
            data-qa-label={`${qaLabel}-dynamicText`}
            bg={bannerCfg.dynamic_text_bg_color}
          >
            <RichTextParser
              content={bannerCfg.dynamic_text?.content}
              variables={{ event_date: endDateCountdown }}
            />
          </TextContainer>
        </DynamicContent>
      )}
    </Container>
  ) : (
    <Container data-qa-label={qaLabel}>
      <BannerImageContainer
        data-qa-label={`${qaLabel}-container`}
        $orientation="portrait"
      >
        <BannerImageLink {...bannerImageProps}>
          <BannerImage
            data-qa-label={`${qaLabel}-img`}
            src={bannerCfg.background_image?.filename}
            alt={bannerCfg.background_image?.alt}
          />
        </BannerImageLink>
      </BannerImageContainer>
      {(hasDynamicText || hasCtaButtons) && (
        <DynamicContent>
          {hasDynamicText && (
            <TextContainer
              data-qa-label={`${qaLabel}-dynamicText`}
              bg={bannerCfg.dynamic_text_bg_color}
            >
              <RichTextParser
                content={bannerCfg.dynamic_text?.content}
                variables={{ event_date: endDateCountdown }}
              />
            </TextContainer>
          )}

          {hasCtaButtons && (
            <ButtonsContainerMobile
              data-qa-label={`${qaLabel}-ctas`}
              bg={bannerCfg.cta_buttons_bg_color}
            >
              {bannerCfg.cta_buttons.map((cta) => renderCTA(cta))}
            </ButtonsContainerMobile>
          )}
        </DynamicContent>
      )}
    </Container>
  );
};

export default DerbyBanner;
