// @ts-nocheck
import pause from "./pause.svg";
import play from "./play.svg";
import rewind10 from "./rewind-10.svg";
import expand from "./expand.svg";
import volumeOn from "./volume-on.svg";
import volumeHalf from "./volume-half.svg";
import volumeOff from "./volume-off.svg";
import closedCaptions from "./closed-captions.svg";

export default {
  pause,
  play,
  rewind10,
  expand,
  volumeOn,
  volumeHalf,
  volumeOff,
  closedCaptions
};
