import React from "react";
import { BetTypesButton, BetTypesText } from "./styled-components";
import type { BetTypeButtonProps } from "./types";

export const BetTypeButton: React.FC<BetTypeButtonProps> = ({
  betType,
  isSelected = false,
  qaLabel = betType.code,
  onPress
}) => {
  const onClick = () => {
    onPress(betType.id);
  };

  return (
    <BetTypesButton
      onClick={onClick}
      isSelected={isSelected}
      data-qa-label={`${qaLabel}${isSelected ? "-active" : ""}`}
    >
      <BetTypesText isSelected={isSelected}>{betType.name}</BetTypesText>
    </BetTypesButton>
  );
};
