import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const AccountLinkBaseStyles = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 100%;

  &[disabled] {
    pointer-events: none;
    cursor: default;

    & > span {
      color: ${({ theme }) => theme.colorTokens.content.default};
    }
  }

  > svg {
    &:first-child {
      margin-right: 12px;
    }

    &:last-child {
      margin-left: 100%;
    }
  }
`;

export const IconWrapper = styled.div<{ auto?: boolean }>`
  ${({ auto }) => `margin-left: ${auto ? "auto" : "0px"}`};
`;

export const AccountLinkGroup = styled(Link)`
  ${AccountLinkBaseStyles};

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;

AccountLinkGroup.displayName = "AccountLinkGroup";

export const AccountExternalLink = styled.a`
  ${AccountLinkBaseStyles};
`;

AccountExternalLink.displayName = "AccountExternalLink";

export const LinkText = styled.span`
  font-size: var(--fd-font-sizes-font-size-040);
  color: var(--fd-colors-link-default-base);
  line-height: var(--fd-line-heights-line-height-030);
  display: flex;
  align-items: center;
`;

export const LinkDeskWrapper = styled.div`
  ${AccountLinkBaseStyles}
  cursor: pointer;
`;
