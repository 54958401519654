import React, { FC, memo } from "react";
import { Default, RaceOff, Resulted, Mtp } from "./components";
import type { MtpCellProps } from "./types";

export const MtpCell: FC<MtpCellProps> = memo(
  ({
    mtp,
    status,
    postTime,
    withBackground = true,
    trackCode,
    raceNumber,
    showMtp = false,
    align = "left",
    qaLabel = "mtp-cell",
    ...rest
  }) => {
    if (!showMtp) {
      return (
        <Default
          withBackground={withBackground}
          align={align}
          qaLabel={qaLabel}
          {...rest}
        />
      );
    }

    if (status === "SK") {
      return (
        <RaceOff
          withBackground={withBackground}
          align={align}
          qaLabel={qaLabel}
          trackCode={trackCode}
          raceNumber={raceNumber}
          {...rest}
        />
      );
    }

    if (status === "RO") {
      return (
        <Resulted
          withBackground={withBackground}
          align={align}
          qaLabel={qaLabel}
          trackCode={trackCode}
          raceNumber={raceNumber}
          {...rest}
        />
      );
    }

    return (
      <Mtp
        mtp={mtp}
        postTime={postTime}
        trackCode={trackCode}
        raceNumber={raceNumber}
        withBackground={withBackground}
        align={align}
        qaLabel={qaLabel}
        {...rest}
      />
    );
  }
);

export type { MtpCellProps };
