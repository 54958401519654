import React from "react";
import { Paragraph } from "@tvg/design-system/web";
import { Container, Wrapper, Content } from "./styled-components";

interface PayoutTagProps {
  isYellow: boolean;
}

const PayoutTag = ({ isYellow }: PayoutTagProps) => (
  <Container
    data-qa-label={
      isYellow ? "bet-type-yellow-payout-item" : "bet-type-payout-item"
    }
  >
    <Wrapper isYellow={isYellow}>
      <Content>
        <Paragraph
          fontFamily="condensedRegular"
          fontSize="xs"
          color="--fd-colors-content-subtle"
          textTransform="uppercase"
          qaLabel="bet-type-payout-item-text"
        >
          $
        </Paragraph>
      </Content>
      <Content isYellow={isYellow} isBack>
        <Paragraph
          fontFamily="condensedRegular"
          fontSize="xs"
          color={
            isYellow
              ? "--fd-colors-system-alert-content-accent"
              : "--fd-colors-content-subtle"
          }
          textTransform="uppercase"
          qaLabel="bet-type-payout-item-text"
        >
          $
        </Paragraph>
      </Content>
    </Wrapper>
  </Container>
);

export default PayoutTag;
