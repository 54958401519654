import { NullaryFn } from "./Functional";
import { WagerType, WagerTypeCodesEnum } from "./Wager";
import { Talent } from "./Talent";
import { TalentPickData } from "./TalentPicks";
import { TopPool } from "./Pool";

export type RaceStatusTypeCode = "SK" | "RO" | "O" | "IC";

export interface RaceStatusType {
  code: RaceStatusTypeCode;
}

export enum RaceStatusEnum {
  OPEN = "O",
  UP_NEXT = "IC",
  RACE_OFFICIAL = "RO",
  RACE_OFF = "SK",
  MANUALLY_OPENED = "MO",
  MANUALLY_CLOSED = "MC"
}

export type RaceLegStatus =
  | "open"
  | "raceOff"
  | "win"
  | "lost"
  | "pending"
  | "attention"
  | "unknown";

export type RaceSurfaceEnum = "Dirt";

export type RaceTypeEnum = "Thoroughbred" | "Harness";

export enum RaceTypeCodeEnum {
  ALL = "ALL",
  THOROUGHBRED = "T",
  HARNESS = "H",
  GREYHOUNDS = "G",
  TB_LARC = "L",
  QTR_HORSE = "Q"
}

export enum RaceDistanceCodeEnum {
  FURLONG = "F",
  METERS = "Mtr",
  MILE = "M",
  YARD = "Y"
}

export type RaceClassEnum = "Maiden";

export interface RaceOdds {
  denominator?: number | null;
  numerator?: number;
}

export interface RaceStatus {
  code: RaceStatusEnum;
}

export interface TalentPickDataRunners {
  order: number;
}

export interface RaceSurface {
  name: RaceSurfaceEnum;
  shortName?: string;
  defaultCondition?: string;
}

export interface RaceType {
  name: RaceTypeEnum;
  code: RaceTypeCodeEnum;
  shortName?: string;
}

export interface RaceTypeCode {
  code: RaceTypeCodeEnum;
}

export interface RaceClass {
  name: RaceClassEnum;
  shortName?: string;
}

export interface RacePool {
  amount: number;
  wagerType: WagerType;
}

export interface PoolRunnerData {
  amount: number;
}
export interface BettingInterestPool {
  wagerType: WagerType;
  poolRunnersData: Array<PoolRunnerData>;
}

export interface BettingInterestPoolsRunners {
  runnerId: string;
  scratched: boolean;
}

export interface BettingInterestsPools {
  biNumber: number;
  runners: Array<BettingInterestPoolsRunners>;
  biPools: Array<BettingInterestPool> | null;
}

export interface WillpayLegResult {
  legNumber: number;
  winningBi: number;
}

export interface WillpayPayout {
  bettingInterestNumber: number;
  payoutAmount: number;
}

export interface Willpay {
  type: WagerType;
  wagerAmount: number;
  legResults: Array<WillpayLegResult>;
  payouts: Array<WillpayPayout>;
}

export interface ProbableBetCombo {
  runner1: string;
  runner2: string | null;
  payout: number;
}

export interface Probable {
  amount: number;
  minWagerAmount: number;
  wagerType: WagerType;
  betCombos: Array<ProbableBetCombo>;
}

export interface DistanceValues {
  value: string;
  code: string;
  name: string;
  shortName: string;
}

export interface Distance {
  date: string;
  newValue: DistanceValues;
  oldValue: DistanceValues;
}

export interface Change {
  date: string;
  newValue: string;
  oldValue: string;
}

export interface HorseChange {
  runnerId: string;
  horseName: string;
  date: string;
  scratched: boolean;
  reason: string;
}

export interface JockeyChange {
  runnerId: string;
  horseName: string;
  date: string;
  oldValue: string;
  newValue: string;
}

export interface SurfaceChanges {
  course?: Array<Change>;
  tempRailDistance?: Array<Change>;
  condition?: Array<Change>;
  distance?: Array<Distance>;
}

export interface HorseChanges {
  scratched: Array<HorseChange>;
  jockey: Array<JockeyChange>;
}

export interface Changes {
  surface: SurfaceChanges;
  horse: HorseChanges | null;
}

export interface HandicappingSpeedAndClass {
  avgClassRating: number;
  highSpeed: number;
  avgSpeed: number;
  lastClassRating: number;
  avgDistance: number;
}

export interface HandicappingAveragePace {
  finish: number;
  numRaces: number;
  middle: number;
  early: number;
}

export interface HandicappingJockeyTrainer {
  places: number;
  jockeyName: string;
  trainerName: string;
  shows: number;
  wins: number;
  starts: number;
}

export interface HandicappingSnapshot {
  powerRating?: number;
  daysOff?: number;
  horseWins?: number;
  horseStarts?: number;
}

export interface HandicappingFreePick {
  number?: number;
  info?: string;
}
export interface TimeFormFreePick {
  number?: number;
  info?: string;
}

export interface HandicappingPastResults {
  numberOfFirstPlace: number;
  numberOfSecondPlace: number;
  numberOfThirdPlace: number;
  totalNumberOfStarts: number;
  top3Percentage: number;
  winPercentage: number;
}

export interface Handicapping {
  speedAndClass: HandicappingSpeedAndClass;
  averagePace: HandicappingAveragePace;
  jockeyTrainer: HandicappingJockeyTrainer;
  snapshot: HandicappingSnapshot;
  freePick: HandicappingFreePick;
  pastResults: HandicappingPastResults;
}

export interface HandicapOptions {
  key?: string;
  value: string | number;
  sorting?: string;
}

export interface RaceTimeform {
  analystVerdict?: string;
}

export interface Timeform {
  silkUrl?: string;
  silkUrlSvg?: string;
  freePick?: TimeFormFreePick;
  analystsComments?: string;
  postRaceReport?: string | null;
  accBeatenDistance?: string | null;
  accBeatenDistanceStatus?: string | null;
  winningDistance?: string | null;
  winningDistanceStatus?: string | null;
  flags?: RunnerFlags;
}

export interface Runner {
  age?: number;
  horseName: string;
  jockey: string;
  med?: string;
  runnerId: string;
  scratched: boolean;
  sex?: string;
  trainer: string;
  weight?: number;
  ownerName?: string;
  sire?: string;
  damSire?: string;
  dam?: string;
  handicapping?: Handicapping;
  dob?: number;
  hasJockeyChanges?: boolean;
  timeform?: Timeform;
  finishPosition?: number;
  runnerName?: string;
  runnerNumber?: number;
  biNumber?: number;
  entityRunnerId?: string;
}

export interface ResultRunner {
  betAmount: number;
  biNumber: number;
  finishPosition: number;
  placePayoff: number;
  runnerNumber: string;
  runnerName: string;
  showPayoff: number;
  winPayoff: number;
  timeform?: Timeform | null;
  favorite?: boolean;
}

export interface RunnerFlags {
  horseInFocus: boolean;
  warningHorse: boolean;
  jockeyUplift: boolean;
  trainerUplift: boolean;
  horsesForCoursePos: boolean;
  horsesForCourseNeg: boolean;
  hotTrainer: boolean;
  coldTrainer: boolean;
  highestLastSpeedRating: boolean;
  sectionalFlag: boolean;
  significantImprover: boolean;
  jockeyInForm: boolean;
  clearTopRated: boolean;
  interestingJockeyBooking: boolean;
  firstTimeBlinkers: boolean;
}

export interface FullResultRunner {
  runnerId: string;
  runnerName: string;
  trainer: string;
  jockey: string;
  scratched: boolean;
  finishPosition: number;
  accBeatenDistance?: string;
  accBeatenDistanceStatus?: string;
  postRaceReport?: string;
  odds?: RaceOdds;
}

export interface PayoffsSelection {
  payoutAmount: number;
  selection: string;
}

export interface PayoffsWagerType {
  code: string;
  name: string;
}

export interface Payoffs {
  selections: Array<PayoffsSelection>;
  wagerAmount: number;
  wagerType: PayoffsWagerType;
}

export interface Results {
  payoffs: Array<Payoffs>;
  runners: Array<ResultRunner> | undefined | null;
  winningTime?: number | string;
}

export interface RaceProgramRaceVideo {
  liveStreaming: boolean;
  onTvg: boolean;
  onTvg2: boolean;
  streams: string[];
  hasReplay: boolean;
  replayFileName: string;
  mobileAvailable: boolean;
  isStreamHighDefinition?: boolean;
}

export interface RecentOdds {
  odd: string;
  trending?: boolean;
}

export interface BettingInterest {
  biNumber: number;
  currentOdds: RaceOdds;
  morningLineOdds: RaceOdds;
  recentOdds?: RecentOdds[];
  numberColor: string;
  runners: Runner[];
  saddleColor: string;
  isFavorite: boolean;
  favorite?: boolean;
  biPools?: Array<BettingInterestPool> | null;
}

export interface PickBetFavorite {
  runnerNumber: string;
  runnerName: string;
  isWinner: boolean;
  isFavorite: boolean;
}

export interface FavoriteRunners {
  [key: number]: PickBetFavorite;
}

export interface RaceCardRunner {
  age?: number;
  horseName: string;
  jockey?: string;
  med?: string;
  runnerId: string;
  scratched: boolean;
  sex?: string;
  trainer?: string;
  weight?: number;
  ownerName?: string;
  sire?: string;
  damSire?: string;
  dam?: string;
  handicapping?: Array<HandicapOptions | string>;
  masterPickNumber?: number;
  masterPickInfo?: string;
  dob?: number;
  hasJockeyChanges?: boolean;
  timeform?: Timeform;
}

export interface RaceCardBettingInterest {
  biNumber: number;
  currentOdds: RaceOdds;
  recentOdds?: RecentOdds[];
  morningLineOdds: RaceOdds;
  isFavorite: boolean;
  runners: RaceCardRunner[];
  numberColor?: string;
  saddleColor?: string;
}

export type RunnerTagsModalProps = {
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  runnerTags?: { [key: string]: any }[];
  linkedRunner: {
    raceTypeCode?: string;
    runnerId?: string;
    horseName?: string;
  };
  currentRunnerTags?: string[];
  trackName?: string;
  raceNumber?: string;
  concatRunnerTags?: string;
};

export enum HandicapOptionsType {
  BASIC = "basic",
  ADVANCED = "advanced",
  SNAPSHOT = "snapshot",
  SPEED = "speed",
  PACE = "pace",
  STATUS = "stats"
}

export enum HandicapSortOptionsType {
  SADDLECLOTH = "saddlecloth",
  ODDS = "odds",
  AGE = "age",
  MED = "med",
  WEIGHT = "weight",
  POWER = "power",
  WIN_STARTS = "winStarts",
  DAYS_OFF = "daysOff",
  AVG_SPEED = "avgSpeed",
  AVG_DISTANCE = "avgDistance",
  HIGH_SPEED = "highSpeed",
  AVG_CLASS_RATING = "avgClassRating",
  LAST_CLASS_RATING = "lastClassRating",
  NUM_RACES = "numRaces",
  EARLY = "early",
  MIDDLE = "middle",
  FINISH = "finish",
  STARTS = "starts",
  WINS = "wins",
  PLACES = "places",
  SHOWS = "shows"
}

export interface Stat {
  value: string | number;
  isPercent?: boolean;
  everBold?: boolean;
  description: string;
}

export interface HandicappingSortModesInfo {
  basic: HandicapSortOptionsType;
  advanced: HandicapSortOptionsType;
  snapshot: HandicapSortOptionsType;
  speed: HandicapSortOptionsType;
  pace: HandicapSortOptionsType;
  stats: HandicapSortOptionsType;
}

export interface RaceWagerTypeGroup {
  id: number;
  name: string;
  code: WagerTypeCodesEnum;
  description?: string;
  descriptionInternational?: string;
}

export interface RaceWagerType {
  columnCount: number;
  isBox: boolean;
  isKey: boolean;
  isWheel: boolean;
  legCount: number;
  minWagerAmount: number;
  positionCount: number;
  group: RaceWagerTypeGroup;
  type: WagerType;
  specialGroup?: boolean;
  types?: RaceWagerType[];
  wagerAmounts: number[];
}

export interface TrackLocation {
  country: string;
  state?: string;
}

export interface RaceProgramRaceTrack {
  featured: boolean;
  numberOfRaces: number;
  trackCode: string;
  code?: string;
  shortName?: string;
  trackLocation: TrackLocation;
  trackName: string;
  trackDataSource?: string;
  perfAbbr?: string;
}

export interface RaceHighlighted {
  description: string;
  style: string;
}

export interface Promo {
  rootParentPromoID: number;
  isAboveTheLine: boolean;
  promoPath: string;
  isOptedIn?: boolean;
  isPromoTagShown?: boolean;
}

export interface RaceListItem {
  value: string | number;
  changed: boolean;
  changes: Change[];
  changeKeyword: string;
}

export interface RaceProgram {
  id?: string;
  tvgRaceId?: number;
  bettingInterests?: BettingInterest[];
  mtp?: number;
  postTime?: string;
  status?: RaceStatus;
  racePools?: RacePool[];
  track?: RaceProgramRaceTrack;
  raceNumber?: string;
  highlighted?: RaceHighlighted;
  description?: string;
  distance?: string;
  purse?: number;
  numRunners?: string;
  numWagerableRunners?: string;
  surface?: RaceSurface;
  type?: RaceType;
  raceClass?: RaceClass;
  wagerTypes?: RaceWagerType[];
  video?: RaceProgramRaceVideo;
  isLoading?: boolean;
  results?: Results;
  wagerable?: boolean;
  promo?: Promo;
  promos?: Promo[];
  changes?: Changes;
  raceDate?: string;
  number?: string;
  willPays?: Willpay[];
  probables?: Probable[];
  runnersPools?: BettingInterestsPools[];
  claimingPrice?: number;
  timeform?: RaceTimeform;
  talentPicks?: TalentPickData[];
  value?: string;
}

export interface RaceInfo {
  country: string;
  isGreyhound: boolean;
  mtp: number;
  postTime: string;
  raceId: string;
  raceNumber: string;
  raceStatus: RaceStatus;
  trackAbbr: string;
  trackName: string;
  trackShortName: string;
  typeCode: RaceTypeCode;
  onTvg: boolean;
  onTvg2: boolean;
  promo: Promo;
}

export interface UserPromotions {
  optedIn: boolean;
  isAboveTheLine?: boolean;
}

export interface RaceTrack {
  code: string;
  name: string;
  perfAbbr?: string;
  location?: TrackLocation;
  hasAboveTheLinePromo?: boolean;
}

export interface RaceVideo {
  onTvg: boolean;
  onTvg2: boolean;
  hasReplay?: boolean;
  liveStreaming?: boolean;
  streams?: string[];
}

export interface RaceDistance {
  code?: RaceDistanceCodeEnum;
  name?: string;
  shortName?: string;
  value?: number;
}

export interface Race {
  id: string;
  raceNumber: string;
  number: string;
  track: RaceTrack;
  mtp: number;
  postTime: string;
  isGreyhound: boolean;
  status: RaceStatus;
  type: RaceTypeCode;
  video: RaceVideo;
  highlighted?: RaceHighlighted;
  bettingInterests?: BettingInterest[];
  numRunners?: number;
  promos?: Promo[];
  tvgRaceId?: number;
  raceDistance?: RaceDistance;
  pools?: Pick<TopPool, "amount">[];
}

export interface TalentPickGraphData {
  id: string;
  talent: Talent;
  race: Race;
  wagerType: WagerType;
  track?: string;
  amount: string;
  price: string;
  selections: TalentPickDataRunners[][];
  favorites: TalentPickDataRunners[][];
}

export interface RacePanelLinkBets {
  count: number;
}

export interface RacePanelLink {
  country: string;
  runnerNumber: string;
  raceNumber?: string;
  isGreyhound: boolean;
  mtp: number;
  raceId: string;
  trackName: string;
  trackCode: string;
  postTime: string;
  promos?: Promo[];
  userPromotions?: UserPromotions[];
  bets?: RacePanelLinkBets;
  talentPicks: TalentPickGraphData[];
  bettingInterests?: RaceCardBettingInterest[];
  highlighted?: RaceHighlighted;
}

export interface UpcomingGraphRaceRaceStatus {
  code: RaceStatusEnum;
}

export interface UpcomingGraphRaceLocation {
  country: string;
}

export interface UpcomingGraphRaceHighlighted {
  description: string;
  style: string;
}

export interface UpcomingGraphRacePromo {
  isAboveTheLine: boolean;
  rootParentPromoID: number;
}

export interface UpcomingGraphRaceTalentPicks {
  id: string;
}

export type UpcomingGraphRace = {
  raceId: string;
  mtp: number;
  postTime: string;
  raceNumber: string;
  trackName: string;
  trackCode: string;
  raceStatus: UpcomingGraphRaceRaceStatus;
  location: UpcomingGraphRaceLocation;
  isGreyhound: boolean;
  highlighted: UpcomingGraphRaceHighlighted;
  promos: UpcomingGraphRacePromo;
  talentPicks: UpcomingGraphRaceTalentPicks;
};

export type UpcomingGraphRaces =
  | Array<UpcomingGraphRace>
  | Array<{ currentRace: UpcomingGraphRace }>;

export interface UpcomingGraphRacesResponseData {
  upcomingRaces: UpcomingGraphRaces;
  extraRaces: UpcomingGraphRaces;
  loading: boolean;
}

export interface UpcomingGraphRacesResponse {
  data: UpcomingGraphRacesResponseData;
}

export enum ImportanceType {
  NO_LEVEL = "",
  LEVEL_0 = "0",
  LEVEL_1 = "1",
  LEVEL_2 = "2",
  LEVEL_3 = "3"
}

export interface RaceInfoNav extends RaceInfo {
  importanceLevel?: ImportanceType;
  description?: string;
  action?: string;
  pinnedOrder?: string;
}

export interface RaceAbbr {
  trackCode: string;
  trackName: string;
  raceStatus: RaceStatusEnum;
  mtp: number;
  postTime: string;
  number: string;
  hasBets: boolean;
  isGreyhound: boolean;
  hasResults?: boolean;
  showTrackCode: boolean;
  promo: Promo;
}

export interface RaceNavigationType {
  mtp: number;
  postTime: string;
  status: RaceStatusEnum;
  isGreyhound: boolean;
  trackName: string;
  trackAbbr: string;
  raceNumber: string;
  isSmall?: boolean;
  onClickCallback: NullaryFn<void>;
}

export interface PastPerformanceRaceTrack {
  name: string;
  code: string;
}

export interface PastPerformanceRaceSurface {
  shortName: string;
  defaultCondition: string;
}

export interface PastPerformanceRaceClass {
  shortName: string;
}

export interface PastPerformanceRace {
  id: string;
  raceDate: string;
  date: string;
  raceNumber: string;
  number: string;
  track: PastPerformanceRaceTrack;
  raceTypeCode: RaceTypeCodeEnum;
  hasReplays: boolean;
  replayFileName?: string;
  distance: string;
  surface: PastPerformanceRaceSurface;
  raceClass: PastPerformanceRaceClass;
  purse: number;
  numRunners: number;
  handicappingData?: string[];
  finishPosition?: number;
  scratched?: boolean;
  odds?: RaceOdds;
  postRaceReport?: string;
  accBeatenDistance?: string;
  accBeatenDistanceStatus?: string;
  favorite?: boolean;
}

export interface PastRaceBettingInterests {
  biNumber: number;
  favorite: boolean;
  currentOdds?: RaceOdds;
  morningLineOdds?: RaceOdds;
  runners: Runner[];
  numberColor?: string;
  saddleColor?: string;
}

export interface PastRace {
  id?: string;
  raceId?: string;
  bettingInterests: PastRaceBettingInterests[];
  results?: Results;
}

export interface VideoSRC {
  sd?: string;
  hd?: string;
  replay?: string;
}

export interface Video {
  show: boolean;
  error: boolean;
  src: VideoSRC;
}

export enum RaceVideoFeedback {
  NOT_AVAILABLE_REPLAY = "NOT_AVAILABLE_REPLAY",
  STARTING_SOON_REPLAY = "STARTING_SOON_REPLAY",
  FINISHED_REPLAY = "FINISHED_REPLAY",
  AVAILABLE_REPLAY = "AVAILABLE_REPLAY",
  NOT_AVAILABLE_NO_REPLAY = "NOT_AVAILABLE_NO_REPLAY",
  STARTING_SOON_NO_REPLAY = "STARTING_SOON_NO_REPLAY",
  FINISHED_NO_REPLAY = "FINISHED_NO_REPLAY",
  AVAILABLE_NO_REPLAY = "AVAILABLE_NO_REPLAY",
  SHOWN_ON_NBC = "SHOWN_ON_NBC"
}

export interface ExpandLineArgs {
  runnerId: string;
  selection: string;
  column: number;
  fromIcon: boolean;
  direction: string;
  isScratched: boolean;
}

export interface RaceTemplate {
  trackName: string;
  trackCode: string;
  number: string;
  raceStatus: RaceStatusEnum;
  hasBets: boolean;
  hasResults?: boolean;
  postTime: string;
  mtp: number;
  showTrackCode: boolean;
  isGreyhound: boolean;
  promo: Promo;
}

export interface CustomRaceAbbr extends RaceAbbr {
  id?: string;
  idPrefix?: string;
}

export interface RaceInfoMyBetsRunner {
  biNumber: number;
  finishPosition: number;
  betAmount: number;
}

export interface RaceInfoMyBetsResults {
  runners?: RaceInfoMyBetsRunner[];
  payoffs?: Array<Payoffs>;
  winningTime?: number | string;
}

export interface RaceInfoMyBetsStatus {
  code: RaceStatusEnum;
}

export interface RaceInfoMyBetsVideo {
  liveStreaming: boolean;
  hasReplay: boolean;
}

export interface RaceInfoMyBets {
  id: string;
  raceId?: string;
  tvgRaceId: number;
  postTime: string;
  raceDate: string;
  probables?: Probable[];
  bettingInterests: BettingInterest[];
  trackName: string;
  trackCode: string;
  isGreyhound: boolean;
  mtp: number;
  number: number;
  results?: RaceInfoMyBetsResults;
  willPays?: Willpay[];
  status: RaceInfoMyBetsStatus;
  video: RaceInfoMyBetsVideo;
  wagerTypes: RaceWagerType[];
  type?: RaceType;
  numRunners: string;
}

export interface MTPNotificationConfigType {
  mtpBelowThresholdText: string;
  raceOffText: string;
  mtpThreshold: number;
  hideNotificationDelay: number;
  showNotificationDelay: number;
}

export interface RaceBet {
  id: string; // this is a string because WRO uses a serial number string to identify bets
  raceTypeCode: RaceTypeCodeEnum;
  betTotal: number;
  wagerAmount: number;
  wagerType: WagerType;
  isKey: boolean;
  isLeg: boolean;
  selections: Array<Array<string>>;
  onDelete?: NullaryFn<unknown>;
  isCancelable?: boolean;
  isLost?: boolean;
  isRepeatable?: boolean;
  isPromoQualifying?: boolean;
  hasRepeatButton?: boolean;
  hasRepeaterEnabled?: boolean;
  repetitionsNr?: number;
  isCancelled?: boolean;
  isRefunded?: boolean;
  totalWinAmount?: number;
  onRepeatClick?: NullaryFn<void>;
}

export interface RaceBetGroup {
  postTime: string;
  mtp: number;
  raceNumber: string;
  trackName: string;
  status: RaceStatus;
  isGreyhound: boolean;
  showRaceDate?: boolean;
  bets: RaceBet[];
  raceDate: string;
  trackCode: string;
}

export interface PastRaceResults {
  id: string;
  number: string;
  description: string;
  purse: number;
  date: string;
  postTime: string;
  track: {
    code: string;
    name: string;
  };
  surface: {
    code: string;
    name: string;
  };
  distance: {
    value: number;
    code: string;
    name: string;
  };
  type: {
    id: number;
    code: string;
    name: string;
  };
  raceClass: {
    code: string;
    name: string;
  };
  video: {
    replayFileName: string;
  };
  results: Results;
}
