import React, { memo } from "react";
import { useSelector } from "react-redux";
import { RaceCell, Icon } from "@tvg/design-system/web";
import buildRaceUrl from "@tvg/formatter/url";
import { Race } from "@tvg/ts-types/Race";
import {
  getActiveBets,
  getOpenTracks
} from "@urp/lib-racetracks/src/redux/selectors";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { get } from "lodash";
import { isTrackOpen } from "@urp/lib-racetracks/src/utils/races";
import Collapsible from "../Collapsible";
import CollapsibleSummary from "../CollapsibleSummary";
import { Props } from "./types";
import { LETTER_BLOCK, LIST, RACE } from "../../utils/constants";
import { ScrollButton, RaceListContainer } from "./styled-components";
import { useRaceScroll } from "../../hooks/useRaceScroll";

const CollapsibleWrapper = ({
  track,
  onTrackClickHandler,
  module,
  qaLabel = "",
  isResults = false,
  checkRacePromos,
  onRaceCellClick
}: Props) => {
  const openTracks = useSelector(getOpenTracks);
  const activeBets = useSelector(getActiveBets);
  const races = track.races as Race[] | undefined;
  const isOpen = isTrackOpen(openTracks, track.code) && !!races;
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);

  const {
    isNextEnabled,
    isPrevEnabled,
    containerRef: racesRef,
    handleScroll
  } = useRaceScroll({ races, isOpen, hasNavigationButtons: isDesktop });

  const renderRaceCell = (race: Race) => {
    const raceUrl = buildRaceUrl(
      race.track.code,
      race.track.name,
      +race.number
    );

    const { hasPromoAvailable, isOptedInPromo } = checkRacePromos(race.promos);

    return (
      <RaceCell.Summary
        key={race.id}
        race={race}
        qaLabel={buildQaLabel([qaLabel, LETTER_BLOCK, race.id])}
        to={raceUrl}
        hasPromosAvailable={hasPromoAvailable}
        isOptedIn={isOptedInPromo}
        numberActiveBets={get(activeBets, race?.tvgRaceId ?? "", 0)}
        onClick={() => onRaceCellClick?.(race, track, module)}
      />
    );
  };

  return (
    <Collapsible
      key={track.code}
      summary={
        <CollapsibleSummary
          trackCode={track.code}
          trackName={track.name}
          isResulted={track.isFinished}
          hasPromo={track.hasAboveTheLinePromo}
          qaLabel={qaLabel}
          module={module}
        />
      }
      isOpen={isOpen}
      onClick={() => onTrackClickHandler(track.code, isResults)}
      qaLabel={buildQaLabel([qaLabel, track.code])}
    >
      {isDesktop && (
        <ScrollButton
          data-qa-label={buildQaLabel([qaLabel, "prev", "button"])}
          onClick={() => handleScroll("prev")}
          disabled={!isPrevEnabled}
          hasMarginLeft
        >
          <Icon name="arrowLeft" size="s" />
        </ScrollButton>
      )}

      <RaceListContainer
        ref={racesRef}
        hasScrollButtons={isDesktop}
        data-qa-label={buildQaLabel([qaLabel, RACE, LIST])}
      >
        {isOpen && races?.map((race: Race) => renderRaceCell(race))}
      </RaceListContainer>

      {isDesktop && (
        <ScrollButton
          data-qa-label={buildQaLabel([qaLabel, "next", "button"])}
          onClick={() => handleScroll("next")}
          disabled={!isNextEnabled}
          hasMarginRight
        >
          <Icon name="arrowRight" size="s" />
        </ScrollButton>
      )}
    </Collapsible>
  );
};

export default memo(CollapsibleWrapper);
