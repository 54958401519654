/* eslint-disable */
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import fetch from "node-fetch";
import { policies } from "@tvg/utils/apolloPolicies";
import { attempt, get } from "lodash";
import { WebSocketLink } from "@apollo/client/link/ws";
import TVGConf from "@tvg/conf";

const getClientURI = (graphClient, useCosmo) => {
  let uri = "";

  switch (graphClient) {
    case "fcp":
      uri = TVGConf().config().service.fcp;
      break;
    case "rda":
      uri = TVGConf().config().service.rda;
      break;
    case "gas":
      uri = TVGConf().config().service.gas;
      break;
    case "behg":
      uri = TVGConf().config().service.behg;
      break;
    default:
      uri = useCosmo
        ? TVGConf().config().service.cosmo
        : TVGConf().config().service.fed;
      break;
  }

  return uri;
};

const getClientWsUri = (graphClient, useCosmo) => {
  let uri = "";

  switch (graphClient) {
    case "graph":
      uri = useCosmo
        ? TVGConf().config().service.cosmoWS
        : TVGConf().config().service.fedWS;
      break;
    case "behg":
      uri = TVGConf().config().service.behgWS;
      break;
    case "gas":
      uri = TVGConf().config().service.gasWS;
      break;
    default:
      uri = "";
      break;
  }

  return uri.replace("https:", "").replace("http:", "").replace("//", "wss://");
};

export default {
  getClient(graphClient = "graph") {
    switch (graphClient) {
      case "rda":
        return this.rdaClient;
      case "fcp":
        return this.fcpClient;
      case "gas":
        return this.gasClient;
      case "behg":
        return this.behgClient;
      default:
        return this.graphClient;
    }
  },

  setClient(client, type) {
    switch (type) {
      case "rda":
        this.rdaClient = client;
        break;
      case "fcp":
        this.fcpClient = client;
        break;
      case "gas":
        this.gasClient = client;
        break;
      case "behg":
        this.behgClient = client;
        break;
      default:
        this.graphClient = client;
        break;
    }
  },

  createClient(ssrMode = false, graphClient = "graph", useCosmo = false) {
    const httpLink = createHttpLink({
      // @ts-ignore
      fetch: typeof window === "undefined" ? fetch : window.fetch,
      uri: getClientURI(graphClient, useCosmo),
      credentials: "include",
      headers: {
        "x-tvg-context": TVGConf().context()
      }
    });

    const retryLink = new RetryLink({
      attempts: () => true,
      delay: (count) => {
        if (count < 25) {
          return 2000 * Math.random();
        }
        return 15000;
      }
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Operation: ${operation.operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
        //return forward(operation);
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`); // eslint-disable-line no-console
      }
    });

    let link;
    let wsLink;
    if (typeof window === "undefined") {
      link = errorLink.concat(httpLink);
    } else {
      const wsUri = getClientWsUri(graphClient, useCosmo);

      if (wsUri !== "") {
        if (useCosmo) {
          let activeSocket, timedOut;
          wsLink = new GraphQLWsLink(
            createClient({
              url: wsUri,
              keepAlive: 20_000, // ping server every 10 seconds
              on: {
                connected: (socket) => (activeSocket = socket),
                ping: (received) => {
                  if (!received)
                    // sent
                    timedOut = setTimeout(() => {
                      if (activeSocket.readyState === WebSocket.OPEN)
                        activeSocket.close(4408, "Request Timeout");
                    }, 5_000); // wait 5 seconds for the pong and then close the connection
                },
                pong: (received) => {
                  if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
                }
              },
              error: (err) => console.log(err, "ERROR")
            })
          );
        } else {
          wsLink = new WebSocketLink({
            // @ts-ignore
            fetch: typeof window === "undefined" ? fetch : window.fetch,
            uri: wsUri,
            options: {
              reconnect: graphClient !== "gas",
              lazy: true,
              minTimeout: 5000
            },
            credentials: "include",
            headers: { "x-tvg-context": TVGConf().context() }
          });
          // hack for subscription error
          attempt(() => {
            if (wsLink !== undefined) {
              wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
                get(wsLink, "subscriptionClient.maxConnectTimeGenerator.max");
            }
          });
        }
      }

      const tmplink = wsLink
        ? split(
            // split based on operation type
            ({ query }) => {
              const definition = getMainDefinition(query);
              return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
              );
            },
            wsLink,
            httpLink
          )
        : httpLink;

      link = retryLink.concat(errorLink).concat(tmplink);
    }

    const cache = new InMemoryCache(policies);

    if (typeof window !== "undefined") {
      // @ts-ignore
      cache.restore(window.__APOLLO_STATE__); // eslint-disable-line no-underscore-dangle
    }

    const client = new ApolloClient({
      link,
      cache,
      // @ts-ignore
      addTypename: true,
      connectToDevTools: true,
      ssrMode
    });

    this.setClient(client, graphClient);

    if (wsLink) {
      return {
        ...client,
        /* eslint-disable */
        subscriptionClient: wsLink?.subscriptionClient,
        subscriptionCosmoClient: wsLink?.client,
        subscribe: client.__proto__.subscribe,
        stop: client.__proto__.stop
        /* eslint-enable */
      };
    }

    return client;
  }
};
/* eslint-enable */
