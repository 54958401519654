import styled, { css } from "styled-components";
import { Theme, breakpoints } from "@tvg/design-system";

export const TalentPicksContainer = styled.section<{
  theme: Theme;
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue["000"]};
  border-radius: var(--fd-radii-border-radius-020);
  overflow: hidden;
`;

export const ContentContainer = styled.div<{
  theme: Theme;
}>`
  width: 100%;
  min-height: 294px;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.white["900"]};
`;

export const CardsContainer = styled.div<{
  theme: Theme;
}>`
  min-width: ${({ theme }) => `calc(280px + 2 * ${theme.space["space-4"]})`};
  display: flex;
  gap: ${({ theme }) => theme.space["space-4"]};

  @media screen and ${breakpoints.tablet.min.sm} {
    flex-grow: 1;
    flex-wrap: wrap;
  }

  @media screen and ${breakpoints.tablet.max.sm} {
    align-items: center;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scroll-padding-left: ${({ theme }) => theme.space["space-4"]};
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: ${({ theme }) =>
      `0 ${theme.space["space-4"]} ${theme.space["space-4"]}`};

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const CardsPgpContainer = styled.div<{ hasMultipleCards: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  display: flex;
  gap: ${({ theme }) => theme.space["space-3"]};
  min-width: ${({ theme }) => `calc(280px + 2 * ${theme.space["space-3"]})`};
  overflow: auto;
  scroll-snap-type: x mandatory;
  scroll-padding-left: ${({ theme }) => theme.space["space-5"]};
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  padding: ${({ theme }) =>
    `0 ${theme.space["space-5"]} ${theme.space["space-5"]}`};

  &::-webkit-scrollbar {
    display: none;
  }

  & .tpWrapper {
    ${({ hasMultipleCards }) =>
      hasMultipleCards &&
      css`
        width: 280px;
      `}
  }
`;

export const LinkContainer = styled.li<{
  theme: Theme;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  min-width: 142px;
  height: 112px;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
  scroll-snap-align: start;

  & > a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and ${breakpoints.desktop.min.sm} {
    display: none;
  }
`;

export const AllTalentPicksWrapper = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div<{
  theme: Theme;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin: 0 0 13px;
  background-color: ${({ theme }) => theme.colors.white["900"]};
  border: 1px solid ${({ theme }) => theme.colors.grey["200"]};

  :hover {
    background-color: ${({ theme }) => theme.colors.blue_accent["900"]};
  }
`;

export const TitleContainer = styled.div<{
  theme: Theme;
}>`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.m};
  background-color: ${({ theme }) => theme.colors.white["900"]};
  margin-top: 10px;
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-4"]}`};
`;

export const DesktopTitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  gap: var(--fd-space-space-4);
  padding: 0 var(--fd-space-space-4);

  @media screen and ${breakpoints.desktop.max.sm} {
    display: none;
  }

  button {
    align-self: center;
  }
`;
