import React from "react";
import { useSelector } from "react-redux";
import buildRaceUrl from "@tvg/formatter/url";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import { getOptedInPromos } from "@urp/store-selectors";
import { Carousel } from "@tvg/design-system/web";
import type { Race } from "@tvg/ts-types/Race";
import useTopRacesCarousel from "./hooks/useTopRacesCarousel";
import { sendAnalyticsEvt } from "./utils";
import { RaceCard } from "./components/RaceCard";
import { TopRacesMask } from "./components/TopRacesMask";
import { NoRacesAvailable } from "./components/NoRacesAvailable";
import {
  ScrollableContainer,
  Element,
  Container,
  LoadingContainer
} from "./styled-components";
import { getEnablePopularRaces } from "./redux/selectors";

const SECTION_TITLE = "Today’s Popular Races";

export const TopRaces = ({
  qaLabel = "topRaces",
  title
}: {
  qaLabel?: string;
  title?: string;
}) => {
  const isPopularRacesEnabled = useSelector(getEnablePopularRaces);
  const optedInPromos = useSelector(getOptedInPromos);
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);

  const {
    races,
    racesWithSilks,
    loading,
    renderedCardCount,
    scrollContainerRef,
    handleScroll
  } = useTopRacesCarousel();

  if (!isMobile && !isPopularRacesEnabled) {
    return null;
  }

  if (loading && !races?.length) {
    return (
      <LoadingContainer>
        <ScrollableContainer data-qa-label={qaLabel}>
          {[...Array(renderedCardCount)].map((_, index) => (
            <Element key={`card-empty-${index.toString()}`}>
              <TopRacesMask />
            </Element>
          ))}
        </ScrollableContainer>
      </LoadingContainer>
    );
  }

  const renderNoRacesAvailable = () => (
    <NoRacesAvailable
      qaLabel={`${qaLabel}-noRacesAvailable`}
      {...(!isMobile && { sectionTitle: SECTION_TITLE })}
    />
  );

  const renderItems = () =>
    races.map((race: Race, index: number) => {
      const runnersWithSilks = racesWithSilks?.length
        ? racesWithSilks[index] || []
        : [];
      const raceUrl = buildRaceUrl(
        race.track.code,
        race.track.name,
        race.raceNumber
      );
      return index < renderedCardCount ? (
        <RaceCard
          key={race.id}
          qaLabel={`${qaLabel}-raceCard-${index}`}
          race={race}
          to={raceUrl}
          runnersWithSilks={runnersWithSilks}
          onClick={(section: string) =>
            sendAnalyticsEvt(race, raceUrl, section, optedInPromos)
          }
        />
      ) : (
        <TopRacesMask key={race.id} />
      );
    });

  return (
    <Container data-qa-label="top-races-section" ref={scrollContainerRef}>
      {races.length ? (
        <Carousel
          title={title || SECTION_TITLE}
          qaLabel="carousel-top-races"
          slidesToScroll="auto"
          dragFree={isMobile}
          slideSize={318}
          items={renderItems()}
          displayHeader={!isMobile}
          onEmblaApi={(emblaApi) => {
            if (emblaApi) {
              const emblaContainer = emblaApi.containerNode();
              if (
                scrollContainerRef &&
                scrollContainerRef.current !== emblaContainer
              ) {
                // @ts-ignore
                scrollContainerRef.current = emblaContainer;
              }

              emblaApi.on("scroll", () => {
                if (scrollContainerRef.current) {
                  handleScroll(scrollContainerRef.current);
                }
              });
            }
          }}
        />
      ) : (
        renderNoRacesAvailable()
      )}
    </Container>
  );
};
