import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import tvgConf from "@tvg/conf";
import { PlaylistVideos, HORSE_RACING_101_URL } from "@urp/education-hub";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import {
  getDefaultPlaylistSettings,
  getEnableURPEducationHub,
  getHeaderTitle
} from "../../redux/selectors";
import type { Playlist } from "../../types";
import { getJWPlaylistVideos } from "../../utils";
import VideoPlaylist from "../VideoPlaylist";
import { Props } from "./types";
import { Container } from "./styled-components";
import { BASE, CONTAINER } from "../../constants";

const TipsAndPicks = ({ qaLabel = BASE, title }: Props) => {
  const isDesktop = useMemo(() => tvgConf().device === "desktop", []);
  const { playlistUrl, playerScript, licenseKey } = useSelector(
    getDefaultPlaylistSettings
  );
  const mobileTitle = useSelector(getHeaderTitle);
  const enableURPEducationHub = useSelector(getEnableURPEducationHub);

  const [playlist, setPlaylist] = useState<Playlist[]>([]);

  useEffect(() => {
    if (!enableURPEducationHub || isXSell()) {
      getJWPlaylistVideos(playlistUrl, playerScript, licenseKey).then(
        (data) => {
          if (data.playlist.length >= playlist.length) {
            setPlaylist(data.playlist);
          }
        }
      );
    }
  }, [
    enableURPEducationHub,
    playlistUrl,
    playerScript,
    licenseKey,
    playlist.length
  ]);

  return (
    <Container data-qa-label={buildQaLabel([qaLabel, CONTAINER])}>
      {enableURPEducationHub && !isXSell() ? (
        <PlaylistVideos
          title={title}
          qaLabel={`${qaLabel}-racing-101`}
          playlistUrl={playlistUrl}
          seeAllUrl={
            enableURPEducationHub && isDesktop
              ? HORSE_RACING_101_URL
              : undefined
          }
          displayNavButtons={isDesktop}
        />
      ) : (
        <VideoPlaylist
          title={mobileTitle}
          qaLabel={qaLabel}
          playlist={playlist}
        />
      )}
    </Container>
  );
};

export default TipsAndPicks;
