import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bannerProps } from "@tvg/sh-utils/fixedBanner";
import { getGeolocationStatus } from "@tvg/sh-geolocation/src/redux/selectors";
import { isFDR } from "@tvg/utils/generalUtils";
import {
  getIsLogged,
  getIsVerified,
  getIsTermsAccepted
} from "@urp/store-selectors";
import {
  getAccountCompliantBannerMsg,
  getGeoLocationBannerMsg
} from "./selectors";

const useBannerProps = () => {
  const dispatch = useDispatch();

  const isLogged = useSelector(getIsLogged);
  const isVerified = useSelector(getIsVerified);
  const isTermsAccepted = useSelector(getIsTermsAccepted);
  const accountCompliantBannerMsg = useSelector(getAccountCompliantBannerMsg);
  const geolocationBannerMsg = useSelector(getGeoLocationBannerMsg);
  const geolocationStatus = useSelector(getGeolocationStatus);

  const props = useMemo(
    () =>
      bannerProps(
        dispatch,
        {
          isLogged,
          // Only FDR allow false as default
          isVerified: isFDR() ? isVerified : undefined,
          isTermsAccepted: isFDR() ? isTermsAccepted : undefined
        },
        accountCompliantBannerMsg,
        geolocationBannerMsg,
        geolocationStatus
      ),
    [
      isVerified,
      isLogged,
      isTermsAccepted,
      accountCompliantBannerMsg,
      geolocationBannerMsg,
      geolocationStatus
    ]
  );

  return props;
};

export default useBannerProps;
