import { closeInvalidResidentialAddressModal } from "@fdr/shared-actions/UserActions";
import { LogoFDR } from "@fdr/static-ui/Logos";
import { Modal, ModalType } from "@tvg/design-system";
import { logout } from "@tvg/sh-lib-account-wallet/src/session/logout";
import React from "react";
import type { Dispatch } from "redux";
import InvalidResidentialAddressError from "../InvalidResidentialAddressError";

interface InvalidResidentialAddressErrorModalProps {
  isOpen: boolean;
  dispatch: Dispatch;
  allowedStates: string[];
  isNative?: boolean;
  isDesktop?: boolean;
  invalidResidentialAddressMessages: { [key: string]: string };
}

const getModalProps = (type: ModalType) => {
  const commonProps = {
    padding: 12,
    hasBorderBottom: false
  };

  switch (type) {
    case "lightbox":
      return {
        ...commonProps,
        hasRoundedCorners: false,
        hasShadow: true
      };
    case "flexible":
      return {
        ...commonProps,
        animation: "bottom",
        hasRoundedCorners: true,
        isDarkMode: false
      };
    case "fullscreen":
      return {
        animation: "bottom",
        topPosition: 0,
        isFullHeight: true,
        headerLeftElement: LogoFDR({
          height: 30,
          fillColor: "var(--fd-colors-content-on-dark)"
        }),
        hasShadow: true,
        useCloseLabel: true,
        isDarkMode: true,
        padding: 0,
        hasBorderBottom: true
      };
    default:
      return null;
  }
};

export const InvalidResidentialAddressErrorModal: React.FC<
  InvalidResidentialAddressErrorModalProps
> = ({
  isOpen,
  dispatch,
  allowedStates,
  isNative,
  isDesktop,
  invalidResidentialAddressMessages
}) => {
  const closeModal = () => {
    logout();
    dispatch(closeInvalidResidentialAddressModal());
  };

  const baseProps = {
    title: "",
    qaLabel: "invalid-residential-address-error-modal",
    isOpen,
    onClose: closeModal,
    hasHeader: true
  };

  const modalType = isDesktop
    ? "lightbox"
    : isNative
      ? "fullscreen"
      : "flexible";
  const modalProps = getModalProps(modalType);

  const modalContent = (
    <InvalidResidentialAddressError
      onClose={baseProps.onClose}
      allowedStates={allowedStates}
      invalidResidentialAddressMessages={invalidResidentialAddressMessages}
      isNative={isNative}
    />
  );
  return (
    <Modal {...baseProps} {...modalProps} type={modalType}>
      {modalContent}
    </Modal>
  );
};

export default InvalidResidentialAddressErrorModal;
