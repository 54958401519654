import styled, { css } from "styled-components";

const sharedPaddingStyles = css`
  padding: 0 22px;
`;

export const PageErrorWrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: var(--fd-space-space-4);
`;

export const IconWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-4);
  padding: var(--fd-space-space-4) var(--fd-space-space-1);
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-1);
  ${sharedPaddingStyles}
  width: 100%;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: var(--fd-colors-content-strong);
`;

export const Subtitle = styled.p`
  margin-top: var(--fd-space-space-1);
  font-size: 14px;
  font-weight: 600;
`;

export const HalfBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-3);
  width: 100%;
`;

export const ParagraphBlock = styled.div`
  ${sharedPaddingStyles}

  button {
    color: var(--fd-colors-brand-primary-default);
    font-weight: 500;
    background: transparent;
    margin-top: -1px;
  }
`;

export const ErrorMessageSubtitleBlock = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding: 0 22px;
`;

export const ButtonWrapper = styled.div`
  margin-top: -8px;
  padding: 0 14px;
  margin-bottom: var(--fd-space-space-8);
`;

export const StateList = styled.div`
  padding: 0 9px;
`;
