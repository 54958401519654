import React, { FC, memo } from "react";
import type { RaceInfoProps } from "./types";
import { Paragraph } from "../../../Typography";

export const RaceInfo: FC<RaceInfoProps> = memo(({ label, align, qaLabel }) => (
  <Paragraph
    fontFamily="medium"
    textAlign={align}
    qaLabel={`${qaLabel}-race-info`}
    color="--fd-colors-content-default"
    margin={0}
    fontSize="14px"
    fontWeight={500}
  >
    {label}
  </Paragraph>
));
