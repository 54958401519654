import React from "react";
import type { WagerType } from "@tvg/ts-types/Wager";
import { BetTypeButton } from "./components/BetTypeButton";
import { BetTypeExpandableButton } from "./components/BetTypeExpandableButton";

import { groupByWagerType } from "./utils";
import {
  BetTypesContainer,
  BetTypeSelectorContainer,
  FadeLayer,
  InfoContainer,
  InfoText,
  Navigation
} from "./styled-components";
import { BetTypeSelectorProps } from "./types";
import useBetSelectorScroll from "../../hooks/useBetSelectorScroll/useBetSelectorScroll";
import { Icon } from "../Icon";

const BetTypeSelector: React.FC<BetTypeSelectorProps> = ({
  betTypesList,
  selectedBetType,
  setSelectedBetType,
  qaLabel = "bet-type-selector"
}) => {
  const filteredList: Array<WagerType[]> = groupByWagerType(betTypesList);

  const {
    scrollAmount,
    isOverFlowing,
    handleScrollTo,
    betTypesContainerRef,
    getWidthToScroll
  } = useBetSelectorScroll(filteredList);

  return (
    <BetTypeSelectorContainer data-qa-label={qaLabel}>
      <InfoContainer data-qa-label="available-bet-types-container">
        <InfoText data-qa-label="available-bet-types">
          Available bet types
        </InfoText>
      </InfoContainer>
      <BetTypesContainer
        ref={betTypesContainerRef}
        data-qa-label="bets-types-container"
      >
        {filteredList[0][0] !== undefined &&
          filteredList.map((betType: Array<WagerType>) =>
            betType.length === 1 ? (
              <BetTypeButton
                betType={betType[0]}
                isSelected={betType[0].id === selectedBetType}
                key={betType[0].id}
                onPress={setSelectedBetType}
              />
            ) : (
              <BetTypeExpandableButton
                betTypes={betType}
                selectedValue={selectedBetType}
                isSelected={
                  !!betType.find((type) => type.id === selectedBetType)
                }
                key={betType[0].id}
                onPress={setSelectedBetType}
              />
            )
          )}
      </BetTypesContainer>
      {isOverFlowing && (
        <FadeLayer
          data-qa-label="fade"
          orientation={scrollAmount <= 0 ? "left" : "right"}
          width={
            betTypesContainerRef.current?.getBoundingClientRect().width || 0
          }
        />
      )}
      {isOverFlowing && (
        <Navigation>
          <Icon
            name={scrollAmount <= 0 ? "arrowRight" : "arrowLeft"}
            size="m"
            lineColor="--fd-colors-content-default"
            onClick={() =>
              scrollAmount <= 0
                ? handleScrollTo(getWidthToScroll())
                : handleScrollTo(-getWidthToScroll())
            }
            qaLabel="bet-types-scroll"
          />
        </Navigation>
      )}
    </BetTypeSelectorContainer>
  );
};

export default BetTypeSelector;
export { BetTypeSelectorProps, BetTypeSelector, groupByWagerType };
