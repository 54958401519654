import React from "react";
import type { DropdownButtonProps } from "./types";
import {
  BetTypesButtonDropdown,
  BetTypesTextDropdown
} from "./styled-components";

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  onSelectValue,
  item,
  selectedValue,
  qaLabel
}) => (
  <BetTypesButtonDropdown
    onClick={() => onSelectValue(item.id)}
    isSelected={item.id === selectedValue}
    data-qa-label={`${qaLabel}-${item.name}`}
  >
    <BetTypesTextDropdown isSelected={item.id === selectedValue}>
      {item.name.substring(item.name.indexOf(" ") + 1)}
    </BetTypesTextDropdown>
  </BetTypesButtonDropdown>
);
