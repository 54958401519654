import type {
  SetDerbyBanner,
  SetBannersEventStatusAction,
  SetBannersDerbyRaceAction
} from "./actions";
import type { BannerConfigEventInfo, HeaderBannerConfig } from "../types";

export type State = {
  eventInfo?: BannerConfigEventInfo;
  derbyBanner?: HeaderBannerConfig;
};

export const initialState: State = {
  eventInfo: {
    eventDate: new Date(),
    eventName: {
      label: "",
      size: 0
    },
    raceNumber: 0,
    trackAbbr: ""
  },
  derbyBanner: {
    default: [],
    eventDay: [],
    preEvent: [],
    fridayPreEvent: [],
    thursdayPreEvent: []
  }
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = initialState,
  action:
    | SetDerbyBanner
    | SetBannersEventStatusAction
    | SetBannersDerbyRaceAction
) => {
  switch (action.type) {
    case "SET_DERBY_BANNER_ACTION":
      return {
        ...state,
        ...action.payload
      };
    case "SET_BANNERS_EVENT_STATUS":
      return {
        ...state,
        currentConfig: action.payload
      };
    case "SET_BANNERS_DERBY_RACE":
      return {
        ...state,
        derbyRace: action.payload
      };
    default: {
      return state;
    }
  }
};
