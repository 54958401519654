import React from "react";
import { truncate } from "lodash";
import { Paragraph, Mtp, MtpProps } from "@tvg/design-system/web";
import { animated, useTransition } from "@react-spring/web";
import { useNavigateWithoutBet } from "@tvg/pp/src/hooks/useProgramPageNavigation";
import buildRaceUrl from "@tvg/formatter/url";
import {
  RaceEntryContainer,
  TextContainer,
  HR,
  LeftContainer,
  MTPContainer
} from "./styled-components";
import Details, { DetailsProps } from "./Details";
import { lhnNavigationGtmEvent } from "../../utils/gtm/navigation";

export interface RaceEntryProps {
  details?: DetailsProps;
  title?: string;
  mtp: MtpProps;
  trackAbbr: string;
  raceNumber: string;
  trackName: string;
  isNavCollapsed: boolean;
  isInsideHamburgerMenu: boolean;
  isFeatureRace?: boolean;
  style?: string;
  onClickRaceEntry?: () => void;
}

const AnimatedTextContainer = animated(TextContainer);
const AnimatedHR = animated(HR);
const AnimatedTackAbbr = animated(Paragraph);

const RaceEntry = ({
  style,
  details,
  mtp,
  title,
  raceNumber,
  trackName,
  trackAbbr,
  isNavCollapsed = false,
  isFeatureRace = false,
  isInsideHamburgerMenu,
  onClickRaceEntry
}: RaceEntryProps) => {
  const { navigateWithoutBet } = useNavigateWithoutBet();
  const transition = useTransition(isNavCollapsed, {
    config: { duration: 150 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const getQaLabel = (name: string) => {
    if (name.substring(0, 2) === name.substring(0, 2).toUpperCase()) {
      return `track-${name.replace(/ /g, "")}-R${raceNumber}`;
    }

    return `track-${name.replace(/ /g, "-")}-R${raceNumber}`;
  };

  return (
    <RaceEntryContainer
      data-qa-label={getQaLabel(trackName)}
      isNavCollapsed={isNavCollapsed}
      onClick={() => {
        // Used on tablet version of lhn to know the user navigated and with this close lhn
        if (typeof onClickRaceEntry === "function") {
          onClickRaceEntry();
        }

        lhnNavigationGtmEvent({
          trackName,
          mtp: mtp.mtp,
          destinationUrl: buildRaceUrl(trackAbbr, raceNumber, trackName),
          isFeatureRace,
          isInsideHamburgerMenu
        });

        navigateWithoutBet(trackAbbr, raceNumber, trackName);
      }}
    >
      <LeftContainer>
        {transition(
          (styles, isCollapsed) =>
            isCollapsed && (
              <AnimatedTackAbbr
                style={styles}
                fontFamily="bold"
                fontSize="s"
                qaLabel="race-track-abbr"
                color="blue.000"
                textTransform="uppercase"
              >
                {trackAbbr}
              </AnimatedTackAbbr>
            )
        )}
        <MTPContainer data-qa-label="mtp">
          <Mtp {...mtp} size="xxs" align="center" mode="dark" />
        </MTPContainer>
      </LeftContainer>
      {transition(
        (styles, isCollapsed) =>
          !isCollapsed && (
            <>
              <AnimatedTextContainer
                data-qa-label="race-entry-content-container"
                style={styles}
              >
                {!!title && (
                  <Paragraph
                    fontFamily="regular"
                    fontSize="xs"
                    qaLabel="race-title"
                    color={style === "Highlighted" ? "yellow.400" : "blue.000"}
                    textTransform="uppercase"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    textAlign="start"
                  >
                    {title}
                  </Paragraph>
                )}
                <Paragraph
                  fontFamily="regular"
                  fontSize="s"
                  qaLabel="trackName"
                  color="blue.000"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  textAlign="start"
                >
                  {`${truncate(trackName, { length: 29 })} R${raceNumber}`}
                </Paragraph>
                {Object.values(details || []).some((detail) => detail) && (
                  <Details {...details} />
                )}
              </AnimatedTextContainer>
              <AnimatedHR style={styles} />
            </>
          )
      )}
    </RaceEntryContainer>
  );
};

export default RaceEntry;
